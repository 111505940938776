import { useMemo } from 'react';
import { useAppContext } from '../store/context';

const useDelegation = (delegatedUserUid) => {
  const { context: { me } } = useAppContext();

  const userHasDelegation = useMemo(() => !!(
    me?.aclMatrix?.userDelegations?.some((delegUser) => delegUser.uid === delegatedUserUid)
  ), [me, delegatedUserUid]);

  return {
    userHasDelegation,
  };
};

export default useDelegation;
