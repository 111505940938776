import api from './_client';

export const getUserProfile = () => api.get('/person/profile');

export const patchUserProfile = (data) => api.patch('/person/profile', {
  ...data,
  tenantNumber: data.tenantNumber || null,
});

export const getCompanyDetailsForProfile = () => api.get('/person/profile/company');

export const patchCompanyDetailsForProfile = (data) => api.patch('/person/profile/company', data);

export const getProfileCompletion = () => api.get('/person/profile/rate');
