/* eslint-disable max-len */
import api from './_client';
import { getDateFilters } from '../utils/dates';

// Documents
export const getPersonDocumentsFromAdmin = (uid, params) => api.get(`/person/admin/document/${uid}`, {
  params,
});

export const getCompanyDocumentsFromAdmin = (uid, params) => api.get(`/person/admin/document/${uid}`, {
  params,
});

// Users
export const getUsersFromAdmin = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[role][--in--]': filters.role ? filters.role.join?.(',') : 'ROLE_USER,ROLE_LESSOR',
    'filters[onboardingStatus][--in--]': filters.onboardingStatus?.join?.(','),
    'filters[onboardingStatus][--not-eq--]': filters.onboardingStatusNot?.join?.(','),
    'filters[status][--in--]': filters.status?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };
  return api.get('/person/admin', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getUserFromAdmin = (uid) => api.get(`/person/admin/${uid}`);

export const editPasswordFromAdmin = (data) => api.post('/identity-access/admin/user/set-password', data);

// Tenants
export const updateTenantFromAdmin = (uid, data) => api.put(`/person/admin/tenant/${uid}`, data);

// Payments
export const getPaymentsFromAdmin = ({ page, itemsPerPage, filters = {} }) => {
  const allStatusFilters = filters?.status?.flatMap((s) => s.split(','));
  const statusFilters = allStatusFilters?.filter((s) => [
    'scheduled',
    'scheduled_manual',
    'notified',
    'pending',
    'canceled',
    'success',
    'failed',
  ].includes(s));
  const accountingsStatusFilters = allStatusFilters?.filter((s) => [
    'received',
    'sent',
    'ready_to_wire',
    'ready_to_check',
    'in_wiring',
    'failed',
    'not_doable',
  ].includes(s));
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[payer][--in--]': filters.payer?.join?.(','),
    'filters[lease][--in--]': filters.lease?.join?.(','),
    'filters[lease][propertyUid][--in--]': filters.property?.join?.(','),
    'filters[status][--in--]': statusFilters?.length > 0
      ? statusFilters.join?.(',')
      : undefined,
    'filters[accountingsStatus][--in--]': accountingsStatusFilters?.length > 0
      ? accountingsStatusFilters.join?.(',')
      : undefined,
    ...getDateFilters('cashInDate', filters, '[accountings][createdAt]'),
    'filters[residentialComplexUid][--eq--]': filters.residentialComplexUid?.[0],
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/payment/admin/payment', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

// Companies
/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @param {Object} filters number of items per page
 * @returns {import('axios').AxiosResponse<any>}
 */
export const getCompaniesFromAdmin = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters?.uid?.join?.(','),
    'filters[owner][--in--]': filters?.owner?.join?.(','),
    'filters[onboardingStatus][--in--]': filters?.onboardingStatus?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };
  return api.get('/person/admin/profile/companies', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

// Accounting
export const getAccountingFromAdmin = (uid) => api.get(`/payment/users/${uid}/account`);

// Internal transfer
export const postInternalTransfer = (data) => api.post('/payment/admin/wiring/internal/initiate', data);

export const getBankAccountBalance = (uid) => api.get(`/payment/admin/accounts/${uid}/balance`);
