import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

// Components
import {
  Button,
} from 'ui-library-unlocker';
import SettingsLeaseDetails from './SettingsLeaseDetails';

// Styles
// import styles from './SettingsPayments.module.scss';

function SettingsPayments() {
  const { t } = useTranslation();

  const { formik, initialValues } = useOutletContext();

  return (
    <div>
      <h1>{t('settings.payments.title')}</h1>
      <form onSubmit={formik.handleSubmit}>
        <SettingsLeaseDetails
          formik={formik}
          initialValues={initialValues}
          isGlobalSettings
        />
        <Button
          type="submit"
          className="m-t-35"
          size="large"
          label={t('global.save')}
          loading={formik.isSubmitting}
        />
      </form>
    </div>
  );
}

export default SettingsPayments;
