import {
  useCallback,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';

// Components
import {
  Button,
  utils,
  SelectInput,
  Message,
  Picto,
} from 'ui-library-unlocker';
import { useQuery } from '@tanstack/react-query';
import Modal from '../../../molecules/Modal/Modal';

// Services
import { getProperties, getOwnerProperties } from '../../../../services/property';

// Hooks
import useRoles from '../../../../hooks/useRoles';

// Utils
import {
  propertyToResidenceInitialValues as initialValues,
  propertyToResidenceSchema,
} from '../../../../utils/forms/residenceSchema';
import { displayError, isFieldValid } from '../../../../utils/forms/form';

// Styles
import styles from './ResidenceForm.module.scss';

const PropertyToResidenceModal = forwardRef(({
  id,
  onSubmit,
  onCancel,
  loading,
  managerUid,
  associatedProperties,
  ownerUid,
  residentialComplexUid,
}, ref) => {
  const { t } = useTranslation();
  const { isUserRealEstateManager, isUserAdmin } = useRoles();

  const [query, setQuery] = useState('');

  const isManagerOrAdmin = useMemo(() => (
    isUserRealEstateManager || isUserAdmin
  ), [isUserRealEstateManager, isUserAdmin]);

  const formik = useFormik({
    initialValues,
    validationSchema: propertyToResidenceSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit,
  });

  const {
    data: propertiesData,
    isFetching: propertiesIsFetching,
    refetch: refetchProperties,
  } = useQuery({
    queryKey: ['owner-properties', query, managerUid, ownerUid],
    queryFn: () => (isManagerOrAdmin ? getOwnerProperties : getProperties)({
      page: 1,
      itemsPerPage: 100,
      filters: {
        search: [query],
        managerUid: [managerUid],
      },
      ownerUid,
    }),
    keepPreviousData: true,
    enabled: !!managerUid && (isManagerOrAdmin ? !!ownerUid : true),
  });

  useImperativeHandle(ref, () => ({
    refetchProperties,
  }));

  const checkIsOptionDisabled = useCallback((optionUid) => (
    associatedProperties?.map((property) => property.uid)?.includes(optionUid)
  ), []);

  const propertiesOptions = useMemo(() => propertiesData?.data?.collection?.map((property) => ({
    label: `${property.name}${checkIsOptionDisabled(property.uid)
      ? ` (${t('residence.crud.properties.alreadyAssociated')})`
      : ''}`,
    value: property.uid,
    residentialComplexUid: property.residentialComplexUid,
  })) || [], [propertiesData]);

  const propertiesWithAnotherResidence = useMemo(() => formik.values.properties.filter((property) => (
    property.residentialComplexUid
    && property.residentialComplexUid !== residentialComplexUid
  )), [formik.values.properties, residentialComplexUid]);

  const handleCancel = useCallback(() => {
    onCancel();
    formik.resetForm();
  }, [onCancel, formik]);

  const handleSearchProperty = useCallback(debounce((value) => {
    setQuery(value);
  }, 500), [setQuery]);

  const getWarningContent = useCallback((list, text) => (
    <p>
      {text}
      <ul className={styles.propertiesThatCannotBeAddedList}>
        {list.map((property) => (
          <li key={property.value}>
            <Link
              to={`/property/${property.value}`}
              target="_blank"
            >
              {property.label}
              <Picto
                icon="export-link"
                width={9}
                color="var(--color-primary)"
                className={styles.externalLink}
              />
            </Link>
          </li>
        ))}
      </ul>
    </p>
  ), []);

  return createPortal(
    <Modal
      id={id}
      onClose={onCancel}
      title={t('residence.crud.properties.propertyToResidenceTitle')}
      className={styles.propertyToResidenceModal}
    >
      <div className={styles.propertyToResidenceForm}>
        <p className={styles.desc}>
          {t('residence.crud.properties.propertyToResidenceDescription')}
        </p>
        <SelectInput
          id="properties"
          name="properties"
          className="m-t-25"
          label={t('residence.crud.properties.fieldLabel')}
          options={propertiesOptions}
          error={displayError(t, formik, 'properties')}
          valid={isFieldValid(formik, 'properties')}
          onChange={(item) => formik.setFieldValue('properties', item)}
          onInputChange={handleSearchProperty}
          onBlur={formik.handleBlur}
          value={formik.values.properties}
          closeMenuOnSelect={false}
          loading={propertiesIsFetching}
          isMulti
          isOptionDisabled={(option) => checkIsOptionDisabled(option.value)}
          menuPortalTarget={document?.body}
        />
        {propertiesWithAnotherResidence.length > 0 && (
          <Message
            variant="info"
            className="m-t-25"
            content={getWarningContent(
              propertiesWithAnotherResidence,
              t('residence.crud.properties.propertiesWithAnotherResidence'),
            )}
          />
        )}
      </div>
      <div className={utils.cn([
        styles.propertyToResidenceButtonContainer,
        'm-t-50',
      ])}
      >
        <Button
          onClick={formik.handleSubmit}
          size="large"
          label={t('global.confirmationModal.confirm')}
          loading={loading}
        />
        <Button
          onClick={handleCancel}
          size="large"
          variant="secondary"
          label={t('global.confirmationModal.cancel')}
        />
      </div>
    </Modal>,
    document.querySelector('body'),
  );
});

PropertyToResidenceModal.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  managerUid: PropTypes.string,
  associatedProperties: PropTypes.arrayOf(PropTypes.shape()),
  residentialComplexUid: PropTypes.string,
  ownerUid: PropTypes.string,
};

PropertyToResidenceModal.defaultProps = {
  id: 'property-to-residence-modal',
  loading: false,
  managerUid: '',
  associatedProperties: [],
  residentialComplexUid: '',
  ownerUid: '',
};

export default PropertyToResidenceModal;
