import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Components
import {
  Picto,
  Tag,
  Button,
  utils,
} from 'ui-library-unlocker';

// Utils
import { formatAddress, formatPrice, getStatusStyles } from '../../../utils/properties';

// Hooks
import useRoles from '../../../hooks/useRoles';

// Styles
import styles from './PropertyLeaseVerif.module.scss';

function PropertyLeaseVerif({
  onCancel = () => {},
  onSubmit = () => {},
  property = null,
  submitLabel,
  isSubmitting,
}) {
  const { t } = useTranslation();
  const { isUserRealEstateManager, isUserAdmin } = useRoles();

  const {
    uid: propertyUid,
    name: propertyName,
    address,
    ownerUid,
    ownerName,
    ownedByCompany,
    ownerPPHName,
    ownerPPHUid,
    rent,
    rentExcludingCharges,
    charges,
    status: propertyStatus,
    managerUid,
    managerName,
    fees,
  } = useMemo(() => property || {}, [property]);

  const propertyStatusStyle = useMemo(() => getStatusStyles(propertyStatus), [propertyStatus]);

  const feesAmount = useMemo(() => {
    if (!rent || !fees) return 0;
    return rent * (utils.centsToEuro(fees) / 100);
  }, [rent, fees]);

  const ownerLink = useMemo(() => {
    if (ownedByCompany) return <span>{ownerName}</span>;
    if (isUserAdmin) {
      return <Link to={`/user/${ownerUid}`} target="_blank">{ownerName}</Link>;
    }
    if (isUserRealEstateManager && !ownedByCompany) {
      return <Link to={`/owner/${ownerUid}`} target="_blank">{ownerName}</Link>;
    }
    return <p>{ownerName}</p>;
  }, [isUserAdmin, isUserRealEstateManager, ownerName, ownerUid, ownedByCompany]);

  const ownedByLink = useMemo(() => {
    if (isUserAdmin) {
      return <Link to={`/user/${ownerPPHUid}`} target="_blank">{ownerPPHName}</Link>;
    }
    if (isUserRealEstateManager) {
      return <Link to={`/owner/${ownerPPHUid}`} target="_blank">{ownerPPHName}</Link>;
    }
    return <span>{ownerPPHName}</span>;
  }, [isUserAdmin, isUserRealEstateManager, ownerPPHName, ownerPPHUid]);

  const managerLink = useMemo(() => {
    if (isUserRealEstateManager || isUserAdmin) {
      return <Link to={`/company/${managerUid}`} target="_blank">{managerName}</Link>;
    }
    return <span>{managerName}</span>;
  }, [isUserAdmin, isUserRealEstateManager, managerName, managerUid]);

  return (
    <div>
      <div>
        <h2>{t('lease.add.verifModal.title')}</h2>
        <Picto width={133} icon="trace-5208" color="var(--color-tertiary)" />
      </div>
      <h3 className={styles.categoryTitle}>
        <Picto width={18} icon="house-2" />
        <span>
          {` ${t('global.property')}`}
        </span>
      </h3>
      <div className={styles.categoryContainer}>
        <div className={styles.withStatusTag}>
          <p>
            {propertyUid ? (
              <Link to={`/property/${propertyUid}`} target="_blank">{propertyName}</Link>
            ) : (
              <span>{propertyName}</span>
            )}
            <br />
            <span>{formatAddress(address)}</span>
          </p>
          <Tag
            label={t(`property.table.status.${propertyStatus}`)}
            variant={propertyStatusStyle.variant}
            icon={propertyStatusStyle.icon}
            size="medium"
          />
        </div>
        <div>
          <div className={styles.categorySubtitle}>
            <Picto width={14} icon="key" />
            <span>{` ${t('global.owner')}`}</span>
          </div>
          <p>
            {ownerLink}
            {ownedByCompany && (
              <>
                <br />
                <span>
                  {`${t('lease.add.verifModal.ownedBy')} `}
                  {ownedByLink}
                </span>
              </>
            )}
          </p>
        </div>
        <div>
          <div className={styles.categorySubtitle}>
            <Picto width={14} icon="wallet" />
            <span>{` ${t('lease.add.verifModal.rent')}`}</span>
          </div>
          <div className={styles.rent}>
            <div>
              <div className={utils.cn([styles.categorySubtitle, styles.categoryTag])}>
                <span>{t('lease.add.verifModal.rentIncludingCharges')}</span>
              </div>
              <span className={styles.price}>
                {formatPrice(utils.centsToEuro(rent))}
              </span>
            </div>
            <div>
              <div className={utils.cn([styles.categorySubtitle, styles.categoryTag])}>
                <span>{t('lease.add.verifModal.rentExcludingCharges')}</span>
              </div>
              <span>{formatPrice(utils.centsToEuro(rentExcludingCharges))}</span>
            </div>
            <div>
              <div className={utils.cn([styles.categorySubtitle, styles.categoryTag])}>
                <span>{t('lease.add.verifModal.charges')}</span>
              </div>
              <span>{formatPrice(utils.centsToEuro(charges))}</span>
            </div>
          </div>
        </div>
      </div>
      <h3 className={styles.categoryTitle}>
        <Picto width={18} icon="user-octagon" />
        <span>
          {` ${t('global.manager')}`}
        </span>
      </h3>
      {managerUid !== ownerUid ? (
        <div className={styles.categoryContainer}>
          {managerLink}
          <div>
            <div className={styles.categorySubtitle}>
              <Picto width={14} icon="wallet" />
              <span>{` ${t('global.fees')}`}</span>
            </div>
            <p className={styles.fees}>
              <span className={styles.price}>
                {utils.centsToEuro(fees)}
                %
              </span>
              <span>{formatPrice(utils.centsToEuro(feesAmount))}</span>
            </p>
          </div>
        </div>
      ) : (
        <p className={utils.cn([styles.categoryContainer, styles.isOwnManager])}>
          {t('lease.add.verifModal.isOwnManager')}
        </p>
      )}
      <div className={styles.actions}>
        <Button
          variant="secondary"
          size="large"
          label={t('global.goBack')}
          onClick={onCancel}
        />
        <Button
          variant="primary"
          size="large"
          label={submitLabel || t('global.submit')}
          onClick={onSubmit}
          loading={isSubmitting}
        />
      </div>
    </div>
  );
}

PropertyLeaseVerif.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  property: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
    ownerUid: PropTypes.string,
    ownerName: PropTypes.string,
    ownedByCompany: PropTypes.bool,
    ownerPPHName: PropTypes.string,
    ownerPPHUid: PropTypes.string,
    rent: PropTypes.number,
    status: PropTypes.string,
    managerUid: PropTypes.string,
    managerName: PropTypes.string,
    fees: PropTypes.number,
  }),
  submitLabel: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

export default PropertyLeaseVerif;
