/* eslint-disable react/prop-types */
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

// Components
import Select, { components } from 'react-select';
import { Picto, utils } from 'ui-library-unlocker';

// Services
import { searchOmniPredictions } from '../../../services/search';

// Utils
import { formatAddress } from '../../../utils/properties';

// Constants
import { SEARCH_SCOPE_LIST } from '../../../utils/constants';

// Styles
import styles from './SearchInput.module.scss';

const stylesSelect = {
  indicatorSeparator: () => {}, // removes vertical line next to dropdown arrow
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
  placeholder: (provided) => ({
    ...provided,
    opacity: 0,
  }),
  menu: (base) => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
    position: 'absolute',
    left: 0,
  }),
};

function ValueContainer({ children, ...props }) {
  const {
    hasValue,
    selectProps: {
      name, isSearchable, inputValue, label,
    },
  } = props;
  return (
    <components.ValueContainer {...props}>
      <label
        htmlFor={name}
        className={utils.cn([
          styles.label,
          hasValue || inputValue ? styles.isFocused : '',
          isSearchable ? styles.isSearchable : '',
        ])}
      >
        {label}
      </label>
      {children}
    </components.ValueContainer>
  );
}

function DropdownIndicator(props) {
  const { hasValue } = props;
  if (hasValue) return null;
  return (
    <components.DropdownIndicator {...props}>
      <Picto color="var(--color-primary-300)" icon="search" width={20} />
    </components.DropdownIndicator>
  );
}

function ClearIndicator(props) {
  return (
    <components.ClearIndicator {...props}>
      <Picto icon="close" style={{ height: '18px' }} />
    </components.ClearIndicator>
  );
}

function SearchInput(props) {
  const {
    disabled = false,
    error = null,
    valid = false,
    info = null,
    onSelect,
    value,
    label = '',
    scope,
  } = props;

  const { className = '', ...inputProps } = props;

  const { t } = useTranslation();
  const [fetchResults, setFetchResults] = useState();

  const fetchPredictions = useMutation({
    mutationFn: (data) => searchOmniPredictions({
      query: data,
      scope,
    }),
    onSuccess: ({ data }) => setFetchResults(data),
  });

  const filterOption = useCallback(() => true, []); // doesnt filter before api call

  const getListPlaceholder = useCallback(() => t('global.search.noResults'), [t]);

  const getLoadingMessage = useCallback(() => t('global.loading'), [t]);

  const options = useMemo(() => {
    if (!Array.isArray(scope) || scope.length < 1) return [];
    if (!fetchResults) return [];
    const list = [];
    const getLabel = (scopeItem, item) => {
      switch (scopeItem) {
        case SEARCH_SCOPE_LIST.TENANTS:
        case SEARCH_SCOPE_LIST.PROPERTY_OWNERS:
        case SEARCH_SCOPE_LIST.MANAGERS:
          return `${item.firstName} ${item.lastName} - ${item.unlockerId}`;
        case SEARCH_SCOPE_LIST.PROPERTIES:
          return `${item.name} - ${formatAddress({
            streetNumber: item.streetNumber,
            street: item.street,
            zipCode: item.zipCode,
            city: item.city,
          })}`;
        case SEARCH_SCOPE_LIST.LEASES:
        case SEARCH_SCOPE_LIST.RESIDENTIAL_COMPLEXES:
          return `${item.name}`;
        case SEARCH_SCOPE_LIST.COMPANIES:
          return `${item.brandName} - ${item.unlockerId}`;
        case SEARCH_SCOPE_LIST.ACCOUNTS:
          return `${item.accountNumber} - ${item.companyName || `${item.firstName} ${item.lastName}`}`;
        default:
          return '';
      }
    };
    scope
      .filter((scopeItem) => ![SEARCH_SCOPE_LIST.RESIDENTIAL_COMPLEXES].includes(scopeItem))
      .forEach((scopeItem) => {
        if (fetchResults[scopeItem]) {
          const groupOptions = [];
          fetchResults[scopeItem].forEach((item) => {
            const isResidence = (
              scopeItem === SEARCH_SCOPE_LIST.PROPERTIES
              && Array.isArray(item.properties)
            );
            const correctScope = isResidence ? SEARCH_SCOPE_LIST.RESIDENTIAL_COMPLEXES : scopeItem;
            groupOptions.push({
              label: getLabel(correctScope, item),
              value: item.uid,
              scope: correctScope,
            });
            if (isResidence && item.properties.length > 0) {
              item.properties.forEach((property) => {
                groupOptions.push({
                  label: getLabel(scopeItem, property),
                  value: property.uid,
                  scope: scopeItem,
                  hasParent: true,
                });
              });
            }
          });
          list.push({
            label: t(`global.search.scope.${scopeItem}`),
            options: groupOptions,
          });
        }
      });
    return list;
  }, [fetchResults, scope]);

  const formatOptionLabel = useCallback((option) => {
    if (!option) return null;
    const getIcon = () => {
      switch (option.scope) {
        case SEARCH_SCOPE_LIST.TENANTS:
        case SEARCH_SCOPE_LIST.PROPERTY_OWNERS:
        case SEARCH_SCOPE_LIST.MANAGERS:
          return 'user';
        case SEARCH_SCOPE_LIST.PROPERTIES:
          return 'house-2';
        case SEARCH_SCOPE_LIST.RESIDENTIAL_COMPLEXES:
          return 'building-4';
        case SEARCH_SCOPE_LIST.LEASES:
          return 'edit-2';
        case SEARCH_SCOPE_LIST.COMPANIES:
          return 'suitcase';
        case SEARCH_SCOPE_LIST.ACCOUNTS:
          return 'wallet';
        default:
          return '';
      }
    };
    return (
      <div
        className={utils.cn([
          styles.optionLabel,
          option.hasParent ? styles.hasParent : null,
        ])}
      >
        <Picto icon={getIcon()} width={18} color="var(--color-primary-600" />
        <span>{option.label}</span>
      </div>
    );
  }, []);

  const handleInputChange = useCallback(debounce((val, { action }) => {
    if (!val || val.length < 3) {
      setFetchResults(null);
    } else if (action === 'input-change') {
      fetchPredictions.mutate(val);
    }
  }, 500), [fetchPredictions]);

  return (
    <div className={utils.cn([
      styles.container,
      disabled ? styles.disabled : null,
      error ? styles.error : null,
      valid ? styles.valid : null,
      className,
    ])}
    >
      <Select
        isClearable
        isSearchable
        classNamePrefix="react-select"
        styles={stylesSelect}
        value={value}
        label={label}
        noOptionsMessage={getListPlaceholder}
        options={options}
        filterOption={filterOption}
        aria-invalid={error != null}
        onInputChange={handleInputChange}
        onChange={onSelect}
        components={{
          DropdownIndicator,
          ClearIndicator,
          ValueContainer,
        }}
        isLoading={fetchPredictions.isLoading}
        loadingMessage={getLoadingMessage}
        formatOptionLabel={formatOptionLabel}
        {...inputProps}
      />
      {error && typeof error === 'string' ? (
        <div className={utils.cn([styles.context, styles.fieldError])}>
          {error}
        </div>
      ) : info && (
        <div className={utils.cn([styles.context, styles.fieldInfo])}>
          {info}
        </div>
      )}
    </div>
  );
}

SearchInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  valid: PropTypes.bool,
  info: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  className: PropTypes.string,
  scope: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SearchInput;
