/* eslint-disable max-len */
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import { Link, useMatch } from 'react-router-dom';

// Components
import {
  Tag,
  utils,
  Picto,
  Table,
  Button,
  UnlockerLoader,
  Message,
} from 'ui-library-unlocker';
import OrgChart from '../../components/organisms/OrgChart/OrgChart';
import RetryPaymentModal from './RetryPaymentModal/RetryPaymentModal';
import RevertPayment from './RevertPayment/RevertPayment';

// Services
import {
  validatePayment,
  getPayment,
  getAdminPayment,
  getPaymentReview,
} from '../../services/payment';
import { getLease } from '../../services/lease';
import { getProperty } from '../../services/property';
import { getOwner } from '../../services/owner';
import { getManagers } from '../../services/person';
import { getCompany } from '../../services/company';
import { getUsersFromAdmin, getCompaniesFromAdmin } from '../../services/admin';

// Hooks
import useResponsive from '../../hooks/useResponsive';
import useRoles from '../../hooks/useRoles';
import useProfile from '../../hooks/useProfile';
import { useAppContext } from '../../store/context';
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Utils
import { formatAddress } from '../../utils/properties';
import { showModal, hideModal } from '../../utils/modal';
import { getPaymentAndAccountStatusVariant } from '../../utils/variants';

// Constants
import { PAYMENT_AND_ACCOUNT_STATUS_LIST } from '../../utils/constants';

import styles from './CashOutDetails.module.scss';

const RETRY_PAYMENT_MODAL_ID = 'retry-payment-modal';

function CashOutDetails() {
  const { t } = useTranslation();
  const { isLaptopS } = useResponsive();
  const match = useMatch('/cashout/:id');
  const { isUserAdmin } = useRoles();
  const { profile } = useProfile();
  const { context: { user, me } } = useAppContext();

  const [beenValidated, setBeenValidated] = useState(false);
  const [paymentRetries, setPaymentRetries] = useState([]);

  const userCompanies = me?.aclMatrix?.companies || [];

  useDocumentTitle(t('cashout.details.browserTitle'));

  const {
    data: paymentData,
    isFetching: paymentFetching,
    refetch: refetchPayment,
  } = useQuery({
    queryKey: ['cashout-payment-details', match?.params?.id, isUserAdmin],
    queryFn: () => (isUserAdmin ? getAdminPayment : getPayment)(match?.params?.id),
    keepPreviousData: true,
  });

  const data = useMemo(() => paymentData?.data || {}, [paymentData]);

  useEffect(() => {
    if (data && Array.isArray(data.children)) {
      setPaymentRetries(data.children);
    }
  }, [data]);

  const {
    data: cashoutFetchedData,
    isFetching: cashoutFetching,
    refetch: refetchCashout,
  } = useQuery({
    queryKey: ['cashout-transfers-details', match?.params?.id, data.paid, isUserAdmin],
    queryFn: () => (isUserAdmin ? getPaymentReview : getPayment)(match?.params?.id),
    keepPreviousData: true,
    enabled: !!data.paid,
  });

  const cashoutData = useMemo(() => cashoutFetchedData?.data || {}, [cashoutFetchedData]);

  const rentInternal = useMemo(() => cashoutData.transfers?.find((transf) => transf.type === 'rent_internal'), [cashoutData]);

  const rentCashOut = useMemo(() => cashoutData.transfers?.find((transf) => transf.type === 'rent_cash_out'), [cashoutData]);

  const rentCashIn = useMemo(() => cashoutData.transfers?.find((transf) => transf.type === 'rent_cash_in'), [cashoutData]);

  const unlockerFees = useMemo(() => cashoutData.transfers?.find((transf) => transf.type === 'unlocker_fees'), [cashoutData]);

  const unlockerFeesCashOut = useMemo(() => cashoutData.transfers?.find((transf) => transf.type === 'unlocker_fees_cash_out'), [cashoutData]);

  const managerFees = useMemo(() => cashoutData.transfers?.find((transf) => transf.type === 'manager_fees'), [cashoutData]);

  const managerFeesCashOut = useMemo(() => cashoutData.transfers?.find((transf) => transf.type === 'manager_fees_cash_out'), [cashoutData]);

  const paymentInfoData = useMemo(() => [
    {
      discriminatingID: data.discriminatingID,
      ezynessRef: rentCashIn?.discriminatingID,
      cashInDate: data.cashInDate,
      dueDate: data.dueDate,
      destination: data.destination,
      amount: data.paidAmount,
      status: data.status,
      paymentMethod: data.paymentMethod,
      leaseAccountCurrentBalance: data.leaseAccountCurrentBalance,
    },
    ...paymentRetries.map((retry) => ({
      discriminatingID: retry.discriminatingID,
      ezynessRef: retry.discriminatingID,
      cashInDate: retry.cashInDate,
      dueDate: retry.dueDate,
      destination: retry.destination,
      amount: retry.paidAmount,
      status: retry.status,
      paymentMethod: retry.paymentMethod,
      leaseAccountCurrentBalance: retry.leaseAccountCurrentBalance,
    })),
  ], [data, rentCashIn, paymentRetries]);

  const unlockerFeesPercent = useMemo(() => {
    if (typeof data.UnlockerFeesInPercent === 'number') {
      return data.UnlockerFeesInPercent / 100;
    }
    return null;
  }, [data]);

  const managerFeesPercent = useMemo(() => {
    if (typeof data.managerFeesInPercent === 'number') {
      return data.managerFeesInPercent / 100;
    }
    return null;
  }, [data]);

  const perceivedManagerFeesPercent = useMemo(() => {
    if (typeof data.perceivedManagerFeesInPercent === 'number') {
      return data.perceivedManagerFeesInPercent / 100;
    }
    return null;
  }, [data]);

  const {
    data: propertyData,
    isFetching: propertyFetching,
  } = useQuery({
    queryKey: ['cashout-property-details', data.leaseUID, isUserAdmin],
    queryFn: async () => {
      const lease = await getLease(data.leaseUID);
      const property = await getProperty(lease?.data?.propertyUid);
      return {
        ...property,
        data: {
          ...property?.data,
          tenantDetails: lease?.data?.tenantDetails || [],
        },
      };
    },
    keepPreviousData: true,
    enabled: !!data.leaseUID,
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const {
    data: propertyUsersData,
    isFetching: propertyUsersFetching,
  } = useQuery({
    queryKey: [
      'cashout-property-users',
      propertyData?.data?.uid,
      isUserAdmin,
      user?.username,
      profile,
    ],
    queryFn: async () => {
      if (!isUserAdmin) {
        let owner; let
          manager = {};
        const collection = [];
        if (!propertyData?.data?.ownedByCompany) {
          owner = user?.username === propertyData?.data?.ownerUid
            ? {
              data: {
                ...profile,
                uid: user?.username,
              },
            }
            : await getOwner(propertyData?.data?.ownerUid);
          if (owner) collection.push(owner);
        }
        if (!propertyData?.data?.managedByCompany) {
          manager = user?.username === propertyData?.data?.managerUid
            ? {
              data: {
                collection: [{
                  ...profile,
                  uid: user?.username,
                }],
              },
            }
            : await getManagers({ filters: { uid: [propertyData?.data?.managerUid] } });
          if (manager?.data?.collection?.[0]) collection.push(manager.data.collection[0]);
        }
        return {
          data: {
            collection,
          },
        };
      }
      const usersUID = [];
      if (!propertyData?.data?.ownedByCompany) usersUID.push(propertyData?.data?.ownerUid);
      if (!propertyData?.data?.managedByCompany) usersUID.push(propertyData?.data?.managerUid);
      return getUsersFromAdmin({
        filters: {
          uid: usersUID,
        },
      });
    },
    keepPreviousData: true,
    enabled: (
      !!propertyData?.data?.uid
      && (!propertyData?.data?.ownedByCompany || !propertyData?.data?.managedByCompany)
    ),
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const {
    data: propertyCompaniesData,
    isFetching: propertyCompaniesFetching,
  } = useQuery({
    queryKey: ['cashout-property-companies', propertyData?.data?.uid, isUserAdmin, userCompanies],
    queryFn: async () => {
      if (!isUserAdmin) {
        let owner; let
          manager = {};
        const collection = [];
        if (propertyData?.data?.ownedByCompany) {
          owner = userCompanies.find((c) => c.uid === propertyData?.data?.ownerUid)
            || await getCompany(propertyData?.data?.ownerUid);
          if (owner) collection.push(owner);
        }
        if (propertyData?.data?.managedByCompany) {
          const managementByUserCompany = userCompanies.find((c) => c.uid === propertyData?.data?.managerUid);
          manager = managementByUserCompany
            ? {
              data: {
                collection: [managementByUserCompany],
              },
            }
            : await getManagers({ filters: { uid: [propertyData?.data?.managerUid] } });
          if (manager?.data?.collection?.[0]) collection.push(manager.data.collection[0]);
        }
        return {
          data: {
            collection,
          },
        };
      }
      const companiesUID = [];
      if (propertyData?.data?.ownedByCompany) companiesUID.push(propertyData?.data?.ownerUid);
      if (propertyData?.data?.managedByCompany) companiesUID.push(propertyData?.data?.managerUid);
      return getCompaniesFromAdmin({
        filters: {
          uid: companiesUID,
        },
      });
    },
    keepPreviousData: true,
    enabled: (
      !!propertyData?.data?.uid
      && (propertyData?.data?.ownedByCompany || propertyData?.data?.managedByCompany)
    ),
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const managerInfo = useMemo(() => {
    const collection = propertyData?.data?.managedByCompany
      ? propertyCompaniesData?.data?.collection
      : propertyUsersData?.data?.collection;
    return collection?.find((u) => u.uid === propertyData?.data?.managerUid);
  }, [propertyUsersData, propertyData, propertyCompaniesData]);

  const ownerInfo = useMemo(() => {
    const collection = propertyData?.data?.ownedByCompany
      ? propertyCompaniesData?.data?.collection
      : propertyUsersData?.data?.collection;
    return collection?.find((u) => u.uid === propertyData?.data?.ownerUid);
  }, [propertyUsersData, propertyData, propertyCompaniesData]);

  const rentTotal = useMemo(() => {
    const charges = propertyData?.data?.charges || 0;
    const rentExcludingCharges = propertyData?.data?.rentExcludingCharges || 0;
    return rentExcludingCharges + charges;
  }, [propertyData]);

  const refetchAfterRevert = useCallback(() => {
    refetchPayment();
    if (data.paid) {
      refetchCashout();
    }
  }, [refetchPayment, refetchCashout, data]);

  const paymentMutation = useMutation({
    mutationFn: ({ transfers }) => Promise.all(transfers.map((transfer) => validatePayment(transfer.uid))),
    onSuccess: (_data, variables) => {
      utils.toast.success(t('cashout.validationSuccess', { name: variables.friendlyName }));
      setBeenValidated(true);
      refetchAfterRevert();
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const handleVerify = useCallback(() => {
    paymentMutation.mutate({
      transfers: cashoutData.transfers
        ?.filter((transfer) => transfer.status === PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_CHECK),
      friendlyName: data.friendlyName,
    });
  }, [paymentMutation, data, cashoutData]);

  const paymentInfoColumns = useMemo(() => ([
    {
      header: t('cashout.details.paymentInfo.table.columns.id'),
      accessorKey: 'discriminatingID',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => <span className={styles.discriminatingID}>{original.discriminatingID}</span>,
    },
    {
      header: t('cashout.details.paymentInfo.table.columns.ezynessRef'),
      accessorKey: 'ezynessRef',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { ezynessRef } } }) => <span>{ezynessRef || '--'}</span>,
    },
    {
      header: t('cashout.details.paymentInfo.table.columns.date'),
      accessorKey: 'cashInDate',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { cashInDate } } }) => (
        <span>
          {cashInDate ? format(new Date(cashInDate), 'dd/MM/yyyy', { locale: fr }) : '--'}
        </span>
      ),
    },
    {
      header: t('cashout.details.paymentInfo.table.columns.dueDate'),
      accessorKey: 'dueDate',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { dueDate } } }) => (
        <span>
          {dueDate ? format(new Date(dueDate), 'dd/MM/yyyy', { locale: fr }) : '--'}
        </span>
      ),
    },
    {
      header: t('cashout.details.paymentInfo.table.columns.destination'),
      accessorKey: 'destination',
      size: 150,
      enableSorting: false,
    },
    {
      header: t('cashout.details.paymentInfo.table.columns.leaseAccountCurrentBalance'),
      accessorKey: 'leaseAccountCurrentBalance',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => (
        <span>
          {original.leaseAccountCurrentBalance !== null && typeof original.leaseAccountCurrentBalance !== 'undefined'
            ? `${utils.centsToEuro(original.leaseAccountCurrentBalance)}€`
            : t('cashout.details.paymentInfo.table.noBalance')}
        </span>
      ),
    },
    {
      header: t('cashout.details.paymentInfo.table.columns.paymentMethod'),
      accessorKey: 'paymentMethod',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { paymentMethod } } }) => (
        <span>
          {t(`global.paymentMethods.${paymentMethod}`)}
        </span>
      ),
    },
    {
      header: t('cashout.details.paymentInfo.table.columns.status'),
      accessorKey: 'status',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => (
        <Tag
          size="medium"
          variant={getPaymentAndAccountStatusVariant(status)}
          label={t(`cashout.paymentStatus.${isUserAdmin ? 'admin' : 'lessor'}.${status}`)}
        />
      ),
    },
    {
      header: t('cashout.details.paymentInfo.table.columns.amount'),
      accessorKey: 'amount',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => <span className={styles.paymentInfoAmount}>{`${utils.centsToEuro(original.amount)}€`}</span>,
    },
  ]), [t, isUserAdmin]);

  const propertyInfoColumns = useMemo(() => ([
    {
      header: t('cashout.details.propertyInfo.table.columns.id'),
      accessorKey: 'unlockerID',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original } }) => <span className={styles.discriminatingID}>{original.unlockerID}</span>,
    },
    {
      header: t('cashout.details.propertyInfo.table.columns.address'),
      accessorKey: 'property',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { address, name, uid } } }) => (
        <div>
          <div className={styles.nameAddress}>
            <Link to={`/property/${uid}`} target="_blank">
              {name}
              <Picto
                icon="export-link"
                width={9}
                color="var(--color-secondary)"
                className="m-l-5"
              />
            </Link>
            <p className="p-2-500">
              {formatAddress(address)}
            </p>
          </div>
        </div>
      ),
    },
    {
      header: t('cashout.details.propertyInfo.table.columns.tenant'),
      accessorKey: 'tenant',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { tenantDetails } } }) => (
        <div>
          {Array.isArray(tenantDetails) && tenantDetails.map(({ firstName, lastName, uid }) => (
            <p key={uid} className="p-2-700">
              {`${firstName} ${lastName}`}
            </p>
          ))}
        </div>
      ),
    },
    {
      header: t('cashout.details.propertyInfo.table.columns.manager'),
      accessorKey: 'manager',
      size: 100,
      enableSorting: false,
      cell: () => {
        const managerData = managerInfo || managerFees?.destination;
        const managerPPHName = managerData?.firstName && managerData?.lastName
          ? `${managerData?.firstName} ${managerData?.lastName}`
          : managerData?.companyName;
        const managerName = propertyData?.data?.managedByCompany
          ? managerData?.legalName || managerData?.companyName
          : managerPPHName;
        return (
          <p className="p-2-700">
            {managerInfo?.uid === ownerInfo?.uid
              ? '--'
              : managerName}
          </p>
        );
      },
    },
    {
      header: t('cashout.details.propertyInfo.table.columns.owner'),
      accessorKey: 'owner',
      size: 100,
      enableSorting: false,
      cell: () => {
        const ownerData = ownerInfo || rentInternal?.destination;
        return (
          <p className="p-2-700">
            {propertyData?.data?.ownedByCompany
              ? ownerData?.legalName || ownerData?.companyName
              : `${ownerData?.firstName} ${ownerData?.lastName}`}
          </p>
        );
      },
    },
    {
      header: t('cashout.details.propertyInfo.table.columns.price'),
      accessorKey: 'rent',
      size: 100,
      enableSorting: false,
      cell: () => <span className={styles.paymentInfoAmount}>{`${utils.centsToEuro(rentTotal)}€`}</span>,
    },
  ]), [t, managerInfo, ownerInfo, rentTotal, propertyData, managerFees, rentInternal]);

  const cashoutTransferStatus = useMemo(() => (s) => {
    if (beenValidated) {
      return {
        variant: 'primary',
        className: styles.orgChartReady,
        icon: 'timer',
      };
    }
    switch (s) {
      case PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_CHECK:
        return {
          variant: 'secondary',
          className: styles.orgChartToValidate,
          icon: 'timer',
        };
      case PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_WIRE:
      case PAYMENT_AND_ACCOUNT_STATUS_LIST.IN_WIRING:
        return {
          variant: 'primary',
          className: styles.orgChartReady,
          icon: 'timer',
        };
      case PAYMENT_AND_ACCOUNT_STATUS_LIST.SENT:
      case PAYMENT_AND_ACCOUNT_STATUS_LIST.RECEIVED:
        return {
          variant: 'success',
          className: styles.orgChartSuccess,
          icon: 'tick-circle',
        };
      case PAYMENT_AND_ACCOUNT_STATUS_LIST.FAILED:
      case PAYMENT_AND_ACCOUNT_STATUS_LIST.NOT_DOABLE:
      case PAYMENT_AND_ACCOUNT_STATUS_LIST.REJECTED_SDD:
        return {
          variant: 'primary',
          className: styles.orgChartError,
          icon: 'alert-circle',
        };
      default:
        return {
          variant: 'error',
          className: null,
        };
    }
  }, [t]);

  const orgChartData = useMemo(() => {
    const siblings = [];
    if (managerFees) {
      const feesAndRentDistribution = (managerFees.amount || 0) + (data.rentDistributionAmount || 0);
      siblings.push({
        id: 4,
        label: (
          <div className={styles.orgChartLabel}>
            <span className={styles.orgChartLabelTitle}>{managerFees.destination?.companyName}</span>
            <small className={styles.orgChartLabelSubtitle}>{t('cashout.details.vent.commissionAccount')}</small>
            <span className={styles.orgChartLabelDetails}>
              {`${t('cashout.details.vent.vent')} : `}
              <b>{`${feesAndRentDistribution >= 0 ? '+' : ''}${utils.centsToEuro(feesAndRentDistribution)}€`}</b>
            </span>
            <span>
              {managerFees.createdAt
                ? t('cashout.details.vent.onDate', { date: format(new Date(managerFees.createdAt), 'dd/MM/yyyy', { locale: fr }) })
                : '--'}
            </span>
          </div>
        ),
        children: [
          {
            id: 5,
            label: (
              <div
                className={utils.cn([
                  styles.orgChartLabel,
                  cashoutTransferStatus(managerFeesCashOut?.status)?.className,
                ])}
              >
                <span className={styles.orgChartLabelTitle}>{managerFeesCashOut?.destination?.companyName}</span>
                <small className={styles.orgChartLabelSubtitle}>{t('cashout.details.vent.externalAccount')}</small>
                <Tag
                  size="small"
                  variant={cashoutTransferStatus(managerFeesCashOut?.status)?.variant}
                  label={t(`cashout.status.${managerFeesCashOut?.status}`)}
                  icon={cashoutTransferStatus(managerFeesCashOut?.status)?.icon}
                  className="m-t-10"
                />
                <span className={styles.orgChartLabelDetails}>
                  {`${t('cashout.details.vent.cashout')} : `}
                  <b>{`${utils.centsToEuro(managerFeesCashOut.amount)}€`}</b>
                </span>
                <span>
                  {managerFeesCashOut?.wiringVisibilityDate
                    ? t('cashout.details.vent.onDate', { date: format(new Date(managerFeesCashOut?.wiringVisibilityDate), 'dd/MM/yyyy', { locale: fr }) })
                    : '--'}
                </span>
              </div>
            ),
          },
        ],
      });
    }
    siblings.push({
      id: 5,
      label: (
        <div className={styles.orgChartPhantomLabel} />
      ),
      children: [
        {
          id: 51,
          label: (
            <div
              className={utils.cn([
                styles.orgChartLabel,
                cashoutTransferStatus(rentCashOut?.status)?.className,
              ])}
            >
              <span className={styles.orgChartLabelTitle}>
                {rentCashOut?.destination?.isCompany
                  ? rentCashOut?.destination?.companyName
                  : `${rentCashOut?.destination?.firstName} ${rentCashOut?.destination?.lastName}`}
              </span>
              <small className={styles.orgChartLabelSubtitle}>{t('cashout.details.vent.externalAccount')}</small>
              <Tag
                size="small"
                variant={cashoutTransferStatus(rentCashOut?.status)?.variant}
                label={t(`cashout.status.${rentCashOut?.status}`)}
                icon={cashoutTransferStatus(rentCashOut?.status)?.icon}
                className="m-t-10"
              />
              <span className={styles.orgChartLabelDetails}>
                {`${t('cashout.details.vent.cashout')} : `}
                <b>{`${utils.centsToEuro(rentCashOut?.amount)}€`}</b>
              </span>
              <span>
                {rentCashOut?.wiringVisibilityDate
                  ? t('cashout.details.vent.onDate', { date: format(new Date(rentCashOut?.wiringVisibilityDate), 'dd/MM/yyyy', { locale: fr }) })
                  : '--'}
              </span>
            </div>
          ),
        },
      ],
    });
    if (unlockerFees) {
      siblings.push(...[
        {
          id: 6,
          label: (
            <div className={styles.orgChartLabel}>
              <span className={styles.orgChartLabelTitle}>Unlocker</span>
              <small className={styles.orgChartLabelSubtitle}>{t('cashout.details.vent.commissionAccount')}</small>
              <span className={styles.orgChartLabelDetails}>
                {`${t('cashout.details.vent.vent')} : `}
                <b>{`${unlockerFees.amount >= 0 ? '+' : ''}${utils.centsToEuro(unlockerFees.amount)}€`}</b>
              </span>
              <span>
                {unlockerFees.createdAt
                  ? t('cashout.details.vent.onDate', { date: format(new Date(unlockerFees.createdAt), 'dd/MM/yyyy', { locale: fr }) })
                  : '--'}
              </span>
            </div>
          ),
          children: [
            {
              id: 7,
              label: (
                <div
                  className={utils.cn([
                    styles.orgChartLabel,
                    cashoutTransferStatus(unlockerFeesCashOut?.status)?.className,
                  ])}
                >
                  <span className={styles.orgChartLabelTitle}>Unlocker</span>
                  <small className={styles.orgChartLabelSubtitle}>{t('cashout.details.vent.externalAccount')}</small>
                  <Tag
                    size="small"
                    variant={cashoutTransferStatus(unlockerFeesCashOut?.status)?.variant}
                    label={t(`cashout.status.${unlockerFeesCashOut?.status}`)}
                    icon={cashoutTransferStatus(unlockerFeesCashOut?.status)?.icon}
                    className="m-t-10"
                  />
                  <span className={styles.orgChartLabelDetails}>
                    {`${t('cashout.details.vent.cashout')} : `}
                    <b>{`${utils.centsToEuro(unlockerFeesCashOut?.amount)}€`}</b>
                  </span>
                  <span>
                    {unlockerFeesCashOut?.wiringVisibilityDate
                      ? t('cashout.details.vent.onDate', { date: format(new Date(unlockerFeesCashOut?.wiringVisibilityDate), 'dd/MM/yyyy', { locale: fr }) })
                      : '--'}
                  </span>
                </div>
              ),
            },
          ],
        },
      ]);
    }

    return ({
      id: 1,
      label: (
        <div
          className={utils.cn([
            styles.orgChartLabel,
            styles.orgChartSource,
          ])}
        >
          <span className={styles.orgChartLabelTitle}>
            {`${rentCashIn?.source?.firstName} ${rentCashIn?.source?.lastName}`}
          </span>
          <small className={styles.orgChartLabelSubtitle}>{t('cashout.details.vent.tenant')}</small>
          <span className={styles.orgChartLabelDetails}>
            {t('cashout.details.vent.sentPayment')}
            <br />
            <b>{`${utils.centsToEuro(rentCashIn?.amount)}€`}</b>
          </span>
          <span>
            {rentCashIn?.createdAt
              ? t('cashout.details.vent.receivedOnDate', { date: format(new Date(rentCashIn?.createdAt), 'dd/MM/yyyy', { locale: fr }) })
              : '--'}
          </span>
        </div>
      ),
      children: [
        {
          id: 2,
          label: (
            <div
              className={utils.cn([
                styles.orgChartLabel,
                styles.orgChartProperty,
              ])}
            >
              <span className={styles.orgChartLabelTitle}>{propertyData?.data?.name}</span>
              <small className={styles.orgChartLabelSubtitle}>{t('cashout.details.vent.techAccount')}</small>
              <span className={styles.orgChartLabelDetails}>
                {t('cashout.details.vent.receivedPayment')}
                <br />
                <b>{`${utils.centsToEuro(rentCashIn?.amount)}€`}</b>
              </span>
              <span>
                {rentCashIn?.createdAt
                  ? t('cashout.details.vent.receivedOnDate', { date: format(new Date(rentCashIn?.createdAt), 'dd/MM/yyyy', { locale: fr }) })
                  : '--'}
              </span>
            </div>
          ),
          children: [
            {
              id: 3,
              label: (
                <div className={styles.orgChartLabel}>
                  <span className={styles.orgChartLabelTitle}>
                    {rentInternal?.destination?.isCompany
                      ? rentInternal?.destination?.companyName
                      : `${rentInternal?.destination?.firstName} ${rentInternal?.destination?.lastName}`}
                  </span>
                  <small className={styles.orgChartLabelSubtitle}>{t('cashout.details.vent.pmoAccount')}</small>
                  <span className={styles.orgChartLabelDetails}>
                    {`${t('cashout.details.vent.vent')} : `}
                    <b>{`${rentInternal?.amount >= 0 ? '+' : ''}${utils.centsToEuro(rentInternal?.amount)}€`}</b>
                  </span>
                  <span>
                    {rentInternal?.createdAt
                      ? t('cashout.details.vent.onDate', { date: format(new Date(rentInternal?.createdAt), 'dd/MM/yyyy', { locale: fr }) })
                      : '--'}
                  </span>
                </div>
              ),
              children: siblings,
            },
          ],
        },
      ],
    });
  }, [
    t,
    rentCashIn,
    rentCashOut,
    propertyData,
    rentInternal,
    managerFees,
    managerFeesCashOut,
    unlockerFees,
    unlockerFeesCashOut,
    data,
  ]);

  const statusStyle = useMemo(() => {
    const { paidAmount, rentAmount, status } = data;
    if (status === PAYMENT_AND_ACCOUNT_STATUS_LIST.REJECTED_SDD) return 'error';
    if (paidAmount === null || typeof paidAmount === 'undefined') return null;
    if (rentAmount === null || typeof paidAmount === 'undefined') return null;
    if (paidAmount < rentAmount) return 'error';
    if (paidAmount === rentAmount) return 'success';
    if (paidAmount > rentAmount) return 'secondary';
    return null;
  }, [data]);

  const btnDisabled = useMemo(() => {
    if (beenValidated) return true;
    if (cashoutData?.transfers?.some((transfer) => transfer.status === PAYMENT_AND_ACCOUNT_STATUS_LIST.NOT_DOABLE)) {
      return true;
    }
    if (cashoutData?.transfers?.some((transfer) => transfer.status === PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_CHECK)) {
      return false;
    }
    return true;
  }, [beenValidated, cashoutData]);

  const canRetryPayment = useMemo(() => {
    if (isUserAdmin) return true;
    // const payments = [data, ...paymentRetries];
    // return payments.every((payment) => [
    //   PAYMENT_AND_ACCOUNT_STATUS_LIST.FAILED,
    //   PAYMENT_AND_ACCOUNT_STATUS_LIST.CANCELED,
    //   PAYMENT_AND_ACCOUNT_STATUS_LIST.SCHEDULING_ERROR,
    // ].includes(payment.status));
    return false;
  }, [data, paymentRetries, isUserAdmin]);

  const canRevertPayment = useMemo(() => {
    if (!isUserAdmin) return false;
    const payments = [data, cashoutData, ...paymentRetries];
    return payments.some((payment) => payment.status === PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_WIRE);
  }, [isUserAdmin, data, paymentRetries, cashoutData]);

  const managerFeesTotal = useMemo(() => {
    const managerAmount = managerFees?.amount || 0;
    const unlockerAmount = unlockerFees?.amount || 0;
    const rentDistribAmount = data.rentDistributionAmount || 0;
    return managerAmount + unlockerAmount + rentDistribAmount;
  }, [managerFees]);

  if (
    propertyFetching
    || propertyUsersFetching
    || propertyCompaniesFetching
    || paymentFetching
    || cashoutFetching
  ) {
    return <UnlockerLoader size={300} />;
  }

  return (
    <div>
      <div className={styles.heading}>
        <h1 className="t-h1-700">{t('cashout.details.title')}</h1>
        {data?.status === PAYMENT_AND_ACCOUNT_STATUS_LIST.REJECTED_SDD && (
          <Tag
            size="medium"
            variant="error"
            label={t('cashout.details.status.error')}
          />
        )}
        {data?.paid && (
          <Tag
            size="medium"
            variant={statusStyle || 'primary'}
            label={t(`cashout.details.status.${statusStyle}`)}
          />
        )}
      </div>
      <div className={styles.content}>
        {(
          beenValidated
          || cashoutData?.transfers
            ?.some((transfer) => transfer.status === PAYMENT_AND_ACCOUNT_STATUS_LIST.READY_TO_WIRE)
          || cashoutData?.transfers
            ?.some((transfer) => transfer.status === PAYMENT_AND_ACCOUNT_STATUS_LIST.IN_WIRING)
        ) && (
          <Message
            className="m-b-20"
            content={t('cashout.details.message.readyToWire')}
            variant="info"
          />
        )}
        <div className={styles.overview}>
          <div className={styles.overviewCard}>
            <h3 className="t-h3-700 m-b-20">{t('cashout.details.amount.title')}</h3>
            <div className={styles.overviewCardContent}>
              {!isLaptopS && (
                <img src="/images/vent_overview.png" alt="overview" />
              )}
              <ul>
                <li>
                  <span>
                    {`${t('cashout.details.amount.rentIncludingCharges')} : ${utils.centsToEuro(data.rentAmount)}€`}
                  </span>
                </li>
                <li>
                  <span>
                    {`${t('cashout.details.amount.rentDistribution')} : ${utils.centsToEuro(data.rentDistributionAmount)}€`}
                  </span>
                </li>
                <li>
                  <span>
                    {`${t('cashout.details.amount.managerFees')} : ${managerFeesPercent}% - ${utils.centsToEuro(data.managerFeesInEuro)}€`}
                  </span>
                  {unlockerFeesPercent && (
                    <ul>
                      <li>
                        <span>
                          {`${t('cashout.details.amount.unlockerFees')} : ${unlockerFeesPercent}% - ${utils.centsToEuro(data.UnlockerFeesInEuro)}€`}
                        </span>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <Picto
            icon="arrow-right-bulk"
            width={70}
            color="var(--color-primary-400)"
            className={styles.arrowIcon}
          />
          <div
            className={utils.cn([
              styles.overviewCard,
              data?.status === PAYMENT_AND_ACCOUNT_STATUS_LIST.REJECTED_SDD ? styles.error : null,
              data?.paid ? styles[statusStyle] : styles.disabledOverviewCard,
            ])}
          >
            <h3 className="t-h3-700 m-b-20">{t('cashout.details.amount.paidTitle')}</h3>
            <div className={styles.overviewCardContent}>
              {!isLaptopS && (
                <img src="/images/vent_overview.png" alt="overview" />
              )}
              <ul>
                <li>
                  <span>
                    {`${t('cashout.details.amount.paidAmount')} : ${utils.centsToEuro(data.paidAmount)}€`}
                  </span>
                </li>
                <li>
                  <span>
                    {`${t('cashout.details.amount.managerAmount')} : ${utils.centsToEuro(managerFeesTotal || 0)}€`}
                  </span>
                  {unlockerFeesPercent && (
                    <ul>
                      <li>
                        <span>
                          {`${t('cashout.details.amount.managerFees')} : ${perceivedManagerFeesPercent || managerFeesPercent}% (+${utils.centsToEuro(managerFees?.amount || 0)}€`}
                          )
                        </span>
                      </li>
                      <li>
                        <span>
                          {`${t('cashout.details.amount.unlockerFees')} : ${unlockerFeesPercent}% (-${utils.centsToEuro(unlockerFees?.amount || 0)}€`}
                          )
                        </span>
                      </li>
                      <li>
                        <span>
                          {`${t('cashout.details.amount.rentDistribution')} : (+${utils.centsToEuro(data.rentDistributionAmount || 0)}€`}
                          )
                        </span>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <h3 className="t-h3-700 m-t-20">{t('cashout.details.paymentInfo.title')}</h3>
        <div className="m-t-15">
          <Table
            fullWidth
            columns={paymentInfoColumns}
            data={paymentInfoData}
            initialState={{
              columnVisibility: {
                destination: isUserAdmin,
                ezynessRef: isUserAdmin,
                leaseAccountCurrentBalance: isUserAdmin,
              },
            }}
            // isLoading={paymentsFetching || leaseListFetching}
          />
          <div className={utils.cn([
            styles.paymentActionsBtnContainer,
            'm-t-15 m-b-35',
          ])}
          >
            {canRetryPayment && (
            <>
              <Button
                variant="primary"
                size="medium"
                label={t('cashout.details.retryPayment.btnLabel')}
                onClick={() => showModal(RETRY_PAYMENT_MODAL_ID)}
              />
              <RetryPaymentModal
                modalId={RETRY_PAYMENT_MODAL_ID}
                onClose={() => hideModal(RETRY_PAYMENT_MODAL_ID)}
                paymentUID={match.params?.id}
                setPaymentRetries={setPaymentRetries}
              />
            </>
            )}
            {canRevertPayment && (
              <RevertPayment
                refetch={refetchAfterRevert}
                transfers={cashoutData.transfers}
              />
            )}
          </div>
        </div>
        <h3 className="t-h3-700 m-t-20">{t('cashout.details.propertyInfo.title')}</h3>
        <div className="m-t-15">
          <Table
            fullWidth
            columns={propertyInfoColumns}
            data={[propertyData?.data || {}]}
            isLoading={propertyFetching || propertyUsersFetching}
          />
        </div>
        <h3 className="t-h3-700 m-t-20">{t('cashout.details.vent.title')}</h3>
        {data?.paid ? (
          <>
            <OrgChart
              data={orgChartData}
              className={styles.orgChart}
              lineHeight="50px"
            />
            {isUserAdmin && (
              <Button
                variant="primary"
                size="large"
                label={t('cashout.details.vent.validate')}
                className={styles.validateBtn}
                loading={paymentMutation.isLoading}
                onClick={handleVerify}
                disabled={btnDisabled}
              />
            )}
          </>
        ) : (
          <Message
            className="m-t-15"
            content={t('cashout.details.message.noVent')}
            variant="info"
          />
        )}
      </div>
    </div>
  );
}

export default CashOutDetails;
