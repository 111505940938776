import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Components
import { Button, Picto, utils } from 'ui-library-unlocker';

// Hooks
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Styles
import styles from './500.module.scss';

function ErrorView() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useDocumentTitle(t('500.browserTitle'));

  return (
    <div className={styles.notFound}>
      <div
        className={styles.cover}
      >
        <Picto
          className={utils.cn([styles.decoration, styles.topDeco])}
          icon="point-moyen-900"
        />
        <Picto
          className={utils.cn([styles.decoration, styles.bottomDeco])}
          icon="gribouillon-6"
        />
        <img
          className={styles.img}
          src="/images/500.png"
          alt="Personne assise dans un salon avec un ordinateur sur les genoux"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <Picto
            className={styles.circle}
            icon="rond-1"
          />
          <h1>{t('500.title')}</h1>

        </div>
        <h2>{t('500.subTitle')}</h2>
        <p className="m-b-20">{t('500.explanation')}</p>
        <Button
          onClick={() => navigate('/messages')}
          label={t('500.contactSupport')}
          variant="secondary"
          size="large"
          blockLevel
          iconSide="left"
          icon="mail"
        />
      </div>
    </div>
  );
}

export default ErrorView;
