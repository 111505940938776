import api from './_client';
// import { ENROLMENT_STATUS } from '../utils/constants';

// const {
//   PROFILE_DONE,
//   COMPLETED,
//   ENROLMENT_BYPASSED,
//   PENDING,
//   NO_PROFILE,
// } = ENROLMENT_STATUS;

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @returns {Promise}
 */
export const getOwners = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[status][--in--]': filters.status?.join?.(','),
    'filters[onboardingStatus][--in--]': filters.onboardingStatus?.join?.(','),
    'filters[onboardingStatus][--not-eq--]': filters.onboardingStatusNot?.join?.(','),
    'filters[companies][uid][--in--]': filters.companies?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/person/property-owners', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const searchOwner = (data) => api.post('/person/property-owners/search', data);

export const getOwner = (uid) => api.get(`/person/property-owners/${uid}`);

export const createOwner = (data) => api.post('/person/property-owners', data);

export const updateOwner = (uid, data) => api.put(`/person/property-owners/${uid}`, data);

export const createOwnerDocument = ({
  type,
  name,
  documentUid,
  personUID,
}) => api.post(`/person/property-owners/${personUID}/documents`, {
  type, name, documentUid, personUid: personUID,
});

export const getOwnerDocumentList = (uid) => api.get(`/person/property-owners/${uid}/documents`);
