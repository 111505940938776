import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

// Components
import {
  ToggleInput,
  Button,
} from 'ui-library-unlocker';

// Styles
// import styles from './SettingsOwners.module.scss';

function SettingsOwners() {
  const { t } = useTranslation();

  const { formik } = useOutletContext();

  return (
    <div>
      <h1>{t('settings.owners.title')}</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="m-t-25">
          <p>{t('settings.owners.ownerAdded')}</p>
          <ToggleInput
            id="sendInvitationToDelegatedPropertyOwner"
            name="sendInvitationToDelegatedPropertyOwner"
            className="m-t-10 m-l-10"
            label={t('settings.owners.form.sendInvitationToDelegatedPropertyOwner')}
            checked={!!formik.values.propertyOwner?.sendInvitationToDelegatedPropertyOwner}
            onChange={(check) => formik.setFieldValue('propertyOwner.sendInvitationToDelegatedPropertyOwner', check)}
          />
        </div>
        <Button
          type="submit"
          className="m-t-35"
          size="large"
          label={t('global.save')}
          loading={formik.isSubmitting}
        />
      </form>
    </div>
  );
}

export default SettingsOwners;
