import decode from 'jwt-decode';

import useProfile from '../hooks/useProfile';

import {
  RENT_DISTRIBUTION_STATUS,
  ENROLMENT_STATUS,
} from './constants';

export const getUserFromToken = (token) => {
  try {
    const user = decode(token);
    return user;
  } catch (error) {
    return null;
  }
};

export const doesUserHaveRole = (userRoles, roles) => {
  if (!userRoles || !roles) {
    return false;
  }

  return roles.some((role) => userRoles.includes(role));
};

export const checkIfSendProfileValidationRequest = (data) => {
  const { profile } = useProfile();
  if (data === null) return false;
  if (!profile && !data) return false;
  const rentDistribution = data || profile?.rentDistribution;
  return [
    RENT_DISTRIBUTION_STATUS.ONLY_WITH_PERSONAL_ACCOUNT,
    RENT_DISTRIBUTION_STATUS.WITH_BOTH_PERSONAL_AND_COMPANIES_ACCOUNT,
  ].includes(rentDistribution);
};

export const handleImpersonate = ({
  user,
  accessToken,
  refreshToken,
}) => {
  localStorage.setItem('accessTokenBackup', localStorage.getItem('accessToken'));
  localStorage.setItem('refreshTokenBackup', localStorage.getItem('refreshToken'));
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('impersonateIdentity', JSON.stringify(user));
  localStorage.setItem('hasCompletedOnboardingFunnelBackup', localStorage.getItem('hasCompletedOnboardingFunnel'));
  localStorage.removeItem('hasCompletedOnboardingFunnel');
  localStorage.setItem('hasRealEstateAgencyBackup', localStorage.getItem('hasRealEstateAgency'));
  localStorage.removeItem('roleSpace');
  // eslint-disable-next-line no-restricted-globals
  location.replace('/');
};

export const handleUnimpersonate = () => {
  localStorage.setItem('accessToken', localStorage.getItem('accessTokenBackup'));
  localStorage.setItem('refreshToken', localStorage.getItem('refreshTokenBackup'));
  localStorage.removeItem('accessTokenBackup');
  localStorage.removeItem('refreshTokenBackup');
  localStorage.removeItem('impersonateIdentity');
  localStorage.setItem('hasCompletedOnboardingFunnel', localStorage.getItem('hasCompletedOnboardingFunnelBackup'));
  localStorage.removeItem('hasCompletedOnboardingFunnelBackup');
  localStorage.setItem('hasRealEstateAgency', localStorage.getItem('hasRealEstateAgencyBackup'));
  localStorage.removeItem('hasRealEstateAgencyBackup');
  localStorage.removeItem('roleSpace');
  // eslint-disable-next-line no-restricted-globals
  location.replace('/');
};

export const logoutUser = (dispatch) => {
  dispatch({ type: 'LOGOUT_USER' });
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('accessTokenBackup');
  localStorage.removeItem('refreshTokenBackup');
  localStorage.removeItem('hasCompletedOnboardingFunnel');
  localStorage.removeItem('hasCompletedOnboardingFunnelBackup');
  localStorage.removeItem('hasRealEstateAgency');
  localStorage.removeItem('hasRealEstateAgencyBackup');
  localStorage.removeItem('roleSpace');
};

export const getPersonName = (person, t) => {
  if (!t || !person) return '';
  if (person.onboardingStatus === ENROLMENT_STATUS.NO_PROFILE) {
    return `${t('global.invited')} (${person.email})`;
  }
  return `${person.firstName} ${person.lastName}`;
};
