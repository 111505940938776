import { utils } from 'ui-library-unlocker';
import api from './_client';

/**
 *
 * @param {number} page page number
 * @param {number} itemsPerPage number of items per page
 * @returns {Promise}
 */
export const getResidences = ({ page, itemsPerPage, filters = {} }) => {
  const apiFilters = {
    'filters[uid][--in--]': filters.uid?.join?.(','),
    'filters[type][--in--]': filters.type?.join?.(','),
    'filters[rentExcludingCharges][--min--]': utils.euroToCents(filters.rentPriceMin?.[0]) || undefined,
    'filters[rentExcludingCharges][--max--]': utils.euroToCents(filters.rentPriceMax?.[0]) || undefined,
    'filters[ownerUid][--in--]': filters.ownerUid?.join?.(','),
    'filters[managerUid][--in--]': filters.managerUid?.join?.(','),
    'filters[properties][uid][--in--]': filters.properties?.join?.(','),
    [`orderBy[${filters?.orderBy?.property}]`]: filters.orderBy?.direction,
    q: filters.search?.[0],
  };

  return api.get('/property/residential-complexes', {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const getResidence = (uid) => api.get(`/property/residential-complexes/${uid}`);

export const createResidence = (data) => api.post('/property/residential-complexes', data);

export const updateResidence = (uid, data) => api.put(`/property/residential-complexes/${uid}`, data);

export const deleteResidence = (uid) => api.delete(`/property/residential-complexes/${uid}`);

export const deleteResidenceAndProperties = (uid) => api
  .delete(`/property/residential-complexes/${uid}/with-properties`);

export const updateResidenceCharacteristics = (uid, data) => api
  .put(`/property/residential-complexes/${uid}/characteristics`, data);

// Documents

export const getResidenceDocuments = (uid, {
  page = null,
  itemsPerPage = null,
  filters = {},
}) => {
  const apiFilters = {
    'filters[type][--in--]': filters.type?.join?.(','),
  };

  return api.get(`/property/residential-complexes/${uid}/documents`, {
    params: {
      page,
      itemsPerPage,
      ...apiFilters,
    },
  });
};

export const createResidenceDocument = (uid, {
  type,
  name,
  documentUid,
  date,
}) => api.post(`/property/residential-complexes/${uid}/documents`, {
  type, name, documentUid, date,
});

export const deleteResidenceDocument = (uid) => api.delete(`/property/residential-complexes/documents/${uid}`);
