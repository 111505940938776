import { Picto } from 'ui-library-unlocker';

import { ROLES } from '../../../utils/constants';

export default (accessToken, t, isUserAdmin, killSwitches) => {
  const {
    paymentpage,
    rentDistribution,
    inventoryPage,
    internalTransfer,
  } = killSwitches || {};

  if (accessToken) {
    return {
      personal: {
        title: t('menu.personal.title'),
        links: [
          {
            label: t('menu.personal.dashboard'),
            to: '/',
            match: ['/'],
            icon: <Picto color="var(--color-white)" width={20} icon="chart" />,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
              ROLES.ROLE_USER,
            ],
          },
          {
            label: t('menu.personal.profile'),
            match: ['/profile'],
            to: '/profile',
            icon: <Picto color="var(--color-white)" width={20} icon="profile-circle" />,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
              ROLES.ROLE_USER,
            ],
          },
          {
            label: t('menu.personal.bankingIdentity'),
            match: ['/banking-identities'],
            to: '/banking-identities',
            icon: <Picto color="var(--color-white)" width={20} icon="user-octagon" />,
            hideFor: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_LESSOR,
              ROLES.ROLE_SUPER_ADMIN,
            ],
            roles: [
              ROLES.ROLE_USER,
            ],
          },
        ],
      },
      management: {
        title: t(isUserAdmin ? 'menu.management.adminTitle' : 'menu.management.adminTitle'),
        displayFor: [
          ROLES.ROLE_ADMIN,
          ROLES.ROLE_SUPER_ADMIN,
          ROLES.ROLE_USER,
          ROLES.ROLE_LESSOR,
        ],
        links: [
          {
            label: t('menu.management.users'),
            to: '/user',
            match: ['/user'],
            icon: <Picto color="var(--color-white)" width={20} icon="user-octagon" />,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
            ],
          },
          {
            label: t(isUserAdmin ? 'menu.management.companyAdmin' : 'menu.management.company'),
            match: ['/company'],
            to: '/company',
            icon: <Picto color="var(--color-white)" width={20} icon="suitcase" />,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
            ],
          },
          {
            label: t('menu.management.owners'),
            to: '/owner',
            match: ['/owner'],
            icon: <Picto color="var(--color-white)" width={20} icon="key" />,
            roles: [
              ROLES.ROLE_LESSOR,
            ],
            onlyManagers: true,
          },
          {
            label: t('menu.management.property'),
            to: '/property',
            match: ['/property'],
            icon: <Picto color="var(--color-white)" width={20} icon="house-2" />,
            roles: [
              ROLES.ROLE_LESSOR,
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
            ],
          },
          {
            label: t('menu.management.residence'),
            to: '/residence',
            match: ['/residence'],
            icon: <Picto color="var(--color-white)" width={20} icon="building-4" />,
            roles: [
              ROLES.ROLE_LESSOR,
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
            ],
            beta: true,
          },
          {
            label: t('menu.management.tenants'),
            to: '/tenant',
            match: ['/tenant'],
            icon: <Picto color="var(--color-white)" width={20} icon="user" />,
            hideFor: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
            ],
            roles: [
              ROLES.ROLE_LESSOR,
            ],
          },
          {
            label: t('menu.management.leases'),
            to: '/lease',
            match: ['/lease'],
            icon: <Picto color="var(--color-white)" width={20} icon="edit-2" />,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
              ROLES.ROLE_USER,
            ],
          },
          {
            label: t('menu.management.payments'),
            to: '/payments',
            match: ['/payments'],
            icon: <Picto color="var(--color-white)" width={20} icon="receipt-2" />,
            hideFor: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_LESSOR,
              ROLES.ROLE_SUPER_ADMIN,
            ],
            roles: [
              ROLES.ROLE_USER,
            ],
          },
          {
            label: t('menu.management.housingBenefits'),
            to: '/housing-benefits',
            match: ['/housing-benefits'],
            icon: <Picto color="var(--color-white)" width={20} icon="wallet" />,
            roles: rentDistribution ? [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
            ] : [],
          },
          {
            label: t('menu.management.cashout'),
            to: '/cashout',
            match: ['/cashout'],
            icon: <Picto color="var(--color-white)" width={20} icon="receipt-2" />,
            hideFor: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
            ],
            roles: paymentpage
              ? [
                ROLES.ROLE_LESSOR,
              ]
              : [],
          },
          // {
          //   label: t('menu.management.candidates'),
          //   to: '/candidates',
          //   match: ['/candidates'],
          //   icon: <Picto color="var(--color-white)" width={20} icon="info-circle" />,
          //   roles: [
          //     ROLES.ROLE_ADMIN,
          //     ROLES.ROLE_SUPER_ADMIN,
          //     ROLES.ROLE_LESSOR,
          //   ],
          // },
          // {
          //   label: t('menu.management.residencies'),
          //   to: '/residencies',
          //   match: ['/residencies'],
          //   icon: <Picto color="var(--color-white)" width={20} icon="info-circle" />,
          //   roles: [
          //     ROLES.ROLE_ADMIN,
          //     ROLES.ROLE_SUPER_ADMIN,
          //     ROLES.ROLE_LESSOR,
          //   ],
          // },
        ],
      },
      flux: {
        title: t('menu.flux.title'),
        displayFor: [
          ROLES.ROLE_ADMIN,
          ROLES.ROLE_SUPER_ADMIN,
        ],
        links: [
          {
            label: t('menu.flux.paymentAccount'),
            to: '/payment-account',
            match: ['/payment-account'],
            icon: <Picto color="var(--color-white)" width={20} icon="wallet" />,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
            ],
          },
          {
            label: t('menu.flux.cashout'),
            isWip: true,
            to: '/cashout',
            match: ['/cashout'],
            icon: <Picto color="var(--color-white)" width={20} icon="receipt-2" />,
            roles: [
              ROLES.ROLE_SUPER_ADMIN,
            ],
          },
          {
            label: t('menu.flux.internalTransfer'),
            isWip: true,
            to: '/internal-transfer',
            match: ['/internal-transfer'],
            icon: <Picto color="var(--color-white)" width={20} icon="charge" />,
            roles: internalTransfer ? [
              ROLES.ROLE_SUPER_ADMIN,
            ] : [],
          },
        ],
      },
      tools: inventoryPage ? {
        title: t('menu.tools.title'),
        displayFor: [
          ROLES.ROLE_ADMIN,
          ROLES.ROLE_SUPER_ADMIN,
          ROLES.ROLE_LESSOR,
        ],
        links: [
          {
            label: t('menu.tools.inventory'),
            to: '/inventory',
            match: ['/inventory'],
            icon: <Picto color="var(--color-white)" width={20} icon="etat-lieux" />,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
            ],
            beta: true,
          },
        ],
      } : {},
      external: {
        title: null,
        displayFor: [
          ROLES.ROLE_ADMIN,
          ROLES.ROLE_SUPER_ADMIN,
          ROLES.ROLE_LESSOR,
          ROLES.ROLE_USER,
        ],
        links: [
          {
            label: t('menu.external.search'),
            isWip: true,
            to: '/rechercher',
            match: ['/rechercher'],
            icon: null,
            roles: [
              ROLES.ROLE_LESSOR,
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_USER,
            ],
          },
          {
            label: t('menu.external.gli'),
            external: true,
            to: 'https://unlocker.io/partenaire-cautioneo/',
            icon: null,
            roles: [
              ROLES.ROLE_LESSOR,
            ],
            hideForManagers: true,
            hideFor: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
            ],
          },
          {
            label: t('menu.external.gl'),
            external: true,
            to: 'https://unlocker.io/cautioneo-garantie-locataire/',
            icon: null,
            roles: [
              ROLES.ROLE_USER,
            ],
            hideFor: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
            ],
          },
          {
            label: t('menu.external.toolbox'),
            to: '/toolbox',
            match: ['/toolbox'],
            icon: null,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
            ],
          },
          {
            label: t('menu.external.blog'),
            external: true,
            to: 'https://unlocker.io/blog',
            icon: null,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
            ],
          },
          {
            label: t('menu.external.formations'),
            external: true,
            to: 'https://unlocker.io/notre-aide-en-video',
            icon: null,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
            ],
          },
          {
            label: t('menu.external.help'),
            external: true,
            to: 'https://unlocker.io/faq/',
            icon: null,
            roles: [
              ROLES.ROLE_ADMIN,
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_LESSOR,
            ],
          },
        ],
      },
    };
  }
  return {};
};
