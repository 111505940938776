import { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

// Components
import { Button, RadioGroup, utils } from 'ui-library-unlocker';
import Modal from '../../../molecules/Modal/Modal';

// Styles
import styles from './PropertyForm.module.scss';

function ImpactModal({
  id = 'impact-modal',
  onSubmit,
  onCancel,
  loading = false,
  canImpactLease = false,
  canImpactResidenceAddress = false,
  canImpactResidenceManager = false,
}) {
  const { t } = useTranslation();
  const [shouldImpactLease, setShouldImpactLease] = useState(false);
  const [shouldImpactResidenceAddress, setShouldImpactResidenceAddress] = useState(false);
  const [shouldImpactResidenceManager, setShouldImpactResidenceManager] = useState(false);

  const leaseOptions = useMemo(() => [
    {
      label: t('property.crud.impactLeaseModal.checkboxNoImpact'),
      value: false,
    },
    {
      label: t('property.crud.impactLeaseModal.checkboxImpact'),
      value: true,
    },
  ], [t]);

  const addressOptions = useMemo(() => [
    {
      label: t('property.crud.impactResidenceAddressModal.checkboxNoImpact'),
      value: false,
    },
    {
      label: t('property.crud.impactResidenceAddressModal.checkboxImpact'),
      value: true,
    },
  ], [t]);

  const managerOptions = useMemo(() => [
    {
      label: t('property.crud.impactResidenceManagerModal.checkboxNoImpact'),
      value: false,
    },
    {
      label: t('property.crud.impactResidenceManagerModal.checkboxImpact'),
      value: true,
    },
  ], [t]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      shouldImpactLease,
      shouldImpactResidenceAddress,
      shouldImpactResidenceManager,
    });
  }, [onSubmit, shouldImpactLease, shouldImpactResidenceAddress, shouldImpactResidenceManager]);

  return createPortal(
    <Modal
      id={id}
      onClose={onCancel}
      title={t('property.crud.impactLeaseModal.title')}
    >
      {canImpactLease && (
        <div className="m-b-40">
          <p>
            <Trans
              i18nKey="property.crud.impactLeaseModal.description"
            />
          </p>
          <RadioGroup
            name="shouldImpactLease"
            className={utils.cn([styles.radioGroupImpactLease, 'm-t-25'])}
            options={leaseOptions}
            value={shouldImpactLease}
            onChange={setShouldImpactLease}
          />
        </div>
      )}
      {canImpactResidenceAddress && (
        <div className="m-b-40">
          <p>
            <Trans
              i18nKey="property.crud.impactResidenceAddressModal.description"
            />
          </p>
          <RadioGroup
            name="shouldImpactResidenceAddress"
            className={utils.cn([styles.radioGroupImpactLease, 'm-t-25'])}
            options={addressOptions}
            value={shouldImpactResidenceAddress}
            onChange={setShouldImpactResidenceAddress}
          />
        </div>
      )}
      {canImpactResidenceManager && (
        <div className="m-b-40">
          <p>
            <Trans
              i18nKey="property.crud.impactResidenceManagerModal.description"
            />
          </p>
          <RadioGroup
            name="shouldImpactResidenceManager"
            className={utils.cn([styles.radioGroupImpactLease, 'm-t-25'])}
            options={managerOptions}
            value={shouldImpactResidenceManager}
            onChange={setShouldImpactResidenceManager}
          />
        </div>
      )}
      <div className={styles.impactLeaseModalButtonContainer}>
        <Button
          onClick={handleSubmit}
          size="large"
          label={t('global.confirmationModal.confirm')}
          loading={loading}
        />
        <Button
          onClick={onCancel}
          size="large"
          variant="secondary"
          label={t('global.confirmationModal.cancel')}
        />
      </div>
    </Modal>,
    document.querySelector('body'),
  );
}

ImpactModal.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  canImpactLease: PropTypes.bool,
  canImpactResidenceAddress: PropTypes.bool,
  canImpactResidenceManager: PropTypes.bool,
};

export default ImpactModal;
