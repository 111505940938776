import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Components
import {
  utils,
  Picto,
} from 'ui-library-unlocker';

// Utils
import useRoles from '../../../hooks/useRoles';

// Styles
import styles from './SettingsMenu.module.scss';

// Constants
import { routeList } from '../../../routes/routeList';

function SettingsMenu({
  className = '',
  isOpen = false,
  onMenuToggle = () => {},
}) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    isUserAdmin, isUserLessor, isUserRealEstateManager, isUserTenant,
  } = useRoles();

  const killswitches = useFlags();

  const checkIsVisible = useCallback((route) => {
    if (!route.routeProps?.path) return false;
    if (route.killswitch && !killswitches[route.killswitch]) return false;
    if (
      !isUserAdmin
      && (route.hideForLessor && isUserLessor)
      && !(route.showForManager && isUserRealEstateManager)
    ) {
      return false;
    }
    if (route.hideForTenant && isUserTenant) return false;
    return true;
  }, [killswitches]);

  const listItems = useMemo(() => (
    routeList
      .find((route) => route.label === 'settings')
      ?.nestedRoutes
      ?.filter((route) => checkIsVisible(route))
      ?.map((route) => ({
        label: route.label,
        icon: route.icon,
        to: route.routeProps.path,
      })) || []
  ), [killswitches]);

  const getIsActive = useCallback((item) => {
    const pathLast = pathname.split('/').pop();
    return pathLast === item.to.replace('/', '');
  }, [pathname]);

  const renderMenu = useCallback(() => listItems
    .map((item) => {
      const isActive = getIsActive(item);
      return (
        <li key={item.label}>
          <Link
            to={`/settings/${item.to || ''}`}
            className={isActive ? 'active' : null}
            onClick={onMenuToggle}
          >
            <div>
              <Picto
                icon={item.icon}
                width={18}
                color="var(--color-primary-500)"
                className={styles.listItemIcon}
              />
            </div>
            <p className={styles.listItemLabel}>
              <span className={styles.listItemLabelTitle}>
                {t(`settings.menu.${item.label}.title`)}
              </span>
              <span>
                {t(`settings.menu.${item.label}.description`)}
              </span>
            </p>
          </Link>
        </li>
      );
    }), [listItems, t, getIsActive]);

  return (
    <nav className={utils.cn([
      styles.container,
      className,
      isOpen ? styles.open : null,
    ])}
    >
      <ul>
        {renderMenu()}
      </ul>
    </nav>
  );
}

SettingsMenu.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onMenuToggle: PropTypes.func,
};

export default SettingsMenu;
