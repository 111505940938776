import * as yup from 'yup';
import addressSchema from './addressSchema';

export const residenceInitialValues = {
  name: '',
  type: '',
  address: null,
  constructionYear: null, // int
  propertiesCount: null, // int
  deliveryPoint: '', // string
  taxNumber: null, // string
  cadastralRef: null, // string
  managerUid: '', // string
  managerName: '', // string
  ownerUid: '', // string
  ownerName: '', // string
  hasCollectiveElectricity: false,
  hasCollectiveHeating: false,
  properties: [],
};

const residenceSchema = yup.object().shape({
  name: yup.string().required('global.form.errors.required'),
  type: yup.string().required('global.form.errors.required'),
  address: addressSchema.nullable(),
  constructionYear: yup
    .number()
    .test(
      'constructionYear-regex',
      'residence.crud.formErrors.constructionYear',
      (value) => {
        if (value === null) return true;
        return (/^\d{4}$/.test(value));
      },
    )
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .nullable(),
  propertiesCount: yup
    .number()
    .integer('global.form.errors.integer')
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .required('global.form.errors.required'),
  deliveryPoint: yup
    .string()
    .test({
      name: 'deliveryPoint',
      message: 'residence.crud.formErrors.deliveryPoint',
      test: function test(value) {
        if (!value) return true;
        const { type } = this.parent;
        const isWhitelisted = ['garage', 'box', 'parking'].includes(type); // to be updated
        if (isWhitelisted) {
          return true;
        }
        return (/^\d{14}$/.test(value));
      },
    }),
  taxNumber: yup.string()
    .test({
      name: 'taxNumber',
      message: 'residence.crud.formErrors.taxNumber',
      test: function test(value) {
        if (!value) return true;
        return (/^\d{10}$/.test(value));
      },
    })
    .nullable(),
  cadastralRef: yup.string().nullable(),
  ownerUid: yup.string().required('global.form.errors.required'),
  ownerName: yup.string().nullable(),
  managerUid: yup.string().required('global.form.errors.required'),
  managerName: yup.string().nullable(),
  hasCollectiveElectricity: yup.boolean().required('global.form.errors.required'),
  hasCollectiveHeating: yup.boolean().required('global.form.errors.required'),
  properties: yup.array().of(
    yup.lazy((value) => {
      if (typeof value === 'string') {
        return yup.string();
      }
      return yup.object().shape({
        uid: yup.string().required('global.form.errors.required'),
      });
    }),
  ).nullable(),
});

export const residenceCharacteristicsInitialValues = {
  equipments: null,
  heatings: null,
  gesScore: null,
  dpeScore: null,
  referenceYear: null,
};

export const residenceCharacteristicsSchema = yup.object().shape({
  equipments: yup.array().of(yup.string()).required('global.form.errors.required').nullable(),
  heatings: yup.array().of(yup.string()).required('global.form.errors.required').nullable(),
  gesScore: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .nullable(),
  dpeScore: yup
    .number()
    .typeError('global.form.errors.number')
    .moreThan(-1, 'global.form.errors.negativeNumber')
    .nullable(),
  referenceYear: yup.number().nullable(),
});

export const propertyToResidenceInitialValues = {
  properties: [],
};

export const propertyToResidenceSchema = yup.object().shape({
  properties: yup.array()
    .of(yup.object().shape({
      value: yup.string().required('global.form.errors.required'),
      label: yup.string().required('global.form.errors.required'),
    }))
    .required('global.form.errors.required')
    .min(1, 'global.form.errors.required'),
});

export default residenceSchema;
