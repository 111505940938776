import {
  useCallback, useMemo, useState, useRef, useEffect,
} from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { formatDistanceToNowStrict } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Picto,
  Button,
  utils,
  UnlockerLoader,
  Tag,
} from 'ui-library-unlocker';
import InventoryMenu from './InventoryMenu/InventoryMenu';
import InventorySaveModal from './InventorySaveModal/InventorySaveModal';
import ErrorView from '../500/500';

// Services
import {
  createInventory,
  updateInventory,
  getInventory,
  sendInventoryToSign,
} from '../../services/inventory';
import { getLeases } from '../../services/lease';
import { getProperties } from '../../services/property';

// Utils
import { inventoryInitialValues, validationSchema } from '../../utils/forms/inventorySchema';
import { showModal, hideModal } from '../../utils/modal';
import { handleDocumentDownload } from '../../utils/documents';
import { getInventoryStatusVariant } from '../../utils/variants';

// Hooks
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useInterval from '../../hooks/useInterval';
import useResponsive from '../../hooks/useResponsive';
import useRoles from '../../hooks/useRoles';
import { useAppContext } from '../../store/context';

// Constants
import { LEASE_STATUS, INVENTORY_STATUS_LIST } from '../../utils/constants';

// Styles
import styles from './InventoryEdit.module.scss';

const SAVE_MODAL_ID = 'inventorySaveModal';

function InventoryEdit() {
  const { t } = useTranslation();
  const { id } = useParams();
  const contentRef = useRef(null);
  const { isMobile } = useResponsive();
  const { isUserAdmin } = useRoles();
  const { context: { user, me } } = useAppContext();
  const navigate = useNavigate();

  useDocumentTitle(t('inventory.addBrowserTitle'));

  // Auto save
  const [savedTime, setSavedTime] = useState(null);
  const getTimeAgo = useCallback((timeAgo) => formatDistanceToNowStrict(new Date(timeAgo), {
    locale: fr,
    addSuffix: true,
  }), []);
  const [savedAgo, setSavedAgo] = useState(null);
  useInterval(() => (savedTime ? setSavedAgo(getTimeAgo(savedTime)) : null), 10000, [getTimeAgo, savedTime]);
  // ----------------------------

  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [leaseQuery, setLeaseQuery] = useState('');
  const [propertyQuery, setPropertyQuery] = useState('');

  const {
    data: inventoryData,
    isFetching: inventoryFetching,
    refetch: refetchInventory,
  } = useQuery({
    queryKey: ['inventory-details'],
    queryFn: () => (id !== 'add' ? getInventory(id) : null),
    keepPreviousData: false,
  });

  const isReadOnly = useMemo(() => !!(
    inventoryData?.data?.status
    && inventoryData.data.status !== INVENTORY_STATUS_LIST.DRAFT
  ), [inventoryData]);

  const initialValues = useMemo(() => {
    if (inventoryData?.data) {
      if (inventoryData.data.status === INVENTORY_STATUS_LIST.DRAFT) {
        const draftData = JSON.parse(inventoryData.data.draft) || {};
        return {
          ...draftData,
          uid: inventoryData.data.uid,
          property: inventoryData.data.property,
          propertyUid: draftData?.propertyUid || inventoryData.data.propertyUid,
          date: draftData?.date || inventoryData.data.date,
          isDraft: true,
        };
      }
      return {
        ...inventoryData.data,
        isDraft: false,
      };
    }
    return inventoryInitialValues;
  }, [inventoryData, inventoryInitialValues]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      sendInventoryToSignMutation.mutate({
        ...values,
      });
    },
  });

  const [collapsedFurniture, setCollapsedFurniture] = useState(formik.values.rooms?.map(() => false) || []);

  const {
    data: propertiesListData,
    isFetching: propertiesListFetching,
  } = useQuery({
    queryKey: ['properties-list-select', propertyQuery],
    queryFn: () => getProperties({
      filters: {
        search: [propertyQuery],
      },
    }),
    keepPreviousData: false,
  });

  const userManagementEntities = useMemo(() => [
    user?.username,
    ...(
      me?.aclMatrix?.companies
        ?.filter((company) => company.owner || (!company.owner && !company.isRealEstateAgency))
        ?.map((company) => company.uid)
      || []
    ),
  ], [user, me]);

  const {
    data: leasesListData,
    isFetching: leasesListFetching,
  } = useQuery({
    queryKey: ['leases-list-select', leaseQuery, formik.values.propertyUid, userManagementEntities, isUserAdmin],
    queryFn: () => getLeases({
      filters: {
        status: [
          LEASE_STATUS.SIGNED,
          LEASE_STATUS.CREATED,
          LEASE_STATUS.TERMINATION_SCHEDULED,
          LEASE_STATUS.TERMINATED,
        ],
        propertyUid: [formik.values.propertyUid],
        managerUid: isUserAdmin ? undefined : userManagementEntities,
        search: [leaseQuery],
      },
    }),
    keepPreviousData: false,
    enabled: !!formik.values.propertyUid,
  });

  const updateSavedTime = useCallback((date) => {
    setSavedTime(date);
    setSavedAgo(getTimeAgo(date));
  }, [getTimeAgo]);

  const updateInventoryMutation = useMutation({
    mutationFn: (data) => updateInventory(id !== 'add' ? id : null, data),
    onSuccess: () => {
      updateSavedTime(new Date());
    },
  });

  const createInventoryMutation = useMutation({
    mutationFn: (data) => createInventory(data?.propertyUid, data),
    onSuccess: ({ data }) => {
      formik.setFieldValue('uid', data?.uid);
      navigate(`/inventory/${data?.uid}`);
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
  });

  const sendInventoryToSignMutation = useMutation({
    mutationFn: (data) => sendInventoryToSign(formik.values.uid, data),
    onSuccess: () => {
      hideModal(SAVE_MODAL_ID);
      utils.toast.success(t('inventory.save.sign.success'));
      refetchInventory();
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.generic'));
    },
  });

  const handleSave = useCallback(() => {
    showModal(SAVE_MODAL_ID);
  }, []);

  const onMenuToggle = useCallback(() => {
    setSideMenuOpen((prev) => !prev);
  }, []);

  const renderMainButton = useCallback(() => {
    if (!isReadOnly) {
      return (
        <Button
          variant="primary"
          onClick={handleSave}
          label={t('global.save')}
          size="large"
          icon="save"
          iconSide="left"
          disabled={!formik.values.propertyUid || id === 'add'}
        />
      );
    }
    if (formik.values.documentUid) {
      return (
        <Button
          variant="primary"
          onClick={() => handleDocumentDownload({ uid: formik.values.documentUid }, t)}
          label={t('global.listOptions.download')}
          size="large"
          icon="import"
          iconSide="left"
        />
      );
    }
    const signerUser = formik.values.signers?.find((signer) => signer?.uid === user?.username);
    const shouldSign = signerUser?.status === 'pending' && signerUser?.link;
    if (shouldSign) {
      return (
        <Button
          variant="primary"
          onClick={() => window.open(signerUser.link, '_blank')}
          label={t('global.listOptions.sign')}
          size="large"
          icon="sign"
          iconSide="left"
        />
      );
    }
    return null;
  }, [isReadOnly, handleSave, t, formik.values, user, createInventoryMutation]);

  const status = useMemo(() => {
    const inventoryStatus = inventoryData?.data?.status || INVENTORY_STATUS_LIST.DRAFT;
    return { value: inventoryStatus, variant: getInventoryStatusVariant(inventoryStatus) };
  }, [inventoryData?.data?.status]);

  useEffect(() => {
    if (id !== 'add') {
      formik.setFieldValue('uid', id);
    }
  }, [id]);

  if (createInventoryMutation.isError) return <ErrorView />;

  return (
    <div
      id="inventory-edit"
      className={utils.cn([styles.inventoryEdit, 'inventory-edit'])}
    >
      <div className={styles.heading}>
        <h1 className={styles.title}>
          {isMobile && (
            <Picto
              onClick={onMenuToggle}
              className={sideMenuOpen ? 'm-r-15' : 'm-r-10'}
              width={sideMenuOpen ? 18 : 24}
              color="var(--color-black)"
              icon={sideMenuOpen ? 'close' : 'menu'}
            />
          )}
          <span>
            {t(`inventory.${isReadOnly ? 'readOnlyTitle' : 'addTitle'}`)}
          </span>
          {!isMobile && !inventoryFetching && (
            <Tag
              variant={status?.variant}
              className={utils.cn([styles.statusTag, 'm-l-15'])}
              size="medium"
              label={t(`inventory.status.${status?.value}`)}
            />
          )}
        </h1>
        <div className={styles.saveCol}>
          {savedAgo && (
            <div className={styles.draftSaved}>
              <Picto
                icon="tick-circle"
                color="var(--color-accent-green"
                width={20}
                className={styles.draftSavedIcon}
              />
              <span className={styles.draftSavedText}>
                {t('inventory.draftSaved', { ago: savedAgo })}
              </span>
            </div>
          )}
          <div className={styles.saveBtn}>
            {renderMainButton()}
          </div>
        </div>
      </div>
      <InventoryMenu
        className={utils.cn([
          styles.menu,
          sideMenuOpen && styles.menuOpen,
        ])}
        propertyUid={formik.values.propertyUid}
        rooms={formik.values.rooms}
        close={onMenuToggle}
      />
      <div
        id="inventoryContent"
        className={styles.content}
        ref={contentRef}
      >
        {inventoryFetching ? (
          <UnlockerLoader size={200} align={isMobile ? 'center' : 'left'} />
        ) : (
          <Outlet
            context={{
              isReadOnly,
              formik,
              contentRef,
              leasesListData,
              leasesListFetching,
              setLeaseQuery,
              propertiesListData,
              propertiesListFetching,
              setPropertyQuery,
              createInventoryMutation,
              updateInventoryMutation,
              collapsedFurniture,
              setCollapsedFurniture,
              status,
            }}
          />
        )}
      </div>
      <InventorySaveModal
        idModal={SAVE_MODAL_ID}
        formik={formik}
        updateSavedTime={updateSavedTime}
        isSending={sendInventoryToSignMutation.isLoading}
      />
    </div>
  );
}

export default InventoryEdit;
