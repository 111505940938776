import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

// Components
import {
  ToggleInput,
  Button,
} from 'ui-library-unlocker';

// Styles
import styles from './SettingsNotifications.module.scss';

function SettingsNotifications() {
  const { t } = useTranslation();

  const { formik } = useOutletContext();

  return (
    <div>
      <h1>{t('settings.notifications.title')}</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="m-t-25">
          <p>{t('settings.notifications.enabledNotifications')}</p>
          <ToggleInput
            id="isInAppNotificationEnabled"
            name="isInAppNotificationEnabled"
            className="m-t-10 m-l-10"
            label={t('settings.notifications.form.isInAppNotificationEnabled')}
            checked={!!formik.values.notification?.isInAppNotificationEnabled}
            onChange={(check) => formik.setFieldValue('notification.isInAppNotificationEnabled', check)}
            disabled
          />
          <ToggleInput
            id="isEmailNotificationEnabled"
            name="isEmailNotificationEnabled"
            className="m-t-10 m-l-10"
            label={(
              <>
                <span>{t('settings.notifications.form.isEmailNotificationEnabled')}</span>
                <small className={styles.toggleInfo}>
                  {t('settings.notifications.form.isEmailNotificationEnabledInfo')}
                </small>
              </>
)}
            checked={!!formik.values.notification?.isEmailNotificationEnabled}
            onChange={(check) => formik.setFieldValue('notification.isEmailNotificationEnabled', check)}
          />
          {/* <ToggleInput
            id="isSmsNotificationEnabled"
            name="isSmsNotificationEnabled"
            className="m-t-10 m-l-10"
            label={t('settings.notifications.form.isSmsNotificationEnabled')}
            checked={!!formik.values.notification?.isSmsNotificationEnabled}
            onChange={(check) => formik.setFieldValue('notification.isSmsNotificationEnabled', check)}
          /> */}
        </div>
        <Button
          type="submit"
          className="m-t-35"
          size="large"
          label={t('global.save')}
          loading={formik.isSubmitting}
        />
      </form>
    </div>
  );
}

export default SettingsNotifications;
