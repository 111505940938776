/* eslint-disable max-len */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

// Components
import LeaseForm from '../../components/organisms/forms/LeaseForm/LeaseForm';

import useRoles from '../../hooks/useRoles';

import styles from './LeaseList.module.scss';

function LeaseAdd() {
  const { t } = useTranslation();
  const { isUserLessor } = useRoles();

  useEffect(() => {
    document.title = t('lease.add.browserTitle');
  }, [t]);

  if (!isUserLessor) return <Navigate to="/" />;

  return (
    <div>
      <div className={styles.heading}>
        <h1>{t('lease.title')}</h1>
      </div>
      <LeaseForm isEditing={false} />
    </div>
  );
}

export default LeaseAdd;
