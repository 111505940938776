import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

// Components
import {
  UnlockerLoader,
  Message,
  Button,
  utils,
} from 'ui-library-unlocker';
import ErrorView from '../../500/500';
import SettingsLeaseDetails from '../../Settings/SettingsPayments/SettingsLeaseDetails';

// Services
import { updateLeaseSettings } from '../../../services/lease';

// Utils
import { leaseDetailsSettingsValidationSchema } from '../../../utils/forms/settingsSchema';

function LeaseSettings({
  leaseQuery,
}) {
  const { t } = useTranslation();

  const {
    data: leaseData,
    isFetching: isLeaseFetching,
    isError: isLeaseError,
  } = leaseQuery || {};

  const updateLeaseSettingsMutation = useMutation({
    mutationFn: (data) => updateLeaseSettings(leaseData?.data?.uid, data),
    onSuccess: () => {
      utils.toast.success(t('settings.success'));
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.global'));
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
  });

  const initialValues = useMemo(() => ({
    settings: leaseData?.data?.settings || {},
  }), [leaseData]);

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      settings: leaseDetailsSettingsValidationSchema,
    }),
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateLeaseSettingsMutation.mutate({
        ...(values.settings || {}),
      });
    },
  });

  if (isLeaseFetching) return <UnlockerLoader size={200} align="left" />;

  if (isLeaseError || !leaseData?.data?.settings) return <ErrorView />;

  return (
    <form className="m-t-35" onSubmit={formik.handleSubmit}>
      <Message
        variant="info"
        content={t('lease.tabs.settings.info')}
      />
      <SettingsLeaseDetails
        formik={formik}
        initialValues={initialValues}
        parentKey="settings"
      />
      <Button
        type="submit"
        className="m-t-35"
        size="large"
        label={t('global.save')}
        loading={formik.isSubmitting}
      />
    </form>
  );
}

LeaseSettings.propTypes = {
  leaseQuery: PropTypes.shape().isRequired,
};

export default LeaseSettings;
