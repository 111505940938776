import React, {
  useState, useMemo, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Picto,
  Button,
  utils,
} from 'ui-library-unlocker';
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../components/organisms/DataTable/DataTable';
import DeleteResidenceModal from '../../components/organisms/forms/ResidenceForm/DeleteResidenceModal';

// Services
import { getResidences, deleteResidence, deleteResidenceAndProperties } from '../../services/residence';

// Hooks
import useFilters from '../../hooks/useFilters';
import { useAppContext } from '../../store/context';
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Utils
import { formatAddress } from '../../utils/properties';
import { showModal, hideModal } from '../../utils/modal';
import { formatMoney } from '../../utils/html';

// Styles
import styles from './ResidenceList.module.scss';

// Constants
import { SEARCH_SCOPE_LIST } from '../../utils/constants';

const DELETE_CONFIRM_MODAL_ID = 'identity-delete-confirm';

const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.PROPERTIES]: 'properties',
  [SEARCH_SCOPE_LIST.RESIDENTIAL_COMPLEXES]: 'uid',
};

function ResidenceList() {
  const { t } = useTranslation();
  const { context: { uiBuilders }, dispatch } = useAppContext();
  const navigate = useNavigate();
  const { filters } = useFilters();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  useDocumentTitle(t('residence.browserTitle'));

  const residenceBuilders = useMemo(() => (uiBuilders ? uiBuilders['/property/ui'] : null), [uiBuilders]);

  const typeOptions = useMemo(() => {
    const { residentialComplexTypes } = residenceBuilders || {};
    if (!residentialComplexTypes) return [];
    return Object.entries(residentialComplexTypes).map(([type, label]) => ({
      value: type,
      label,
    }));
  }, [residenceBuilders]);

  const resetSelected = useCallback((modalId) => {
    setSelectedItem(null);
    hideModal(modalId);
  }, []);

  const deleteResidenceMutation = useMutation({
    mutationFn: (shouldDeleteProperties) => (
      shouldDeleteProperties ? deleteResidenceAndProperties : deleteResidence
    )(selectedItem?.uid),
    onSuccess: () => {
      utils.toast.success(t('residence.crud.deleteSuccess'));
      dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'residence-list' });
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
    onSettled: () => {
      resetSelected(DELETE_CONFIRM_MODAL_ID);
    },
  });

  const columns = useMemo(() => ([
    {
      header: t('residence.table.columns.createdAt'),
      accessorKey: 'createdAt',
      size: 100,
      cell: ({ row: { original: { createdAt } } }) => (
        <div>
          <span>{createdAt ? format(new Date(createdAt), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('residence.table.columns.unlockerId'),
      accessorKey: 'unlockerId',
      size: 125,
    },
    {
      header: t('residence.table.columns.name'),
      accessorKey: 'name',
      size: 150,
    },
    {
      header: t('residence.table.columns.address'),
      accessorKey: 'address',
      minSize: 200,
      cell: ({ row }) => {
        if (!row?.original?.address) return '-';
        const {
          streetNumber, street, zipCode, city,
        } = row.original.address;
        return (
          <div className={styles.nameAddress}>
            {formatAddress({
              streetNumber, street, zipCode, city,
            })}
          </div>
        );
      },
    },
    {
      header: t('residence.table.columns.type'),
      accessorKey: 'type',
      size: 150,
      enableSorting: false,
      cell: ({ row: { original: { type } } }) => (
        <div>
          {typeOptions.find((opt) => opt.value === type)?.label}
        </div>
      ),
    },
    {
      header: t('residence.table.columns.theoricalCumulatedRent'),
      accessorKey: 'theoricalCumulatedRent',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { theoricalCumulatedRent } } }) => (
        <div>
          {formatMoney(utils.centsToEuro(theoricalCumulatedRent))}
        </div>
      ),
    },
    {
      header: t('residence.table.columns.balance'),
      accessorKey: 'balance',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { balance } } }) => (
        <div className={utils.cn([
          styles.price,
          balance < 0 || typeof balance !== 'number' ? styles.negative : null,
        ])}
        >
          {formatMoney(utils.centsToEuro(balance))}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-residence-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              if (tooltipOpen === row?.original?.uid) setTooltipOpen(null);
              else setTooltipOpen(row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-residence-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('global.listOptions.manage'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/residence/${row?.original?.uid}`);
                  },
                },
                {
                  id: 'delete',
                  label: t('global.listOptions.delete'),
                  icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
                  onClick: () => {
                    setSelectedItem(row?.original);
                    showModal(DELETE_CONFIRM_MODAL_ID);
                  },
                },
              ]}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen, navigate, typeOptions]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'type',
      type: 'checkbox',
      title: t('residence.table.columns.type'),
      options: typeOptions,
    },
    {
      name: 'rent',
      multiName: ['rentPriceMin', 'rentPriceMax'],
      type: 'price',
      title: t('residence.table.columns.theoricalCumulatedRent'),
      options: [
        {
          value: filters?.rentPriceMin ? filters?.rentPriceMin[0] : null,
          label: `${t('residence.filter.min-price')} (${filters?.rentPriceMin ? filters?.rentPriceMin[0] : null}€)`,
        },
        {
          value: filters?.rentPriceMax ? filters?.rentPriceMax[0] : null,
          label: `${t('residence.filter.max-price')} (${filters?.rentPriceMax ? filters?.rentPriceMax[0] : null}€)`,
        },
      ],
    },
    {
      name: 'createdAt',
      multiName: ['createdAtDate', 'createdAtStartDate', 'createdAtEndDate'],
      type: 'date',
      title: t('residence.table.columns.createdAt'),
      options: [
        {
          value: filters?.createdAtDate?.[0],
          label: `${t('global.filterDate.exactSelectedFilterLabel')} ${filters?.createdAtDate?.[0]
            ? format(new Date(filters?.createdAtDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.createdAtStartDate?.[0],
          label: `${t('global.filterDate.after')} ${filters?.createdAtStartDate?.[0]
            ? format(new Date(filters?.createdAtStartDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.createdAtEndDate?.[0],
          label: `${t('global.filterDate.before')} ${filters?.createdAtEndDate?.[0]
            ? format(new Date(filters?.createdAtEndDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
      ],
    },
  ], [t, filters]);

  const headerBtn = useMemo(() => (
    <Link to="/residence/add" className={styles.headerBtn}>
      <Button
        variant="primary"
        size="large"
        icon="plus"
      >
        {t('residence.add')}
      </Button>
    </Link>
  ), [t]);

  const handleCancelDelete = useCallback(() => {
    resetSelected(DELETE_CONFIRM_MODAL_ID);
  }, []);

  return (
    <div
      onClick={() => setTooltipOpen(null)}
      onKeyDown={() => setTooltipOpen(null)}
      role="presentation"
    >
      <div className={styles.heading}>
        <h1>{t('residence.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('residence.search')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={getResidences}
        queryKey="residence-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={headerBtn}
      />
      <DeleteResidenceModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={deleteResidenceMutation.mutate}
        onCancel={handleCancelDelete}
        name={selectedItem?.name}
        loading={deleteResidenceMutation.isLoading}
      />
    </div>
  );
}

export default ResidenceList;
