import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import {
  Message,
  utils,
} from 'ui-library-unlocker';

// Hooks
import { useAppContext } from '../../../store/context';

// Styles
import styles from './MissingDocuments.module.scss';

function MissingDocuments({
  missingDocuments = [],
  type = 'PPH',
}) {
  const { t } = useTranslation();
  const { context: { uiBuilders } } = useAppContext();

  const documentTypes = useMemo(() => {
    if (!uiBuilders) return {};
    return uiBuilders['/person/ui']?.[`documentType${type}`] || {};
  }, [uiBuilders]);

  if (!missingDocuments?.length) return null;

  return (
    <Message
      className={utils.cn(['m-t-30', styles.missingBlock])}
      icon={null}
      variant="alert"
      content={(
        <>
          <p className="p-2-700 m-b-10">
            {t('profile.documents.missingDocuments')}
          </p>
          <ul className={styles.missingList}>
            {missingDocuments.map((item) => (
              <li key={item}>
                {documentTypes[item.toLowerCase()]}
              </li>
            ))}
          </ul>
        </>
      )}
    />
  );
}

MissingDocuments.propTypes = {
  missingDocuments: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
};

export default MissingDocuments;
