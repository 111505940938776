/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Components
import {
  Picto, Tag, Button, utils,
} from 'ui-library-unlocker';
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import ConfirmationModal from '../../components/organisms/ConfirmationModal/ConfirmationModal';
import ExportModal from '../../components/molecules/ExportModal/ExportModal';
import DataTable from '../../components/organisms/DataTable/DataTable';

// Utils
import { showModal, hideModal } from '../../utils/modal';
import { useAppContext } from '../../store/context';

// Services
import { deleteCompany, getCompanies } from '../../services/company';
import { getCompaniesFromAdmin } from '../../services/admin';

// Hooks
import useRoles from '../../hooks/useRoles';
import useDocumentTitle from '../../hooks/useDocumentTitle';

// Styles
import styles from './CompanyList.module.scss';

// Constants
import { ENROLMENT_STATUS, SEARCH_SCOPE_LIST } from '../../utils/constants';

const {
  PENDING,
  PROFILE_DONE,
  ENROLMENT_REQUESTED,
  ENROLMENT_PENDING,
  ENROLMENT_CHALLENGED,
  ENROLMENT_DENIED,
  ENROLMENT_BYPASSED,
  COMPLETED,
  BLOCKED,
} = ENROLMENT_STATUS;
const DELETE_CONFIRM_MODAL_ID = 'company-delete-confirm';
const EXPORT_MODAL_ID = 'company-export-modal';
const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.COMPANIES]: 'uid',
  [SEARCH_SCOPE_LIST.PROPERTY_OWNERS]: 'owner',
  [SEARCH_SCOPE_LIST.MANAGERS]: 'owner',
};

function CompanyListView() {
  const { t } = useTranslation();
  const { dispatch, refetchMe } = useAppContext();
  const { isUserAdmin } = useRoles();
  const {
    exportOwner,
  } = useFlags();
  const navigate = useNavigate();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [companytoDelete, setCompanyToDelete] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useDocumentTitle(t('company.browserTitle'));

  const formatData = useCallback((data) => {
    if (!data) return data;
    const companies = [...data];
    return companies.sort((a, b) => {
      if (a.isRealEstateAgency && !b.isRealEstateAgency) return -1;
      if (!a.isRealEstateAgency && b.isRealEstateAgency) return 1;
      return 0;
    });
  }, []);

  const deleteCompanyMutation = useMutation({
    mutationFn: () => deleteCompany(companytoDelete?.uid),
    onSuccess: () => {
      utils.toast.success(t('company.crud.deleteSuccess'));
      dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'company-list' });
      refetchMe();
      cancelDelete();
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 403: {
            utils.toast.error(t('company.crud.formErrors.PERSON_COMPANY_DELETION_FORBIDDEN'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
      cancelDelete();
    },
  });

  const statusVariant = useMemo(() => (status) => {
    if (!status) return null;
    switch (status) {
      case PENDING:
      case ENROLMENT_REQUESTED:
      case ENROLMENT_PENDING:
      case ENROLMENT_CHALLENGED:
      case ENROLMENT_DENIED:
        return 'tertiary';

      case PROFILE_DONE:
        return 'primary';

      case BLOCKED:
        return 'error';

      case ENROLMENT_BYPASSED:
      case COMPLETED:
        return 'success';

      default:
        return 'tertiary';
    }
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('company.table.columns.unlockerID'),
      accessorKey: 'unlockerId',
      size: 125,
    },
    {
      header: t('company.table.columns.legalName'),
      accessorKey: 'legalName',
      size: 200,
    },
    {
      header: t('company.table.columns.owner'),
      accessorKey: 'ownerDetails.fullName',
      size: 200,
      enableSorting: false,
    },
    {
      header: t('company.table.columns.companyType.title'),
      accessorKey: 'isRealEstateAgency',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { isRealEstateAgency } } }) => (
        <div>
          <Tag
            label={t(`company.table.columns.companyType.${isRealEstateAgency ? 'realEstate' : 'normal'}`)}
            size="medium"
            variant={isRealEstateAgency ? 'secondary' : 'secondary-outline'}
          />
        </div>
      ),
    },
    {
      header: t('company.table.columns.delegation.title'),
      accessorKey: 'isDelegated',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { isDelegated } } }) => (
        <div>
          <Tag
            label={
              isUserAdmin
                ? t('company.table.columns.delegation.admin')
                : t(`company.table.columns.delegation.${isDelegated ? 'delegated' : 'normal'}`)
            }
            size="medium"
            variant={isDelegated ? 'tertiary' : 'secondary'}
          />
        </div>
      ),
    },
    {
      header: t('company.table.columns.onboardingStatus'),
      accessorKey: 'onboardingStatus',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { onboardingStatus } } }) => (
        <div>
          <Tag
            label={t(`global.companyStatus.${onboardingStatus}`)}
            size="medium"
            variant={statusVariant(onboardingStatus)}
          />
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 50,
      enableSorting: false,
      cell: ({ row }) => {
        const options = [
          {
            id: 'edit',
            label: t('global.listOptions.manage'),
            icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
            onClick: (e) => {
              e.stopPropagation();
              setTooltipOpen(null);
              navigate(`/company/${row?.original?.uid}`);
            },
          },
          exportOwner ? {
            id: 'export',
            label: t('company.table.listOptions.export'),
            icon: <Picto icon="export-link" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="export-link" width={24} color="var(--color-white)" />,
            onClick: (e) => {
              e.stopPropagation();
              setTooltipOpen(null);
              setSelectedCompany(row?.original);
              showModal(EXPORT_MODAL_ID);
            },
          } : null,
          {
            id: 'delete',
            label: t('global.listOptions.delete'),
            icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
            onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
            onClick: () => {
              setCompanyToDelete({
                uid: row?.original?.uid,
                isRealEstateAgency: row?.original?.isRealEstateAgency,
              });
              showModal(DELETE_CONFIRM_MODAL_ID);
            },
          },
        ];
        return options?.filter((elem) => elem != null).length === 0 ? null : (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-company-${row?.original?.uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === row?.original?.uid}
              anchorId={`more-option-company-${row?.original?.uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={options}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [t, tooltipOpen, exportOwner, navigate, statusVariant]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'onboardingStatus',
      type: 'checkbox',
      title: t('company.table.columns.onboardingStatus'),
      options: [
        {
          value: PROFILE_DONE,
          label: t('global.companyStatus.profile_done'),
        },
        {
          value: `${ENROLMENT_PENDING},${ENROLMENT_REQUESTED},${ENROLMENT_CHALLENGED},${ENROLMENT_DENIED}`,
          label: t('global.companyStatus.enrolment_pending'),
        },
        {
          value: `${COMPLETED},${ENROLMENT_BYPASSED}`,
          label: t('global.companyStatus.completed'),
        },
        {
          value: BLOCKED,
          label: t('global.companyStatus.blocked'),
        },
      ],
    },
  ], []);

  const entityToExport = useMemo(() => {
    if (selectedCompany) {
      return {
        uid: selectedCompany.uid,
        name: selectedCompany.legalName,
        type: 'company',
        isRealEstateAgency: selectedCompany.isRealEstateAgency,
      };
    }
    return null;
  }, [selectedCompany]);

  const cancelDelete = useCallback(() => {
    setCompanyToDelete(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleExportClose = useCallback(() => {
    setSelectedCompany(null);
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={null}
      onClick={() => setTooltipOpen(null)}
    >
      <div className={styles.heading}>
        <h1>{t('company.title')}</h1>
      </div>
      <DataTable
        columns={columns}
        searchLabel={t('company.search')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={isUserAdmin ? getCompaniesFromAdmin : getCompanies}
        queryKey="company-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={(
          <Link to="/company/add">
            <Button
              variant="primary"
              size="large"
              icon="plus"
            >
              {t('company.add')}
            </Button>
          </Link>
        )}
        formatData={formatData}
      />
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={deleteCompanyMutation.mutate}
        onCancel={cancelDelete}
      />
      <ExportModal
        idModal={EXPORT_MODAL_ID}
        entity={entityToExport}
        onClose={handleExportClose}
      />
    </div>
  );
}

export default CompanyListView;
