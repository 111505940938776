/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch } from 'react-router-dom';
import { debounce } from 'lodash';
import { Buffer } from 'buffer';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Component
import {
  utils,
  TextInput,
  SelectInput,
  DateInput,
  ToggleInput,
  RadioGroup,
  WysiwygInput,
  Button,
  Picto,
  Table,
  Message,
} from 'ui-library-unlocker';
import LeasePreview from './LeasePreview';
import Modal from '../../../molecules/Modal/Modal';
import FormInfoRequired from '../../../atoms/FormInfoRequired/FormInfoRequired';
import Tooltip from '../../../atoms/Tooltip/Tooltip';
import TableOptions from '../../../atoms/TableOptions/TableOptions';
import DocumentList from '../../DocumentList/DocumentList';
import FileInput from '../../../molecules/FileInput/FileInput';
import BackButton from '../../../atoms/BackButton/BackButton';
import PropertyLeaseVerif from '../../PropertyLeaseVerif/PropertyLeaseVerif';
import SettingsLeaseDetails from '../../../../views/Settings/SettingsPayments/SettingsLeaseDetails';

// Services
import {
  createLease,
  createLeasePreview,
  getLeaseDocuments,
  createLeaseDocument,
  deleteLeaseDocument,
  getLeasableTenants,
} from '../../../../services/lease';
import { getProperties, getPropertyDocuments } from '../../../../services/property';
import { getTenantDocumentList } from '../../../../services/person';
import { getSettings } from '../../../../services/settings';

// Utils
import leaseSchema, { leaseInitialValues } from '../../../../utils/forms/leaseSchema';
import { displayError, errorFocusSubmit, isFieldValid } from '../../../../utils/forms/form';
import { showModal, hideModal } from '../../../../utils/modal';
import { handleDocumentDownload, handleFileSelect } from '../../../../utils/documents';

// Hooks
import { useAppContext } from '../../../../store/context';
import useScroll from '../../../../hooks/useScroll';
import useFileUpload from '../../../../hooks/useFileUpload';

// Styles
import styles from './LeaseForm.module.scss';

// Constants
import {
  PROPERTY_STATUS,
  LEASE_STATUS,
  LEASE_DURATIONS,
  LEASE_RENT_ADJUSMENT_FREQUENCY,
} from '../../../../utils/constants';

const TENANT_ADD_MODAL_ID = 'tenant-add-modal';
const PROPERTY_ADD_MODAL_ID = 'property-add-modal';
const ADD_LEASE_DOCUMENT_MODAL_ID = 'add-lease-document-modal-id';

function LeaseForm({
  leaseRealStatus = 'new',
  leaseQuery = null,
}) {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();
  const [statusMessage, setStatusMessage] = useState({ displayed: false, value: '' });
  const { scrollContentToTop, scrollToElement } = useScroll();
  const navigate = useNavigate();
  const { confirmLease, settingsPayments } = useFlags();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [leaseStatus, setLeaseStatus] = useState(leaseRealStatus || 'new');
  const [propertiesQuery, setPropertiesQuery] = useState('');
  const [tenantsQuery, setTenantsQuery] = useState('');
  const [previewStep, setPreviewStep] = useState(false);
  const [confirmStep, setConfirmStep] = useState(false);

  const match = useMatch('/lease/:id');

  // TENANTS DATA
  // Used for the select
  const [tenantSelected, setTenantSelected] = useState(null);
  const [tenantsSelected, setTenantsSelected] = useState([]);
  const [tenantsFiles, setTenantsFiles] = useState([]);

  useEffect(() => {
    if (tenantsSelected) {
      const uids = tenantsSelected.map((tenant) => JSON.parse(tenant).uid);
      if (uids.length > 0) {
        Promise.all(uids
          .map((tenantUid) => getTenantDocumentList(tenantUid, { itemsPerPage: 100, page: 1 })))
          .then((res) => {
            const files = res.map((r) => r?.data?.collection || []);
            setTenantsFiles(uids.map((tenantUid, index) => ({ uid: tenantUid, files: files[index] })));
          })
          .catch(() => {
            utils.toast.error(t('lease.add.form.errors.getTenantsDocuments'));
          });
      }
    }
  }, [tenantsSelected]);

  const {
    data: tenantListData,
    isFetching: tenantListFetching,
    isLoading: tenantListLoading,
  } = useQuery({
    queryKey: ['tenant-list-select', tenantsQuery],
    queryFn: () => getLeasableTenants({
      page: 1,
      itemsPerPage: 100,
      filters: {
        search: [tenantsQuery],
      },
    }),
    keepPreviousData: true,
  });

  const handleSearchTenants = useCallback(debounce((value) => {
    setTenantsQuery(value);
  }, 500), [setTenantsQuery]);

  // PROPERTIES DATA
  const [propertySelected, setPropertySelected] = useState(null);
  const [propertyFiles, setPropertyFiles] = useState([]);

  useEffect(() => {
    if (propertySelected) {
      const { uid } = JSON.parse(propertySelected);
      getPropertyDocuments(uid, { itemsPerPage: 100, page: 1 }).then((res) => {
        setPropertyFiles(res?.data?.collection || []);
      });
    }
  }, [propertySelected]);

  const {
    data: propertyListData,
    isFetched: propertyListFetched,
    isFetching: propertyListFetching,
    isLoading: propertyListLoading,
  } = useQuery({
    queryKey: ['property-list-select', propertiesQuery],
    queryFn: () => getProperties({
      page: 1,
      itemsPerPage: 100,
      filters: {
        status: [PROPERTY_STATUS.AVAILABLE, PROPERTY_STATUS.DISABLED],
        visibility: ['visible', 'hidden', 'deactivated'],
        search: [propertiesQuery],
      },
    }),
    keepPreviousData: true,
  });

  const handleSearchProperties = useCallback(debounce((value) => {
    setPropertiesQuery(value);
  }, 500), [setPropertiesQuery]);

  // SETTINGS DATA
  const {
    data: settingsData,
  } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettings,
    keepPreviousData: false,
  });

  // FORMIK
  const initialValues = useMemo(() => {
    if (settingsData?.data?.payment) {
      return {
        ...leaseInitialValues,
        settings: settingsData?.data?.payment,
      };
    }
    return leaseInitialValues;
  }, [settingsData]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      try {
        leaseSchema.validateSync(values, {
          context: {},
          abortEarly: false,
        });
      } catch (err) {
        const errorList = err.inner.reduce((errors, error) => {
          errors[error.path] = error.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: () => {
      if (confirmLease) {
        setConfirmStep(true);
        formik.setSubmitting(false);
      } else {
        submitData();
      }
    },
  });

  const previewMutation = useMutation({
    mutationFn: createLeasePreview,
    onSuccess: ({ data }) => {
      setConfirmStep(false);
      setPreviewStep(true);
      if (data?.data) {
        const decodedHtml = Buffer.from(data.data, 'base64').toString('utf-8');
        formik.setFieldValue('html', decodedHtml);
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            if (err.response.data?.message === 'LEASE_LEASE_TENANT_DELEGATION_EXCEPTION') {
              utils.toast.error(t(`lease.add.form.errors.${err.response.data?.message}`));
            } else {
              utils.toast.error(t('global.form.errors.generic'));
            }
            formik.setErrors(err?.response?.data?.errors);
            break;
          }
          case 409: {
            utils.toast.error(t('lease.add.form.errors.alreadyExists'));
            break;
          }
          case 500: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            utils.toast.error(t('global.form.errors.generic'));
            break;
        }
      }
      formik.setSubmitting(false);
    },
  });

  const leaseMutation = useMutation({
    mutationFn: createLease,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          utils.toast.success(t('lease.add.form.success'));
          scrollContentToTop();
          formik?.resetForm();
          if (leaseStatus === 'new') {
            navigate('/lease');
          } else {
            setLeaseStatus('created');
          }
          break;
        case 204:
          setStatusMessage({ displayed: true, value: t('profile.personalInfo.form.success') });
          scrollContentToTop();
          break;
        default:
          break;
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            if (err.response.data?.message === 'LEASE_LEASE_TENANT_DELEGATION_EXCEPTION') {
              utils.toast.error(t(`lease.add.form.errors.${err.response.data?.message}`));
            } else {
              utils.toast.error(t('global.form.errors.generic'));
            }
            formik.setErrors(err?.response?.data?.errors);
            break;
          }
          case 409: {
            utils.toast.error(t('lease.add.form.errors.alreadyExists'));
            break;
          }
          case 500: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            utils.toast.error(t('global.form.errors.generic'));
            break;
        }
      }
      formik.setSubmitting(false);
    },
  });

  const submitData = useCallback(() => {
    const isNewLease = leaseStatus === 'new';
    const { values } = formik;
    (isNewLease ? previewMutation : leaseMutation).mutate({
      ...values,
      rent: utils.euroToCents(values.rent),
      charges: utils.euroToCents(values.charges),
      depositAmount: utils.euroToCents(values.depositAmount),
      lastRentAmount: values.lastRentAmount ? utils.euroToCents(values.lastRentAmount) : null,
      new: isNewLease,
      ...(values.settings || {}),
    });
  }, [leaseStatus, previewMutation, leaseMutation, formik.values]);

  const isTenantAlreadySelected = (tenant) => tenantsSelected
    .find((tSelected) => JSON.parse(tSelected).uid === tenant.uid);

  const tenantOptions = useMemo(() => {
    if (!tenantListData) return [];
    return tenantListData?.data?.collection
      ?.filter((tenant) => !isTenantAlreadySelected(tenant) && (
        !tenant.underPendingInvitation
        || (tenant.isDelegated && tenant.delegatedTo === user?.username)
      ))
      ?.map((tenant) => ({
        value: JSON.stringify(tenant),
        label: `${tenant.firstName} ${tenant.lastName}`,
      }));
  }, [tenantListData, tenantsSelected]);

  const propertyOptions = useMemo(() => {
    if (!propertyListData) return [];
    return propertyListData?.data?.collection?.map((property) => ({
      value: JSON.stringify(property),
      label: property.name,
    }));
  }, [propertyListData]);

  const noPropertiesAvailable = useMemo(() => propertyListFetched && propertyOptions.length === 0, [propertyListData]);

  const paymentFrequencyOptions = useMemo(() => {
    if (uiBuilders?.['/lease/ui']) {
      return Object.keys(uiBuilders?.['/lease/ui']?.leasePaymentFrequencies).map((type) => ({
        value: type,
        label: uiBuilders?.['/lease/ui']?.leasePaymentFrequencies[type],
      }));
    }
    return [];
  }, [t, uiBuilders]);

  const leaseTypesOptions = useMemo(() => {
    if (!uiBuilders) return [];
    return Object.keys(uiBuilders?.['/lease/ui']?.leaseTypes).map((type) => ({
      value: type,
      label: uiBuilders?.['/lease/ui']?.leaseTypes[type],
    }));
  }, [uiBuilders]);

  const leaseUsagesOptions = useMemo(() => {
    if (!uiBuilders) return [];
    return Object.keys(uiBuilders?.['/lease/ui']?.leaseUsages).map((type) => ({
      value: type,
      label: uiBuilders?.['/lease/ui']?.leaseUsages[type],
    }));
  }, [uiBuilders]);

  const regularisationFrequencyOptions = useMemo(() => [1, 2, 3].map((frequency) => ({
    label: t('lease.add.form.general.fields.chargesRegularisationFrequencySuffix', { count: frequency }),
    value: frequency,
  })), []);

  const rentAdjustmentFrequencyOptions = useMemo(() => LEASE_RENT_ADJUSMENT_FREQUENCY.map((frequency) => ({
    label: t('lease.add.form.general.fields.rentAdjustmentFrequencySuffix', { count: frequency / 12 }),
    value: frequency,
  })), []);

  const durationOptions = useMemo(() => {
    if (!formik.values?.type) return [];
    return LEASE_DURATIONS
      .filter((option) => option.types.includes(formik.values?.type))
      .map(({ value }) => ({
        label: t('lease.add.form.general.fields.durationOption_interval', {
          postProcess: 'interval',
          count: value,
          year: value / 12,
        }),
        value,
      }));
  }, [formik.values?.type, t]);

  const handleLeaseTypeChange = useCallback(async (value) => {
    const { rentExcludingCharges } = JSON.parse(propertySelected);
    const rentAmount = utils.centsToEuro(rentExcludingCharges);

    formik.setFieldValue('type', value.value);

    // Setting default duration based on lease type and user type
    if (!formik.touched.type) {
      switch (value.value) {
        case 'commercial': {
          formik.setFieldValue('duration', 108);
          formik.setFieldValue('depositAmount', null);
          break;
        }
        case 'furnished': {
          formik.setFieldValue('duration', 12);
          formik.setFieldValue('depositAmount', rentAmount * 2);
          break;
        }
        case 'empty': {
          formik.setFieldValue('duration', 36);
          formik.setFieldValue('depositAmount', rentAmount);
          break;
        }
        default:
          break;
      }
    }
  }, [formik, propertySelected, t]);

  const tenantColumn = useMemo(() => ([
    {
      header: t('lease.add.tenant.table.headerName'),
      accessorKey: 'name',
      minSize: 1,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.tableName}>
          {row?.original?.file
            ? <span className={styles.fileNameHeader}>{row?.original?.file?.type}</span>
            : (
              <>
                <span>
                  {row?.original?.firstName}
                </span>
                <span>
                  <b>
                    {row?.original?.lastName}
                  </b>
                </span>
              </>
            )}
        </div>
      ),
    },
    {
      header: t('lease.add.tenant.table.headerFileName'),
      accessorKey: 'file',
      size: 400,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.tableName}>
          {row?.original?.file
            ? (
              <>
                <span className={styles.fileNameHeader}>
                  {t('global.documents.header')}
                </span>
                <span className={styles.fileNameHeader}>
                  <b className={styles.documentNameValue} onClick={() => handleDocumentDownload(row.original?.file, t)}>
                    {row?.original?.file?.name}
                  </b>
                </span>
              </>
            )
            : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => (
        !row.original.file
          ? (
            <div className={styles.seeMore}>
              <Picto
                id={`more-option-tenant-${row?.original?.uid}`}
                icon="more"
                width={24}
                onClick={(e) => {
                  e.stopPropagation();
                  setTooltipOpen(tooltipOpen === row?.original?.uid ? null : row?.original?.uid);
                }}
                color="var(--color-secondary)"
              />
              <Tooltip
                isOpen={tooltipOpen === row?.original?.uid}
                anchorId={`more-option-tenant-${row?.original?.uid}`}
                place="bottom"
                type="dark"
                effect="solid"
              >
                <TableOptions
                  options={[
                    {
                      id: 'trash',
                      label: t('global.listOptions.delete'),
                      icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
                      onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
                      onClick: (e) => {
                        e.stopPropagation();
                        setTooltipOpen(null);

                        const tenants = tenantsSelected.map((tenant) => JSON.parse(tenant));
                        const filtered = tenants.filter((tenant) => tenant.uid !== row?.original?.uid);
                        setTenantsSelected(tenantsSelected
                          .filter((tenant) => JSON.parse(tenant).uid !== row?.original?.uid));
                        formik.setFieldValue('tenants', filtered);
                      },
                    },
                  ]}
                />
              </Tooltip>
            </div>
          ) : null
      ),
    },
  ]), [tenantsSelected, tooltipOpen, formik.setFieldValue, handleDocumentDownload, t]);

  const tenantData = useMemo(() => {
    if (!tenantsSelected) return [];

    const getTenantData = tenantsSelected.reduce((prev, cur) => {
      const {
        firstName, lastName, uid,
      } = JSON.parse(cur);

      const list = [...prev, {
        firstName,
        lastName,
        uid,
        file: null,
      }];

      if (tenantsFiles?.length === 0) return list;
      const tenantFiles = tenantsFiles.find((tenant) => tenant.uid === uid);
      if (!tenantFiles) return list;

      return [
        ...list,
        ...tenantFiles.files.map((file) => ({
          firstName: null,
          lastName: null,
          file: {
            ...file,
            type: uiBuilders?.['/person/ui'].documentTypePPH[file.type],
          },
        })),
      ];
    }, []);

    return getTenantData;
  }, [tenantsSelected, tenantsFiles]);

  const propertyColumns = useMemo(() => ([
    {
      header: t('lease.add.property.table.headerName'),
      accessorKey: 'name',
      minSize: 1,
      enableSorting: false,
      cell: ({ row }) => {
        const {
          name, address, file, uid,
        } = row.original;
        return (
          <div className={styles.tableName}>
            {file
              ? <span className={styles.fileNameHeader}>{file?.type}</span>
              : (
                <>
                  <a href={`/property/${uid}`} target="_blank" rel="noreferrer">
                    {name}
                    <Picto className="m-l-5" width={9} icon="export-link" />
                  </a>
                  <div className={styles.address}>
                    <span>
                      {`${address?.streetNumber || ''} ${address?.street || ''}`}
                    </span>
                    <span>
                      {`${address?.zipCode || ''} ${address?.city || ''}`}
                    </span>
                  </div>
                </>
              )}
          </div>
        );
      },
    },
    {
      header: t('lease.add.tenant.table.headerFileName'),
      accessorKey: 'file',
      size: 400,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.tableName}>
          {row?.original?.file
            ? (
              <>
                <span className={styles.fileNameHeader}>
                  {t('global.documents.header')}
                </span>
                <span className={styles.fileNameHeader}>
                  <b
                    className={styles.documentNameValue}
                    onClick={() => handleDocumentDownload(row?.original?.file, t)}
                  >
                    {row?.original?.file?.name}
                  </b>
                </span>
              </>
            )
            : null}
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 100,
      enableSorting: false,
      cell: ({ row }) => (
        !row.original.file
          ? (
            <div className={styles.seeMore}>
              <Picto
                id={`more-option-property-${row?.original?.uid}`}
                icon="more"
                width={24}
                onClick={(e) => {
                  e.stopPropagation();
                  if (tooltipOpen === row?.original?.uid) setTooltipOpen(null);
                  else setTooltipOpen(row?.original?.uid);
                }}
                color="var(--color-secondary)"
              />
              <Tooltip
                isOpen={tooltipOpen === row?.original?.uid}
                anchorId={`more-option-property-${row?.original?.uid}`}
                place="bottom"
                type="dark"
                effect="solid"
              >
                <TableOptions
                  options={[
                    {
                      id: 'trash',
                      label: t('global.listOptions.delete'),
                      icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
                      onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
                      onClick: (e) => {
                        e.stopPropagation();
                        setTooltipOpen(null);
                        setPropertySelected(null);
                        formik.setFieldValue('propertyUid', null);
                      },
                    },
                  ]}
                />
              </Tooltip>
            </div>
          ) : null
      ),
    },
  ]), [propertySelected, tooltipOpen]);

  const propertyData = useMemo(() => {
    if (!propertySelected) return [];

    const { name, address, uid } = JSON.parse(propertySelected);
    return [
      {
        name,
        address,
        uid,
        file: null,
      },
      ...propertyFiles.map(((file) => ({
        firstName: null,
        lastName: null,
        file: {
          ...file,
          type: uiBuilders?.['/property/ui'].documentTypes[file.type],
        },
      }))),
    ];
  }, [propertySelected, propertyFiles]);

  const leaseStatusOptions = useMemo(() => [
    {
      id: 'new',
      label: t('lease.add.option1'),
      value: 'new',
    },
    {
      id: 'existing',
      label: t('lease.add.option2'),
      value: 'existing',
    },
  ], [t]);

  // Documents upload for existing lease
  const [isHugeDocument, setHugeDocument] = useState(false);

  const resetDocumentModal = () => {
    hideModal(ADD_LEASE_DOCUMENT_MODAL_ID);
    setFileType(null);
    setFile(null);
    setHugeDocument(false);
    formik.resetForm();
  };

  const fileList = useMemo(() => {
    if (uiBuilders) {
      const { documentTypes } = uiBuilders['/lease/ui'];
      return Object.keys(documentTypes);
    }
    return [];
  }, [uiBuilders]);

  const documentTypeOptions = useMemo(() => {
    if (uiBuilders) {
      const { documentTypes } = uiBuilders['/lease/ui'];
      return Object.keys(documentTypes).map((key) => ({
        value: key,
        label: documentTypes[key],
      }));
    }
    return [];
  }, [uiBuilders]);

  const columns = useMemo(() => ([
    {
      header: t('global.documents.columns.headers.category'),
      accessorKey: 'type',
      size: 1,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!uiBuilders) return null;
        const documentType = uiBuilders['/lease/ui'].documentTypes[original?.type];
        return (
          <div className={styles.documentTypeValue}>{documentType}</div>
        );
      },
    },
    {
      header: t('global.documents.columns.headers.name'),
      accessorKey: 'name',
      size: 400,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return (
          <div>
            <p
              tabIndex={0}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        );
      },
    },
  ]), [t, uiBuilders]);

  const {
    data: documentListData,
    isFetching: documentListFetching,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['lease-documents', leaseMutation.data],
    queryFn: async () => {
      const leaseUid = leaseMutation.data?.data?.uid || match?.params?.id;
      if (leaseUid && leaseUid !== 'add') {
        return getLeaseDocuments(leaseUid, {
          page: 1,
          itemsPerPage: 100,
        });
      }
      return [];
    },
    keepPreviousData: true,
  });

  const documentInitialValues = useMemo(() => ({
    customName: '',
    filename: '',
    type: 'lease',
    userUid: user?.username || null,
    metadata: null,
  }), [user]);

  const diagnosisDocumentFormik = useFormik({
    initialValues: documentInitialValues,
    validationSchema: yup.object().shape({
      customName: yup.string().max(50, 'Filename too long').required('global.form.errors.required'),
      filename: yup.string(),
      type: yup.string().required('global.form.errors.required'),
      userUid: yup.string().required('global.form.errors.required'),
      metadata: yup.object().shape(),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof file === 'object') {
        uploadFile({
          ...values,
          type: 'lease',
        });
      } else {
        utils.toast.info(t('global.documents.addDocumentForm.errors.noFileSelected'));
      }
    },
  });

  const documentDeleteMutation = useMutation({
    mutationFn: deleteLeaseDocument,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('global.documents.delete.success'));
          refetchDocumentList();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const createDocumentCb = async (documentUid, fileName) => {
    const leaseUid = leaseMutation.data?.data?.uid || match?.params?.id;
    if (leaseUid && leaseUid !== 'add') {
      const createdDocument = await createLeaseDocument(leaseUid, {
        type: fileType,
        name: fileName,
        documentUid,
      });
      refetchDocumentList();
      resetDocumentModal();
      return createdDocument;
    }
    return null;
  };

  const [{ uploadFile, setFileType, setFile }, {
    fileType, file, isUploading,
  }] = useFileUpload(createDocumentCb);

  const handleAddTenant = () => {
    formik.setFieldValue('tenants', [
      ...tenantsSelected.map((tenant) => JSON.parse(tenant).uid),
      JSON.parse(tenantSelected).uid,
    ]);
    setTenantsSelected([...tenantsSelected, tenantSelected]);
    hideModal(TENANT_ADD_MODAL_ID);
    setTenantSelected(null);
  };

  const startDateValue = useMemo(() => {
    try {
      if (!formik.values.startDate) return null;
      return new Date(formik.values.startDate);
    } catch {
      return null;
    }
  }, [formik.values.startDate]);

  const lastRentDateValue = useMemo(() => {
    try {
      if (!formik.values.lastRentDate) return null;
      return new Date(formik.values.lastRentDate);
    } catch {
      return null;
    }
  }, [formik.values.lastRentDate]);

  const lastRentAdjustmentDateValue = useMemo(() => {
    try {
      if (!formik.values.lastRentAdjustmentDate) return null;
      return new Date(formik.values.lastRentAdjustmentDate);
    } catch {
      return null;
    }
  }, [formik.values.lastRentAdjustmentDate]);

  const handleFileChange = useCallback((e) => {
    handleFileSelect(e, setHugeDocument, diagnosisDocumentFormik, setFile, t);
  }, [setHugeDocument, diagnosisDocumentFormik, setFile, t]);

  const creationForm = (
    <form className={styles.form} onSubmit={errorFocusSubmit(formik.handleSubmit)}>
      <h2 className="m-b-30">{t('lease.add.title')}</h2>
      <RadioGroup
        name="leaseStatus"
        options={leaseStatusOptions}
        value={leaseStatus}
        onChange={setLeaseStatus}
      />
      <TextInput
        type="text"
        id="name"
        name="name"
        className={utils.cn(['m-t-25', styles.largeInput])}
        label={t('lease.add.form.general.fields.name')}
        error={displayError(t, formik, 'name')}
        valid={isFieldValid(formik, 'name', null, leaseInitialValues?.name)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
      />

      {/* Add a tenant */}
      {formik?.values?.tenants?.length > 0
        ? (
          <div className={styles.tenants}>
            <p><b>{t('lease.add.tenant.title')}</b></p>
            <Button
              className={utils.cn([
                'm-b-30',
                'float-right',
                styles.mediumInput,
              ])}
              variant="primary"
              icon="plus"
              iconSide="left"
              disabled={tenantOptions.length === 0}
              onClick={() => {
                showModal(TENANT_ADD_MODAL_ID);
              }}
            >
              {t('lease.add.tenant.replace')}
            </Button>
            <Table
              fullWidth
              columns={tenantColumn}
              data={tenantData}
            />
          </div>
        )
        : (
          <Button
            className={utils.cn([styles.linkBtn, styles.mediumInput])}
            variant="primary"
            icon="plus"
            iconSide="left"
            onClick={() => {
              showModal(TENANT_ADD_MODAL_ID);
            }}
            loading={tenantListLoading}
          >
            {t('lease.add.tenant.action')}
          </Button>
        )}

      {/* Add a property */}
      {formik?.values?.propertyUid
        ? (
          <div className={styles.tenants}>
            <p><b>{t('lease.add.property.title')}</b></p>
            <Button
              className={utils.cn([
                'm-b-30',
                'float-right',
                styles.mediumInput,
              ])}
              variant="primary"
              icon="plus"
              iconSide="left"
              onClick={() => {
                showModal(PROPERTY_ADD_MODAL_ID);
              }}
            >
              {t('lease.add.property.replace')}
            </Button>
            <Table
              fullWidth
              columns={propertyColumns}
              data={propertyData}
            />
          </div>
        )
        : (
          <Button
            className={utils.cn(['m-t-20', styles.linkBtn, styles.mediumInput])}
            variant="primary"
            icon="plus"
            iconSide="left"
            onClick={() => {
              showModal(PROPERTY_ADD_MODAL_ID);
            }}
            loading={propertyListLoading}
            disabled={noPropertiesAvailable}
          >
            {t('lease.add.property.action')}
          </Button>
        )}

      {noPropertiesAvailable && (
        <Message
          className="m-t-20"
          content={t('lease.add.property.noPropertiesAvailable')}
          variant="error"
        />
      )}

      {(formik?.values?.propertyUid && formik?.values?.tenants?.length > 0) && (
      <div id="type-select">
        <SelectInput
          className={utils.cn(['m-t-25', styles.largeInput])}
          id="type"
          name="type"
          label={t('lease.add.form.general.fields.type')}
          options={leaseTypesOptions}
          error={displayError(t, formik, 'type')}
          valid={isFieldValid(formik, 'type', null, leaseInitialValues?.name)}
          onChange={(e) => {
            handleLeaseTypeChange(e);
            setTimeout(() => {
              scrollToElement('type-select');
            }, 200);
          }}
          onBlur={formik.handleBlur}
          value={leaseTypesOptions.find((type) => type.value === formik.values.type)}
        />
      </div>
      )}

      {(formik?.values?.type) && (
      <>
        <SelectInput
          className={utils.cn(['m-t-25', styles.largeInput])}
          id="usage"
          name="usage"
          label={t('lease.add.form.general.fields.usage')}
          options={leaseUsagesOptions}
          error={displayError(t, formik, 'usage')}
          valid={isFieldValid(formik, 'usage', null, leaseInitialValues?.name)}
          onChange={(value) => formik.setFieldValue('usage', value.value)}
          onBlur={formik.handleBlur}
          value={leaseUsagesOptions.find((usage) => usage.value === formik.values.usage)}
        />

        {/* PRESELECTED WHEN PICKING A PROPERTY */}
        <TextInput
          min="0"
          type="number"
          id="rent"
          name="rent"
          className={utils.cn(['m-t-25', styles.mediumInput])}
          label={t('lease.add.form.general.fields.rent')}
          value={formik.values.rent}
          info={t('lease.add.form.general.fields.rentInfo')}
          icon="euro"
          iconSize={20}
          iconColor="var(--color-primary-300)"
          disabled
        />
        <TextInput
          min="0"
          type="number"
          id="charges"
          name="charges"
          className={utils.cn(['m-t-25', styles.mediumInput])}
          label={t('lease.add.form.general.fields.charges')}
          value={formik.values.charges}
          icon="euro"
          iconSize={20}
          iconColor="var(--color-primary-300)"
          disabled
        />

        <SelectInput
          isSearchable={false}
          className={utils.cn(['m-t-25', styles.largeInput])}
          id="paymentFrequency"
          name="paymentFrequency"
          label={t('lease.add.form.general.fields.paymentFrequency')}
          options={paymentFrequencyOptions}
          error={displayError(t, formik, 'paymentFrequency')}
          valid={isFieldValid(formik, 'paymentFrequency', null, leaseInitialValues?.name)}
          onChange={(value) => formik.setFieldValue('paymentFrequency', value.value)}
          onBlur={formik.handleBlur}
          value={paymentFrequencyOptions
            .find((month) => month.value === formik.values.paymentFrequency)}
        />

        {formik.values.type === 'commercial' ? (
          <TextInput
            type="text"
            id="activityPractised"
            name="activityPractised"
            className={utils.cn(['m-t-25', styles.mediumInput])}
            label={t('lease.add.form.general.fields.activityPractised')}
            error={displayError(t, formik, 'activityPractised')}
            valid={isFieldValid(formik, 'activityPractised', null, leaseInitialValues?.activityPractised)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.activityPractised}
          />
        ) : null}

        <SelectInput
          isSearchable={false}
          className={utils.cn(['m-t-25', styles.largeInput])}
          id="chargesRegularisationFrequency"
          name="chargesRegularisationFrequency"
          label={t('lease.add.form.general.fields.chargesRegularisationFrequency')}
          options={regularisationFrequencyOptions}
          error={displayError(t, formik, 'chargesRegularisationFrequency')}
          valid={isFieldValid(formik, 'chargesRegularisationFrequency', null, leaseInitialValues?.name)}
          onChange={(value) => formik.setFieldValue('chargesRegularisationFrequency', value.value)}
          onBlur={formik.handleBlur}
          value={regularisationFrequencyOptions
            .find((frequency) => frequency.value === formik.values.chargesRegularisationFrequency)}
        />
        <DateInput
          id="startDate"
          name="startDate"
          className={utils.cn(['m-t-25', styles.dateInput])}
          label={t('lease.add.form.general.fields.startDate')}
          error={displayError(t, formik, 'startDate')}
          valid={isFieldValid(formik, 'startDate', null, leaseInitialValues?.startDate)}
          onChange={(date) => formik.setFieldValue('startDate', date)}
          info={t('lease.add.form.general.fields.startDateInfo')}
          onBlur={formik.handleBlur}
          value={startDateValue}
        />
        <SelectInput
          className={utils.cn(['m-t-25', styles.mediumInput])}
          id="duration"
          name="duration"
          label={t('lease.add.form.general.fields.duration')}
          options={durationOptions}
          error={displayError(t, formik, 'duration')}
          valid={isFieldValid(formik, 'duration', null, leaseInitialValues?.name)}
          onChange={(value) => formik.setFieldValue('duration', value.value)}
          onBlur={formik.handleBlur}
          value={durationOptions.find((duration) => duration.value === formik.values.duration)}
        />
        <TextInput
          min="0"
          type="number"
          id="rentPaymentDay"
          name="rentPaymentDay"
          className={utils.cn(['m-t-25', styles.largeInput])}
          label={t('lease.add.form.general.fields.rentPaymentDay')}
          error={displayError(t, formik, 'rentPaymentDay')}
          valid={isFieldValid(formik, 'rentPaymentDay', null, leaseInitialValues?.rentPaymentDay)}
          info={t('lease.add.form.general.fields.rentPaymentDayInfo')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.rentPaymentDay}
        />
        <SelectInput
          className={utils.cn(['m-t-25', styles.mediumInput])}
          id="rentAdjustmentFrequency"
          name="rentAdjustmentFrequency"
          label={t('lease.add.form.general.fields.rentAdjustmentFrequency')}
          options={rentAdjustmentFrequencyOptions}
          error={displayError(t, formik, 'rentAdjustmentFrequency')}
          valid={isFieldValid(formik, 'rentAdjustmentFrequency', null, leaseInitialValues?.name)}
          onChange={(value) => formik.setFieldValue('rentAdjustmentFrequency', value.value)}
          onBlur={formik.handleBlur}
          // eslint-disable-next-line max-len
          value={rentAdjustmentFrequencyOptions.find((rentAdjustmentFrequency) => rentAdjustmentFrequency.value === formik.values.rentAdjustmentFrequency)}
        />
        <TextInput
          min="0"
          type="number"
          id="depositAmount"
          name="depositAmount"
          step="any"
          className={utils.cn(['m-t-25', styles.largeInput])}
          label={t('lease.add.form.general.fields.depositAmount')}
          error={displayError(t, formik, 'depositAmount')}
          valid={isFieldValid(formik, 'depositAmount', null, leaseInitialValues?.depositAmount)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.depositAmount}
          icon="euro"
          iconSize={20}
          iconColor="var(--color-primary-300)"
        />

        { formik.values.type === 'commercial' && (
        <>
          <ToggleInput
            id="rentSubjectToVat"
            name="rentSubjectToVat"
            className={utils.cn(['m-t-25', styles.mediumInput])}
            label={t('lease.add.form.general.fields.rentSubjectToVat')}
            checked={!!formik.values.rentSubjectToVat}
            onChange={(check) => formik.setFieldValue('rentSubjectToVat', check)}
          />
          <SelectInput
            className={utils.cn(['m-t-25', styles.largeInput])}
            id="terminationFrequency"
            name="terminationFrequency"
            label={t('lease.add.form.general.fields.terminationFrequency')}
            options={durationOptions}
            error={displayError(t, formik, 'terminationFrequency')}
            valid={isFieldValid(formik, 'terminationFrequency', null, leaseInitialValues?.name)}
            onChange={(value) => formik.setFieldValue('terminationFrequency', value.value)}
            onBlur={formik.handleBlur}
            // eslint-disable-next-line max-len
            value={durationOptions.find((terminationFrequency) => terminationFrequency.value === formik.values.terminationFrequency)}
          />
        </>
        )}

        <WysiwygInput
          className={utils.cn(['m-t-25', styles.largeInput])}
          label={t('lease.add.form.general.fields.additionalMarkings')}
          onChange={(value) => formik.setFieldValue('additionalMarkings', value)}
          error={displayError(t, formik, 'additionalMarkings', 'wysiwyg')}
          valid={isFieldValid(formik, 'additionalMarkings', null, leaseInitialValues?.additionalMarkings)}
          defaultValue={null}
        />

        <h2 className="m-t-40 m-b-30">{t('lease.add.form.tenseArea.title')}</h2>
        <FormInfoRequired content={(
          <p>
            {t('lease.add.form.tenseArea.info')}
            {' '}
            <a href="https://google.com" target="_blank" rel="noreferrer">
              {t('lease.add.form.tenseArea.infoLink')}
              <Picto className="m-l-5" width={9} icon="export-link" />
            </a>
          </p>
            )}
        />
        <TextInput
        // min="0"
          type="text"
          id="lastRentAmount"
          name="lastRentAmount"
          step="any"
          className={utils.cn(['m-t-25', styles.largeInput])}
          label={t('lease.add.form.tenseArea.fields.lastRentAmount')}
          error={displayError(t, formik, 'lastRentAmount')}
          valid={isFieldValid(formik, 'lastRentAmount', null, leaseInitialValues?.lastRentAmount)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastRentAmount}
        />
        <div className={styles.lastRentDates}>
          <DateInput
            id="lastRentDate"
            name="lastRentDate"
            className="m-t-30"
            label={t('lease.add.form.tenseArea.fields.lastRentDate')}
            error={displayError(t, formik, 'lastRentDate', 'date', { errorIfUntouched: true })}
            valid={isFieldValid(formik, 'lastRentDate', null, leaseInitialValues?.lastRentDate)}
            onChange={(date) => {
              formik.setFieldValue('lastRentDate', date);
              setTimeout(() => {
                formik.handleBlur('lastRentDate');
                formik.setFieldTouched('lastRentDate', true);
              }, 50);
            }}
            info={t('lease.add.form.tenseArea.fields.dateInfo')}
            onBlur={formik.handleBlur}
            value={lastRentDateValue}
          />
          <DateInput
            id="lastRentAdjustmentDate"
            name="lastRentAdjustmentDate"
            className="m-t-30"
            label={t('lease.add.form.tenseArea.fields.lastRentAdjustmentDate')}
            error={displayError(t, formik, 'lastRentAdjustmentDate', 'date', { errorIfUntouched: true })}
            valid={isFieldValid(formik, 'lastRentAdjustmentDate', null, leaseInitialValues?.lastRentAdjustmentDate)}
            onChange={(date) => {
              formik.setFieldValue('lastRentAdjustmentDate', date);
              setTimeout(() => {
                formik.handleBlur('lastRentAdjustmentDate');
                formik.setFieldTouched('lastRentAdjustmentDate', true);
              }, 50);
            }}
            info={t('lease.add.form.tenseArea.fields.dateInfo')}
            onBlur={formik.handleBlur}
            value={lastRentAdjustmentDateValue}
          />
        </div>

        {settingsPayments && (
          <>
            <h2 className="m-t-40 m-b-30">{t('lease.add.form.settings.title')}</h2>
            <SettingsLeaseDetails
              formik={formik}
              initialValues={leaseInitialValues}
              parentKey="settings"
            />
          </>
        )}

        {/* SUBMIT NEW LEASE */}
        <div className={styles.submit}>
          <Button
            type="submit"
            size="large"
            loading={formik.isSubmitting}
            label={t('global.next')}
          />
        </div>
      </>
      )}
    </form>
  );

  const createdLeaseDocList = (
    <div className={styles.documentList}>
      <h2 className="m-b-30">{t('lease.add.form.documentsExisting.title')}</h2>
      <DocumentList
        className="m-t-25"
        fileList={fileList}
        columns={columns}
        documentListData={documentListData}
        addButton={{
          enabled: true,
          label: t('global.documents.addDocument'),
          icon: 'plus',
        }}
        onAddBtnClick={() => {
          setFileType('lease');
          formik.setFieldValue('type', 'lease');
          showModal(ADD_LEASE_DOCUMENT_MODAL_ID);
        }}
        onFileListElementDelete={(element) => {
          documentDeleteMutation.mutate(element?.uid);
        }}
        pagination={null}
        isDocumentListFetching={documentListFetching}
      />
      <div className={styles.submitBtn}>
        <Button
          type="button"
          className="m-t-25"
          label={t('lease.add.form.documentsExisting.submit')}
          onClick={() => {
            utils.toast.success(t('lease.add.form.documentsExisting.success'));
            if (leaseRealStatus === 'created') {
              leaseQuery?.refetch();
            } else {
              navigate('/lease');
            }
          }}
          disabled={!documentListData?.data?.collection?.length || isUploading}
        />
      </div>
    </div>
  );

  const displayForm = useCallback(() => {
    if (leaseStatus === LEASE_STATUS.CREATED) return createdLeaseDocList;
    if (confirmStep) {
      return (
        <PropertyLeaseVerif
          onSubmit={submitData}
          onCancel={() => setConfirmStep(false)}
          property={propertySelected ? JSON.parse(propertySelected) : null}
          submitLabel={t('lease.add.form.submit')}
          isSubmitting={previewMutation.isLoading}
        />
      );
    }
    if (previewStep) {
      return (
        <>
          <BackButton
            label={t('global.goBack')}
            onClick={() => setPreviewStep(false)}
          />
          <LeasePreview
            formik={formik}
            leaseMutation={leaseMutation}
          />
        </>
      );
    }
    return creationForm;
  }, [
    previewStep,
    leaseStatus,
    creationForm,
    createdLeaseDocList,
    formik,
    leaseMutation,
    confirmStep,
    submitData,
    t,
    setConfirmStep,
    propertySelected,
  ]);

  const selectedTenantOption = useMemo(() => {
    if (!tenantSelected) return null;
    const data = JSON.parse(tenantSelected);
    return {
      value: data.uid,
      label: `${data.firstName} ${data.lastName}`,
    };
  }, [tenantSelected]);

  const selectedPropertyOption = useMemo(() => {
    if (!propertySelected) return null;
    const data = JSON.parse(propertySelected);
    return {
      value: data.uid,
      label: data.name,
    };
  }, [propertySelected]);

  return (
    <div onClick={() => setTooltipOpen(null)} className={styles.wrapper}>
      {displayForm()}
      <div>
        {statusMessage?.displayed ? <Message content={statusMessage.value} /> : null}
      </div>

      {/* Tenant Add Modal */}
      <Modal
        className={styles.modal}
        id={TENANT_ADD_MODAL_ID}
        title={t('lease.add.tenant.action')}
        size="large"
        onClose={() => hideModal(TENANT_ADD_MODAL_ID)}
      >
        <SelectInput
          className="m-t-25"
          id="tenant-select"
          name="tenant-select"
          label={t('lease.add.tenant.selectPlaceholder')}
          options={tenantOptions}
          info={t('lease.add.tenant.selectInfo')}
          isSearchable
          onChange={(value) => setTenantSelected(value.value)}
          onBlur={formik.handleBlur}
          value={selectedTenantOption}
          onInputChange={(value) => handleSearchTenants(value)}
          isLoading={tenantListFetching}
        />
        <Button
          className={utils.cn(['m-t-25', styles.modalSubmitBtn])}
          size="large"
          variant="primary"
          disabled={tenantSelected === null}
          onClick={handleAddTenant}
        >
          {t('global.validate')}
        </Button>
      </Modal>

      {/* Property Add Modal */}
      <Modal
        className={styles.modal}
        id={PROPERTY_ADD_MODAL_ID}
        title={t('lease.add.property.action')}
        size="large"
        onClose={() => hideModal(PROPERTY_ADD_MODAL_ID)}
      >
        <SelectInput
          className="m-t-25"
          id="property-select"
          name="property-select"
          label={t('lease.add.property.selectPlaceholder')}
          options={propertyOptions}
          info={t('lease.add.property.selectInfo')}
          isSearchable
          onChange={(value) => setPropertySelected(value.value)}
          onBlur={formik.handleBlur}
          value={selectedPropertyOption}
          onInputChange={(value) => handleSearchProperties(value)}
          isLoading={propertyListFetching}
        />
        <Button
          className={utils.cn(['m-t-25', styles.modalSubmitBtn])}
          size="large"
          variant="primary"
          disabled={propertySelected === null}
          onClick={() => {
            const property = JSON.parse(propertySelected);
            formik.setFieldValue('propertyUid', property?.uid);
            formik.setFieldValue('rent', utils.centsToEuro(property?.rentExcludingCharges));
            formik.setFieldValue('charges', utils.centsToEuro(property?.charges));
            hideModal(PROPERTY_ADD_MODAL_ID);
          }}
        >
          {t('global.validate')}
        </Button>
      </Modal>

      <Modal
        className={styles.modal}
        id={ADD_LEASE_DOCUMENT_MODAL_ID}
        title={isHugeDocument
          ? t('profile.documents.addDocumentForm.errors.size.title')
          : t('global.documents.addDocumentForm.fields.file')}
        size="large"
        onClose={resetDocumentModal}
      >
        {!isHugeDocument ? (
          <form onSubmit={diagnosisDocumentFormik.handleSubmit} className={styles.addForm}>
            <SelectInput
              isSearchable={false}
              className="m-t-25"
              id="type"
              name="type"
              label={t('global.documents.addDocumentForm.fields.type')}
              options={documentTypeOptions}
              onChange={(value) => {
                setFileType(value.value);
                formik.setFieldValue('type', value.value);
              }}
              onBlur={formik.handleBlur}
              error={displayError(t, formik, 'type')}
              valid={isFieldValid(formik, 'type', null, documentInitialValues.type)}
              value={documentTypeOptions.find((type) => type.value === fileType)}
            />
            <TextInput
              type="text"
              id="customName"
              name="customName"
              className="m-t-25"
              label={t('profile.documents.addDocumentForm.fields.name')}
              error={displayError(t, diagnosisDocumentFormik, 'customName')}
              valid={isFieldValid(diagnosisDocumentFormik, 'customName', null, documentInitialValues.customName)}
              onChange={diagnosisDocumentFormik.handleChange}
              onBlur={diagnosisDocumentFormik.handleBlur}
              value={diagnosisDocumentFormik.values.customName}
            />
            <FileInput
              className="m-t-25"
              id="profile-file"
              name="profile-file"
              label={t('profile.documents.addDocumentForm.fields.file')}
              help={t('profile.documents.addDocumentForm.fileHelp')}
              accept="image/jpg,image/png,image/jpeg,application/pdf"
              value={file}
              onChange={handleFileChange}
            />
            <div className={styles.submitBtn}>
              <Button
                type="submit"
                loading={isUploading}
                className="m-t-25"
                label={t('profile.documents.addDocumentForm.submit')}
              />
            </div>
          </form>
        ) : (
          <div className={styles.hugeDocument}>
            <p className={styles.errorModalSubtitle}>{t('profile.documents.addDocumentForm.errors.size.message')}</p>
            <div className={styles.submitBtn}>
              <Button
                className="m-t-25"
                label={t('profile.documents.addDocumentForm.errors.size.action')}
                onClick={() => {
                  diagnosisDocumentFormik.resetForm();
                  setFileType(null);
                  setFile(null);
                  setHugeDocument(false);
                }}
              />
            </div>
          </div>
        ) }
      </Modal>
    </div>
  );
}

LeaseForm.propTypes = {
  leaseRealStatus: PropTypes.string,
  leaseQuery: PropTypes.shape({
    refetch: PropTypes.func,
  }),
};

export default LeaseForm;
