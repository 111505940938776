import { useMemo } from 'react';
import useRoles from './useRoles';
import { useAppContext } from '../store/context';

const useManagementRights = (managerUid) => {
  const { isUserAdmin } = useRoles();
  const { context: { user, me } } = useAppContext();

  const userHasManagementRights = useMemo(() => {
    if (isUserAdmin) return true;
    if (user && me?.aclMatrix?.companies && managerUid) {
      const options = [user.username];
      if (Array.isArray(me.aclMatrix.companies)) {
        const userCompanies = me.aclMatrix.companies
          .filter((company) => company.owner || (!company.owner && !company.isRealEstateAgency));
        userCompanies.forEach((company) => {
          options.push(company.uid);
        });
      }
      return options.includes(managerUid);
    }
    return false;
  }, [managerUid, user, me, isUserAdmin]);

  return userHasManagementRights;
};

export default useManagementRights;
