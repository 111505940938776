/* eslint-disable max-len */
import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation, useMatch } from 'react-router-dom';

// Components
import { Picto, utils } from 'ui-library-unlocker';
import ResidenceForm from '../../components/organisms/forms/ResidenceForm/ResidenceForm';
import ResidenceCharacteristicsForm from '../../components/organisms/forms/ResidenceForm/ResidenceCharacteristicsForm';
import ResidenceProperties from '../../components/organisms/forms/ResidenceForm/ResidenceProperties';

// Services
import { getResidence } from '../../services/residence';

// Styles
import styles from './ResidenceEdit.module.scss';

function ResidenceEdit({ isEditing }) {
  const { t } = useTranslation();
  const location = useLocation();

  const match = useMatch('/residence/:id');

  useEffect(() => {
    switch (location.hash) {
      case '#characteristics':
        document.title = t('residence.tabs.characteristics.browserTitle');
        break;
      case '#properties':
        document.title = t('residence.tabs.properties.browserTitle');
        break;
      default:
        document.title = t('residence.tabs.generalInfo.browserTitle');
        break;
    }
  }, [location, t]);

  const residenceQuery = useQuery({
    queryKey: ['residence-id', isEditing],
    queryFn: () => getResidence(match?.params?.id),
    cacheTime: 0,
    enabled: isEditing && match?.params?.id !== 'add',
  });

  const tabs = useMemo(() => [
    {
      label: t('residence.tabs.generalInfo.tabTitle'),
      title: t('residence.tabs.generalInfo.title'),
      hash: '',
      show: true,
      activePicto: <Picto color="var(--color-secondary)" width="100%" icon="trace-3512" />,
      component: (
        <ResidenceForm isEditing={isEditing} residenceQuery={residenceQuery} />
      ),
    },
    {
      label: t('residence.tabs.characteristics.tabTitle'),
      title: t('residence.tabs.characteristics.title'),
      hash: 'characteristics',
      show: true,
      activePicto: <Picto style={{ bottom: '-13px' }} className="m-t-30" color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <ResidenceCharacteristicsForm residenceQuery={residenceQuery} />
      ),
    },
    {
      label: t('residence.tabs.properties.tabTitle'),
      title: t('residence.tabs.properties.title'),
      hash: 'properties',
      show: true,
      activePicto: <Picto style={{ bottom: '-13px' }} className="m-t-30" color="var(--color-secondary)" width="100%" icon="trace-3511" />,
      component: (
        <ResidenceProperties residenceQuery={residenceQuery} />
      ),
    },
  ], [t, location, isEditing, residenceQuery]);

  const currentTab = useMemo(() => {
    const hash = location.hash.replace('#', '');
    const tab = tabs.find((cur) => cur.hash === hash && cur.show);
    return tab || tabs[0];
  }, [tabs, location]);

  return (
    <div>
      <div className={styles.heading}>
        <div className={styles.title}>
          <h1>{isEditing ? t('residence.crud.editTitle') : t('residence.crud.addTitle')}</h1>
          {isEditing && residenceQuery?.data && (
            <ul className={styles.tabList}>
                {tabs.filter((tab) => tab.show).map((tab) => {
                  const isActive = tab.hash === currentTab.hash;

                  return (
                    <li className={utils.cn([styles.tabItem, isActive ? styles.active : null])} key={tab.hash}>
                      <Link to={`#${tab.hash}`}>{tab.label}</Link>
                      {isActive && tab.activePicto}
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
      <div className={styles.content}>
        {currentTab.component}
      </div>
    </div>
  );
}

ResidenceEdit.propTypes = {
  isEditing: PropTypes.bool.isRequired,
};

export default ResidenceEdit;
