/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useMemo, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, Link } from 'react-router-dom';

// Components
import {
  utils,
  Button,
  Message,
  UnlockerLoader,
  Picto,
  Table,
  Tag,
} from 'ui-library-unlocker';
import Tooltip from '../../../atoms/Tooltip/Tooltip';
import TableOptions from '../../../atoms/TableOptions/TableOptions';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import PropertyToResidenceModal from './PropertyToResidenceModal';

// Services
import {
  updateResidence,
} from '../../../../services/residence';

// Hooks
import useManagementRights from '../../../../hooks/useManagementRights';

// Utils
import { showModal, hideModal, checkIsModalOpen } from '../../../../utils/modal';
import { getStatusStyles } from '../../../../utils/properties';
import { formatMoney } from '../../../../utils/html';

// Styles
import styles from './ResidenceForm.module.scss';

// Constants
const ADD_PROPERY_MODAL_ID = 'add-property-modal';
const CONFIRM_DELETE_MODAL_ID = 'confirm-delete-modal';

function ResidenceProperties({ residenceQuery }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const match = useMatch('/residence/:id');

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);

  const modalRef = useRef();

  const {
    data: residenceData,
    isFetched: residenceIsFetched,
    error: residenceError,
    refetch: residenceRefetch,
  } = residenceQuery || {};

  const {
    properties = [],
    managerUid,
    ownerUid,
  } = useMemo(() => residenceData?.data || {}, [residenceData]);

  const userHasManagementRights = useManagementRights(managerUid);

  const residenceMutation = useMutation({
    mutationFn: (data) => updateResidence(match?.params?.id, data),
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          residenceRefetch();
          utils.toast.success(t('residence.tabs.properties.formSuccess'));
          if (checkIsModalOpen(ADD_PROPERY_MODAL_ID)) {
            hideModal(ADD_PROPERY_MODAL_ID);
          }
          if (checkIsModalOpen(CONFIRM_DELETE_MODAL_ID)) {
            hideModal(CONFIRM_DELETE_MODAL_ID);
          }
          modalRef?.current?.refetchProperties?.();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
  });

  const showDeleteConfirmModal = useCallback((uid) => {
    setItemSelected(uid);
    showModal(CONFIRM_DELETE_MODAL_ID);
  }, []);

  const getFloorLabel = useCallback((floor) => {
    if (floor === 0) return t('property.groundFloor');
    if (floor === null || typeof floor === 'undefined') return t('global.form.unknown');
    return floor;
  }, [t]);

  const columns = useMemo(() => ([
    {
      header: t('property.table.columns.id'),
      accessorKey: 'unlockerID',
      size: 125,
    },
    {
      header: t('property.table.columns.name'),
      accessorKey: 'name',
      minSize: 200,
      cell: ({ row }) => {
        const { address, name } = row?.original || {};
        return (
          <div className={styles.nameAddress}>
            <Link to={`/property/${row.original.uid}`} className={styles.propertyName}>
              <p className="p-2-700">{name}</p>
              <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
            </Link>
            <div className={styles.address}>
              <span>
                {`${address?.streetNumber || ''} ${address?.street || ''}`}
              </span>
              <span>
                {`, ${address?.zipCode || ''} ${address?.city || ''}`}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      header: t('property.table.columns.type'),
      accessorKey: 'type',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { type } } }) => (
        <div>
          {t(`property.table.type.${type}`)}
        </div>
      ),
    },
    {
      header: t('property.table.columns.tantiemes'),
      accessorKey: 'tantiemes',
      size: 1,
      cell: ({ row: { original: { tantiemes } } }) => (
        <span>
          {tantiemes || t('global.form.unknown')}
        </span>
      ),
    },
    {
      header: t('property.table.columns.floor'),
      accessorKey: 'floor',
      size: 1,
      cell: ({ row: { original: { floor } } }) => (
        <span>
          {getFloorLabel(floor)}
        </span>
      ),
    },
    {
      header: t('property.table.columns.rent'),
      accessorKey: 'rentExcludingCharges',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { rentExcludingCharges } } }) => (
        <div className={styles.price}>
          {formatMoney(utils.centsToEuro(rentExcludingCharges))}
        </div>
      ),
    },
    {
      header: t('property.table.columns.status'),
      accessorKey: 'status',
      size: 50,
      enableSorting: false,
      cell: ({ row: { original: { status } } }) => {
        const { variant, icon } = getStatusStyles(status);

        return (
          <div className={styles.tag}>
            <Tag
              label={t(`property.table.status.${status}`)}
              variant={variant}
              icon={icon}
              size="medium"
            />
          </div>
        );
      },
    },
    {
      header: t('property.table.columns.visibility'),
      accessorKey: 'visibility',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { visibility } } }) => {
        const { variant, icon } = getStatusStyles(visibility);

        return (
          <div className={styles.tag}>
            <Tag
              label={t(`property.table.visibility.${visibility}`)}
              variant={variant}
              icon={icon}
              size="medium"
            />
          </div>
        );
      },
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({ row }) => (
        <div className={styles.seeMore}>
          <Picto
            id={`more-option-property-${row?.original?.uid}`}
            icon="more"
            width={24}
            onClick={(e) => {
              e.stopPropagation();
              if (tooltipOpen === row?.original?.uid) setTooltipOpen(null);
              else setTooltipOpen(row?.original?.uid);
            }}
            color="var(--color-secondary)"
          />
          <Tooltip
            isOpen={tooltipOpen === row?.original?.uid}
            anchorId={`more-option-property-${row?.original?.uid}`}
            place="bottom"
            type="dark"
            effect="solid"
          >
            <TableOptions
              options={[
                {
                  id: 'edit',
                  label: t('global.listOptions.manage'),
                  icon: <Picto icon="edit" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="edit" width={24} color="var(--color-white)" />,
                  onClick: (e) => {
                    e.stopPropagation();
                    setTooltipOpen(null);
                    navigate(`/property/${row?.original?.uid}`);
                  },
                },
                userHasManagementRights ? {
                  id: 'delete',
                  label: t('residence.crud.properties.delete'),
                  icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
                  onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
                  onClick: () => {
                    showDeleteConfirmModal(row?.original?.uid);
                  },
                } : null,
              ].filter(Boolean)}
            />
          </Tooltip>
        </div>
      ),
    },
  ]), [t, tooltipOpen, navigate, showDeleteConfirmModal, userHasManagementRights, getFloorLabel]);

  const cancelDelete = useCallback(() => hideModal(CONFIRM_DELETE_MODAL_ID), []);

  const confirmDelete = useCallback(() => residenceMutation.mutate({
    ...(residenceData?.data || {}),
    properties: properties
      .filter((property) => property.uid !== itemSelected)
      .map((property) => property.uid),
  }), [residenceMutation, itemSelected]);

  const cancelAddProperty = useCallback(() => hideModal(ADD_PROPERY_MODAL_ID), []);

  const addProperty = useCallback((data) => {
    if (data?.properties?.length) {
      const residence = residenceData?.data || {};
      residenceMutation.mutate({
        ...residence,
        properties: [
          ...(residence?.properties?.map((property) => property.uid) || []),
          ...data.properties.map((property) => property.value),
        ],
      });
    }
  }, [residenceMutation, residenceData]);

  if (residenceError) {
    return (
      <Message
        variant="error"
        content={t('global.form.errors.global')}
      />
    );
  }

  if (!residenceIsFetched) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className={styles.wrapper} onClick={() => setTooltipOpen(null)}>
      <div className={styles.propertiesList}>
        {userHasManagementRights && (
          <Button
            className={utils.cn([
              'm-b-30',
              'float-right',
            ])}
            variant="primary"
            icon="plus"
            iconSide="left"
            onClick={() => {
              showModal(ADD_PROPERY_MODAL_ID);
            }}
            size="large"
            label={t('residence.tabs.properties.addBtn')}
          />
        )}
        <Table
          fullWidth
          columns={columns}
          data={properties}
        />
      </div>
      <ConfirmationModal
        id={CONFIRM_DELETE_MODAL_ID}
        onSubmit={confirmDelete}
        onCancel={cancelDelete}
        title={t('residence.crud.properties.delete')}
        size="small"
        loading={residenceMutation.isLoading}
      />
      <PropertyToResidenceModal
        id={ADD_PROPERY_MODAL_ID}
        ref={modalRef}
        onSubmit={addProperty}
        onCancel={cancelAddProperty}
        loading={residenceMutation.isLoading}
        managerUid={managerUid}
        ownerUid={ownerUid}
        associatedProperties={properties}
        residentialComplexUid={match?.params?.id}
      />
    </div>
  );
}

ResidenceProperties.propTypes = {
  residenceQuery: PropTypes.shape().isRequired,
};

export default ResidenceProperties;
