/* eslint-disable max-len */
import * as yup from 'yup';

import addressSchema from './addressSchema';

// MORAL
// Forme juridique -> ?
// Nom commercial -> ?
// Date d'enregistrement -> ?
// Lieu d'enregistrement -> ?
// Adresse siège -> ?
// Indication déclarative du chiffre d’affaires de l’entreprise -> ?
// Résidence fiscale -> ?

// const allLegalCategories = [
//   { label: 'ORGANISME_DE_PLACEMENT_COLLECTIF_EN_VALEURS_MOBILIERES_SANS_PERSONNALITE_MORALE', value: '00' },
//   { label: 'ENTREPRENEUR_INDIVIDUEL', value: '10' },
//   { label: 'INDIVISION', value: '21' },
//   { label: 'SOCIETE_CREEE_DE_FAIT', value: '22' },
//   { label: 'SOCIETE_EN_PARTICIPATION', value: '23' },
//   { label: 'FIDUCIE', value: '24' },
//   { label: 'PAROISSE_HORS_ZONE_CONCORDATAIRE', value: '27' },
//   { label: 'AUTRE_GROUPEMENT_DE_DROIT_PRIVE_NON_DOTE_DE_LA_PERSONNALITE_MORALE', value: '29' },
//   { label: 'PERSONNE_MORALE_DE_DROIT_ETRANGER_IMMATRICULEE_AU_RCS_REGISTRE_DU_COMMERCE_ET_DES_SOCIETES', value: '31' },
//   { label: 'PERSONNE_MORALE_DE_DROIT_ETRANGER_NON_IMMATRICULEE_AU_RCS', value: '32' },
//   { label: 'ETABLISSEMENT_PUBLIC_OU_REGIE_A_CARACTERE_INDUSTRIEL_OU_COMMERCIAL', value: '41' },
//   { label: 'SOCIETE_COOPERATIVE_COMMERCIALE_PARTICULIERE', value: '51' },
//   { label: 'SOCIETE_EN_NOM_COLLECTIF', value: '52' },
//   { label: 'SOCIETE_EN_COMMANDITE', value: '53' },
//   { label: 'SOCIETE_A_RESPONSABILITE_LIMITEE_SARL', value: '54' },
//   { label: 'SOCIETE_A_NONYME_A_CONSEIL_D_ADMINISTRATION', value: '55' },
//   { label: 'SOCIETE_A_NONYME_A_DIRECTOIRE', value: '56' },
//   { label: 'SOCIETE_PAR_ACTIONS_SIMPLIFIEE', value: '57' },
//   { label: 'SOCIETE_EUROPEENNE', value: '58' },
//   { label: 'CAISSE_D_EPARGNE_ET_DE_PREVOYANCE', value: '61' },
//   { label: 'GROUPEMENT_D_INTERET_ECONOMIQUE', value: '62' },
//   { label: 'SOCIETE_COOPERATIVE_AGRICOLE', value: '63' },
//   { label: 'SOCIETE_D_ASSURANCE_MUTUELLE', value: '64' },
//   { label: 'SOCIETE_CIVILE', value: '65' },
//   { label: 'AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE_INSCRITE_AU_REGISTRE_DU_COMMERCE_ET_DES_SOCIETES', value: '69' },
//   { label: 'ADMINISTRATION_DE_L_ETAT', value: '71' },
//   { label: 'COLLECTIVITE_TERRITORIALE', value: '72' },
//   { label: 'ETABLISSEMENT_PUBLIC_ADMINISTRATIF', value: '73' },
//   { label: 'AUTRE_PERSONNE_MORALE_DE_DROIT_PUBLIC_ADMINISTRATIF', value: '74' },
//   { label: 'ORGANISME_GERANT_UN_REGIME_DE_PROTECTION_SOCIALE_A_ADHESION_OBLIGATOIRE', value: '81' },
//   { label: 'ORGANISME_MUTUALISTE', value: '82' },
//   { label: 'COMITE_D_ENTREPRISE', value: '83' },
//   { label: 'ORGANISME_PROFESSIONNEL', value: '84' },
//   { label: 'ORGANISME_DE_RETRAITE_A_ADHESION_NON_OBLIGATOIRE', value: '85' },
//   { label: 'SYNDICAT_DE_PROPRIETAIRES', value: '91' },
//   { label: 'ASSOCIATION_LOI_1901_OU_ASSIMILE', value: '92' },
//   { label: 'FONDATION', value: '93' },
//   { label: 'AUTRE_PERSONNE_MORALE_DE_DROIT_PRIVE', value: '99' },
// ];

export const legalCategories = [
  { label: 'Société à responsabilité limitée (SARL)', value: '54' },
  { label: 'Société par actions simplifiée (SAS)', value: '543' },
  { label: 'Société civile immobilière (SCI)', value: '65' },
  { label: 'Entreprise unipersonnelle à responsabilité limitée (EURL)', value: '542' },
  { label: 'Société par actions simplifiée unipersonnelle (SASU)', value: '541' },
  { label: 'Association loi 1901 (ou assimilé)', value: '92' },
];

export const companyDetailsInitialValues = {
  isRealEstateAgency: false, // Agence immobilière
  address: null, // Adresse
  legalName: '', // Raison sociale
  vatNumber: '', // N° de TVA
  taxCountry: 'FR', // Pays de résidence fiscale
  brandName: '', // Nom commercial
  registrationDate: null, // Date d'enregistrement
  registrationPlace: '', // Lieu d'enregistrement
  registrationCountry: 'FR', // Pays d'enregistrement
  idNumber: '', // N° d'identification
  annualTurnover: '', // Chiffre d'affaires annuel
  taxZipCode: '', // Code postal de résidence fiscale
  taxCity: '', // Ville de résidence fiscale
  legalCategory: null, // Catégorie juridique
  iban: '', // IBAN
  rcsRegisterLocation: '', // Lieu d'immatriculation au RCS
  proCardNumber: '', // N° de carte pro
  warrantyFund: '', // Garantie financière
  proCivilLiability: '', // Responsabilité civile pro
  ownerUid: null,
};

export const companyDetailsSchema = yup.object().shape({
  isRealEstateAgency: yup.boolean().required('global.form.errors.required'),
  address: addressSchema.required('global.form.errors.required').nullable(),
  legalName: yup.string().nullable().required('global.form.errors.required'),
  vatNumber: yup.string().nullable().required('global.form.errors.required'),
  taxCountry: yup.string().required('global.form.errors.required'),
  brandName: yup.string().required('global.form.errors.required'),
  registrationDate: yup.date().required('global.form.errors.required'),
  registrationPlace: yup.string().required('global.form.errors.required'),
  registrationCountry: yup.string().required('global.form.errors.required'),
  idNumber: yup
    .string()
    .length(14, 'profile.companyDetails.formErrors.idNumberLength')
    .required('global.form.errors.required'),
  annualTurnover: yup.string().required('global.form.errors.required'),
  taxZipCode: yup.string().required('global.form.errors.required'),
  taxCity: yup.string().required('global.form.errors.required'),
  legalCategory: yup.string()
    .oneOf(legalCategories.map((category) => category.value), 'global.form.errors.invalid')
    .required('global.form.errors.required')
    .nullable(),
  iban: yup.string()
    .length(27, 'global.form.errors.iban')
    .test({
      message: 'global.form.errors.ibanFormat',
      test: function test(value) {
        return value?.length > 0 ? value.startsWith('FR') : true;
      },
    })
    .required('global.form.errors.required')
    .nullable(),
  rcsRegisterLocation: yup
    .string()
    .when('isRealEstateAgency', {
      is: false,
      then: yup.string().strip(),
      otherwise: yup.string().required('global.form.errors.required'),
    }),
  proCardNumber: yup
    .string()
    .when('isRealEstateAgency', {
      is: false,
      then: yup.string().strip(),
      otherwise: yup.string().required('global.form.errors.required'),
    }),
  warrantyFund: yup
    .string()
    .when('isRealEstateAgency', {
      is: false,
      then: yup.string().strip(),
      otherwise: yup.string().required('global.form.errors.required'),
    }),
  proCivilLiability: yup
    .string()
    .when('isRealEstateAgency', {
      is: false,
      then: yup.string().strip(),
      otherwise: yup.string().required('global.form.errors.required'),
    }),
  ownerUid: yup.string().required('global.form.errors.required').nullable(),
});
