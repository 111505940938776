import React, {
  useMemo, useEffect, useCallback, useState,
} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';

// Components
import {
  utils,
  UnlockerLoader,
  Picto,
  Message,
} from 'ui-library-unlocker';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import ConfirmationModal from '../../components/organisms/ConfirmationModal/ConfirmationModal';
import ErrorView from '../500/500';

// Services
import { getSettings, updateSettings } from '../../services/settings';

// Utils
import { validationSchema } from '../../utils/forms/settingsSchema';
import { showModal, hideModal, checkIsModalOpen } from '../../utils/modal';

// Hooks
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useResponsive from '../../hooks/useResponsive';

// Styles
import styles from './Settings.module.scss';

// Constants
const CONFIRM_MODAL_ID = 'confirm-lease-impact';

function SettingsView() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isMobile } = useResponsive();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useDocumentTitle(t('settings.browserTitle'));

  const {
    data: settingsData,
    isFetching: settingsFetching,
    refetch: refetchSettings,
    isError: settingsError,
  } = useQuery({
    queryKey: ['settings'],
    queryFn: getSettings,
    keepPreviousData: false,
  });

  const updateSettingsMutation = useMutation({
    mutationFn: updateSettings,
    onSuccess: () => {
      utils.toast.success(t('settings.success'));
      refetchSettings();
    },
    onError: () => {
      utils.toast.error(t('global.form.errors.generic'));
    },
    onSettled: () => {
      formik.setSubmitting(false);
      if (checkIsModalOpen(CONFIRM_MODAL_ID)) {
        hideModal(CONFIRM_MODAL_ID);
      }
    },
  });

  const initialValues = useMemo(() => settingsData?.data || {}, [settingsData]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (checkIfNeedConfirmation([
        'daysBeforePaymentReminder',
        'lessorPayoutFrequency',
        'lessorPayoutDay',
        'feesPayoutFrequency',
        'feesPayoutDay',
      ])) {
        showModal(CONFIRM_MODAL_ID);
      } else {
        updateSettingsMutation.mutate(values);
      }
    },
  });

  const checkIfNeedConfirmation = useCallback((keys) => keys.some((key) => (
    initialValues.payment[key] !== formik.values.payment[key]
  )), [formik.values, initialValues]);

  useEffect(() => {
    formik?.resetForm();
  }, [pathname]);

  const handleCancel = useCallback(() => {
    hideModal(CONFIRM_MODAL_ID);
    formik.setSubmitting(false);
  }, []);

  const handleConfirm = useCallback(() => updateSettingsMutation.mutate(formik.values), [updateSettingsMutation]);

  const onMenuToggle = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  if (settingsError) return <ErrorView />;

  return (
    <div className={styles.container}>
      <SettingsMenu
        isOpen={isMenuOpen}
        onMenuToggle={onMenuToggle}
      />
      <div className={styles.content}>
        {settingsFetching ? (
          <UnlockerLoader size={200} align="left" />
        ) : (
          <>
            <div className={styles.title}>
              {isMobile && (
                <Picto
                  onClick={onMenuToggle}
                  className="m-r-10"
                  color="var(--color-primary-500)"
                  icon={isMenuOpen ? 'close' : 'menu'}
                  width={24}
                />
              )}
              <Picto
                icon="setting-2"
                width={16}
                color="var(--color-primary-500)"
              />
              <span>{t('settings.title')}</span>
            </div>
            <Outlet
              context={{
                formik,
                initialValues,
                isMenuOpen,
              }}
            />
          </>
        )}
      </div>
      <ConfirmationModal
        id={CONFIRM_MODAL_ID}
        onSubmit={handleConfirm}
        onCancel={handleCancel}
        title={t('settings.payments.impactLease')}
        description={(
          <Message
            variant="info"
            className={styles.confirmModalInfo}
            content={t('settings.payments.impactLeaseDescription')}
          />
        )}
        loading={updateSettingsMutation.isLoading}
      />
    </div>
  );
}

SettingsView.propTypes = {};

export default SettingsView;
