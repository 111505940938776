import i18n from 'i18next';
import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

const timestamp = new Date().getTime();

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    fallbackLng: 'fr',
    // debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?${timestamp}`,
    },
  });

export default i18n;
