export const routeList = [
  {
    label: 'settings',
    element: 'SettingsView',
    routeProps: {
      path: '/settings',
    },
    nestedRoutes: [
      {
        label: 'index',
        element: 'RedirectSettingsAccount',
        routeProps: {
          index: true,
        },
      },
      {
        label: 'account',
        element: 'SettingsAccount',
        icon: 'profile-circle',
        routeProps: {
          path: 'account',
        },
      },
      {
        label: 'notifications',
        element: 'SettingsNotifications',
        icon: 'notification',
        routeProps: {
          path: 'notifications',
        },
        killswitch: 'settingsNotifications',
      },
      {
        label: 'payments',
        element: 'SettingsPayments',
        icon: 'receipt-2',
        routeProps: {
          path: 'payments',
        },
        killswitch: 'settingsPayments',
        hideForTenant: true,
      },
      {
        label: 'owners',
        element: 'SettingsOwners',
        icon: 'key',
        routeProps: {
          path: 'owners',
        },
        killswitch: 'settingsOwners',
        hideForLessor: true,
        showForManager: true,
      },
    ],
  },
];
