import * as yup from 'yup';

import {
  SETTINGS_PAYMENT_DEPOSIT_LOCATIONS,
  SETTINGS_PAYOUT_FREQUENCIES,
  SETTINGS_DAYS_BEFORE_PAYMENT_REMINDER,
} from '../constants';

export const leaseDetailsSettingsInitialValues = {
  daysBeforePaymentReminder: 7,
  depositLocation: SETTINGS_PAYMENT_DEPOSIT_LOCATIONS.UNLOCKER_ESCROW,
  lessorPayoutFrequency: SETTINGS_PAYOUT_FREQUENCIES.MONTHLY,
  lessorPayoutDay: 5,
  feesPayoutFrequency: SETTINGS_PAYOUT_FREQUENCIES.MONTHLY,
  feesPayoutDay: 5,
};

export const leaseDetailsSettingsValidationSchema = yup.object().shape({
  daysBeforePaymentReminder: yup.number()
    .oneOf(SETTINGS_DAYS_BEFORE_PAYMENT_REMINDER, 'global.form.errors.invalid')
    .nullable()
    .required('global.form.errors.required'),
  // depositLocation: yup.string()
  //   .oneOf([
  //     SETTINGS_PAYMENT_DEPOSIT_LOCATIONS.UNLOCKER_ESCROW,
  //     SETTINGS_PAYMENT_DEPOSIT_LOCATIONS.MANAGER_ACCOUNT,
  //   ], 'global.form.errors.invalid')
  //   .nullable()
  //   .required('global.form.errors.required'),
  lessorPayoutFrequency: yup.string()
    .oneOf([
      SETTINGS_PAYOUT_FREQUENCIES.AUTO,
      SETTINGS_PAYOUT_FREQUENCIES.MONTHLY,
    ], 'global.form.errors.invalid')
    .nullable()
    .required('global.form.errors.required'),
  lessorPayoutDay: yup.number()
    .min(1, 'global.form.errors.number')
    .max(28, 'global.form.errors.maxValue')
    .nullable()
    .when('lessorPayoutFrequency', {
      is: SETTINGS_PAYOUT_FREQUENCIES.MONTHLY,
      then: (schema) => schema.required('global.form.errors.required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  feesPayoutFrequency: yup.string()
    .oneOf([
      SETTINGS_PAYOUT_FREQUENCIES.AUTO,
      SETTINGS_PAYOUT_FREQUENCIES.MONTHLY,
    ], 'global.form.errors.invalid')
    .nullable()
    .required('global.form.errors.required'),
  feesPayoutDay: yup.number()
    .min(1, 'global.form.errors.number')
    .max(28, 'global.form.errors.maxValue')
    .nullable()
    .when('feesPayoutFrequency', {
      is: SETTINGS_PAYOUT_FREQUENCIES.MONTHLY,
      then: (schema) => schema.required('global.form.errors.required'),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export const validationSchema = yup.object().shape({
  notification: yup.object().shape({
    isInAppNotificationEnabled: yup.boolean().required('global.form.errors.required'),
    isEmailNotificationEnabled: yup.boolean().required('global.form.errors.required'),
    isSmsNotificationEnabled: yup.boolean().required('global.form.errors.required'),
  }),
  payment: yup.object().shape({
    ...leaseDetailsSettingsValidationSchema.fields,
  }),
  propertyOwner: yup.object().shape({
    sendInvitationToDelegatedPropertyOwner: yup.boolean().required('global.form.errors.required').nullable(),
  }),
});
