/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

// Components
import {
  Button,
  utils,
  CompletionDashboard,
} from 'ui-library-unlocker';
import Modal from '../../molecules/Modal/Modal';

// Hooks
import useProfileCompletion from '../../../hooks/useProfileCompletion';
import useRoles from '../../../hooks/useRoles';
import useProfile from '../../../hooks/useProfile';
import { useAppContext } from '../../../store/context';
import useResize from '../../../hooks/useResize';

// Services
import { getProfileCompletion } from '../../../services/profile';
import { requestBankingEnrolment } from '../../../services/person';
import { getOwners } from '../../../services/owner';
import { getProperties, getLotOnboarding } from '../../../services/property';
import { getTenantsNoFilter } from '../../../services/tenant';

// Utils
import { showModal, hideModal } from '../../../utils/modal';
import { formatProfileCompletion } from './profileCompletion';
import { formatCompaniesCompletion, getCompanyPercent, getOwnersPercent } from './companyCompletion';
import { formatLotCompletion, getLotPercent } from './lotCompletion';
import { formatTenantsCompletion, getTenantsPercent } from './tenantCompletion';

// Constants
import { ENROLMENT_STATUS, RENT_DISTRIBUTION_STATUS } from '../../../utils/constants';

const COMPLETION_LIST_WIDTH = 'calc(25% - 20px*3/4)';

function DashboardBasics({
  handleValidateOnboardingUserStatus = () => {},
  validating = false,
  className = '',
}) {
  const { t } = useTranslation();
  const { context: { user }, refetchMe } = useAppContext();
  const {
    onboardingStatus,
    isUserCompletionLevel2,
    isUserCompletionLevel3,
  } = useProfileCompletion();
  const { profile } = useProfile();
  const { isUserRealEstateManager } = useRoles();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useResize(handleResize);

  const needCompany = useMemo(() => [
    RENT_DISTRIBUTION_STATUS.WITH_BOTH_PERSONAL_AND_COMPANIES_ACCOUNT,
    RENT_DISTRIBUTION_STATUS.ONLY_WITH_COMPANIES_ACCOUNT,
    RENT_DISTRIBUTION_STATUS.REAL_ESTATE_AGENCY_WITH_NO_PROPERTIES,
  ].includes(profile?.rentDistribution), [profile?.rentDistribution]);

  // profile completion rates
  const {
    data: profileCompletion,
    refetch: profileCompletionRefetch,
    isFetched: profileFetched,
  } = useQuery({
    queryKey: ['profile-completion'],
    queryFn: () => getProfileCompletion(),
  });

  // owners
  const {
    data: ownersData,
    isFetched: ownersFetched,
  } = useQuery({
    queryKey: ['owners-completion'],
    queryFn: () => getOwners({}),
    enabled: !!isUserRealEstateManager,
  });

  // properties
  const {
    data: propertiesData,
    isFetched: propertiesFetched,
  } = useQuery({
    queryKey: ['properties-completion'],
    queryFn: () => getProperties({}),
  });

  const {
    data: propertyOnboarding,
  } = useQuery({
    queryKey: ['property-onboarding'],
    queryFn: () => getLotOnboarding(),
  });

  // tenant
  const {
    data: tenantsData,
    isFetched: tenantsFetched,
  } = useQuery({
    queryKey: ['tenants-completion'],
    queryFn: () => getTenantsNoFilter({}),
  });

  const calculateHeight = useMemo(() => {
    const { documents = {}, companyDocuments = {} } = profileCompletion?.data ?? {};
    const { length } = Object.values(isUserRealEstateManager || needCompany ? companyDocuments : documents);
    return Math.max(123 + (30 * length), 207);
  }, [profileCompletion, isUserRealEstateManager, needCompany]);

  // data for component
  const completionData = () => {
    const {
      completionPercentage = 0,
      personnalInformations,
      documents,
      enrolmentComplete,
      companyDocuments,
      companyValidated,
      companyInformations,
    } = profileCompletion?.data ?? {};

    // --- PERCENTS ---
    // owners
    let ownersPercent = isUserRealEstateManager ? 0 : 100;
    const { totalNumberOfItems: ownersCount, collection: ownersList } = ownersData?.data ?? {};
    if (isUserRealEstateManager) {
      ownersPercent = getOwnersPercent({
        ownersCount,
        ownersList,
      });
    }
    // company
    let companyPercent = isUserRealEstateManager || needCompany ? 0 : 100;
    if (isUserRealEstateManager || needCompany) {
      companyPercent = getCompanyPercent({
        companyInformations,
        isUserRealEstateManager,
        ownersPercent,
        documents: companyDocuments,
        validated: companyValidated,
      });
    }
    // lot
    const { totalNumberOfItems: propertiesCount } = propertiesData?.data ?? {};
    const {
      hasPictures,
      hasPropertyDeed,
      hasPropertyTax,
      hasGES,
      hasDPE,
    } = propertyOnboarding?.data ?? {};
    const lotPercent = getLotPercent({
      propertiesCount,
      hasPictures,
      hasPropertyDeed,
      hasPropertyTax,
      hasGES,
      hasDPE,
    });
    // tenants
    const { totalNumberOfItems: tenantsCount, collection: tenantsList } = tenantsData?.data ?? {};
    const tenantsPercent = getTenantsPercent({
      tenantsCount,
      tenantsList,
    });

    // --- DATA ---
    const completion = [];
    if (
      completionPercentage === 100
      && companyPercent === 100
      && lotPercent === 100
      && tenantsPercent === 100
    ) {
      if (!validating) handleValidateOnboardingUserStatus();
      return completion;
    }
    // profile
    completion.push(formatProfileCompletion({
      completionPercentage,
      personnalInformations,
      documents,
      enrolmentComplete,
      onboardingStatus,
      t,
      navigate,
      isUserCompletionLevel2,
      isUserCompletionLevel3,
      showModal,
      COMPLETION_LIST_WIDTH,
    }));
    // company
    if (isUserRealEstateManager || needCompany) {
      completion.push(formatCompaniesCompletion({
        completionPercentage,
        companyPercent,
        ownersPercent,
        t,
        navigate,
        COMPLETION_LIST_WIDTH,
        isUserRealEstateManager,
        documents: companyDocuments,
        validated: companyValidated,
      }));
    }
    // lot
    completion.push(formatLotCompletion({
      prevPercentage: isUserRealEstateManager || needCompany ? companyPercent : completionPercentage,
      lotPercent,
      t,
      navigate,
      COMPLETION_LIST_WIDTH,
      index: isUserRealEstateManager || needCompany ? 3 : 2,
      hasPictures,
      hasPropertyDeed,
      hasPropertyTax,
      hasGES,
      hasDPE,
    }));
    // tenants
    completion.push(formatTenantsCompletion({
      prevPercentage: lotPercent,
      tenantsPercent,
      t,
      navigate,
      showModal,
      COMPLETION_LIST_WIDTH,
      index: isUserRealEstateManager || needCompany ? 4 : 3,
    }));

    return completion;
  };

  const enrolmentMutation = useMutation({
    mutationFn: () => requestBankingEnrolment(user?.username),
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('profile.paymentAccount.success'));
          profileCompletionRefetch();
          refetchMe();
          hideModal('document-validation-completion-modal');
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('profile.paymentAccount.errorMissingDocuments'));
            break;
          }
          case 409: {
            utils.toast.error(t('profile.paymentAccount.errorInProgress'));
            break;
          }
          default:
            break;
        }
        hideModal('document-validation-completion-modal');
      }
    },
  });

  const canSendValidationRequest = useMemo(() => {
    if (!onboardingStatus) return false;
    return [
      ENROLMENT_STATUS.PROFILE_DONE,
      ENROLMENT_STATUS.ENROLMENT_CHALLENGED,
      ENROLMENT_STATUS.ENROLMENT_DENIED,
    ].includes(onboardingStatus);
  }, [onboardingStatus]);

  const generateValidationModal = () => {
    // can't validate if no documents
    if (!profileCompletion?.data?.documents) {
      return (
        <Modal
          id="document-validation-completion-modal"
          title={t('dashboard.completion.profile.step4.modal_locked')}
          size="large"
          onClose={() => hideModal('document-validation-completion-modal')}
        >
          <p>
            {t('dashboard.completion.profile.step3.level1')}
            <br />
            {t('dashboard.completion.profile.step3.level2')}
            <br />
            {t('dashboard.completion.profile.step3.level3')}
          </p>

          <Button
            className="m-t-30 center-block"
            size="large"
            onClick={() => navigate({ pathname: '/profile', hash: '#documents' })}
          >
            {t('dashboard.completion.profile.step4.doc_btn')}
          </Button>

          <Link className="center-block m-t-30" to="/messages" target="_blank">
            {t('dashboard.completion.profile.step4.help')}
          </Link>
        </Modal>
      );
    }

    // can't validate if already sent
    if (canSendValidationRequest) {
      return (
        <Modal
          id="document-validation-completion-modal"
          title={t('dashboard.completion.profile.step4.already_sent')}
          size="large"
          onClose={() => hideModal('document-validation-completion-modal')}
        >
          <Button
            className="m-t-40 center-block"
            size="large"
            onClick={() => hideModal('document-validation-completion-modal')}
          >
            {t('global.goBack')}
          </Button>
        </Modal>
      );
    }

    // can validate
    return (
      <Modal
        id="document-validation-completion-modal"
        title={t('profile.documents.confirmSendValidation')}
        size="large"
        onClose={() => hideModal('document-validation-completion-modal')}
      >
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={enrolmentMutation?.isLoading}
          onClick={() => {
            enrolmentMutation?.mutate();
          }}
        >
          {t('global.validate')}
        </Button>
      </Modal>
    );
  };

  return (
    <div className={className}>

      {completionData().length > 0 && (
        <CompletionDashboard
          items={completionData()}
          title={t('dashboard.completion.title')}
          minHeight={calculateHeight}
          loading={!(
            profileFetched
            && (ownersFetched || !isUserRealEstateManager)
            && propertiesFetched
            && tenantsFetched
          )}
          windowWidth={windowWidth}
        />
      )}

      {generateValidationModal()}

      <Modal
        id="tenant-invited-wait-modal"
        title={t('dashboard.completion.tenant.step2.modalTitle')}
        size="large"
        onClose={() => {
          hideModal('tenant-invited-wait-modal');
        }}
      >
        <Button
          className="m-t-40 center-block"
          size="large"
          onClick={() => {
            hideModal('tenant-invited-wait-modal');
          }}
        >
          {t('global.goBack')}
        </Button>
      </Modal>

    </div>
  );
}

DashboardBasics.propTypes = {
  handleValidateOnboardingUserStatus: PropTypes.func,
  validating: PropTypes.bool,
  className: PropTypes.string,
};

export default DashboardBasics;
