import { useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

// Components
import {
  Button, RadioGroup, utils, Message,
} from 'ui-library-unlocker';
import Modal from '../../../molecules/Modal/Modal';

// Styles
import styles from './ResidenceForm.module.scss';

function DeleteResidenceModal({
  id,
  onSubmit,
  onCancel,
  loading,
  name,
}) {
  const { t } = useTranslation();
  const [shouldDeleteProperties, setShouldDeleteProperties] = useState(false);

  const options = useMemo(() => [
    {
      label: t('residence.crud.deleteResidence.checkboxNoProperties'),
      value: false,
    },
    {
      label: t('residence.crud.deleteResidence.checkboxWithProperties'),
      value: true,
    },
  ], [t]);

  const handleSubmit = useCallback(() => {
    onSubmit(shouldDeleteProperties);
  }, [onSubmit, shouldDeleteProperties]);

  return createPortal(
    <Modal
      id={id}
      onClose={onCancel}
      title={t('residence.crud.deleteResidence.title', { name })}
    >
      <div>
        <p>
          <Trans
            i18nKey="residence.crud.deleteResidence.description"
          />
        </p>
        <RadioGroup
          name="shouldDeleteProperties"
          className={utils.cn([styles.radioGroupResidenceDelete, 'm-t-25'])}
          options={options}
          value={shouldDeleteProperties}
          onChange={setShouldDeleteProperties}
        />
        {shouldDeleteProperties && (
          <Message
            variant="error"
            content={t('residence.crud.deleteResidence.warningMessage')}
            className="m-t-25"
          />
        )}
      </div>
      <div className={utils.cn([
        styles.deleteResidenceButtonContainer,
        'm-t-40',
      ])}
      >
        <Button
          onClick={handleSubmit}
          size="large"
          label={t('global.confirmationModal.confirm')}
          loading={loading}
        />
        <Button
          onClick={onCancel}
          size="large"
          variant="secondary"
          label={t('global.confirmationModal.cancel')}
        />
      </div>
    </Modal>,
    document.querySelector('body'),
  );
}

DeleteResidenceModal.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

DeleteResidenceModal.defaultProps = {
  id: 'impact-lease-modal',
  loading: false,
};

export default DeleteResidenceModal;
