import { useMemo } from 'react';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// Components
import {
  utils,
  TextInput,
  Button,
} from 'ui-library-unlocker';

// Services
import { changeUserEmail, changeUserPassword } from '../../../services/identity';

// Context
import { useAppContext } from '../../../store/context';

// Utils
import {
  emailLoginInfoInitialValues,
  passwordLoginInfoInitialValues,
  emailLoginInfoSchema,
  passwordLoginInfoSchema,
} from '../../../utils/forms/loginInfoSchema';
import { displayError, isFieldValid } from '../../../utils/forms/form';

// Styles
import styles from './SettingsAccount.module.scss';

function EmailForm() {
  const { t } = useTranslation();
  const { context: { me }, refetchMe } = useAppContext();

  const initialEmailValues = useMemo(() => {
    if (me) return me;
    return emailLoginInfoInitialValues;
  }, [me]);

  const emailMutation = useMutation({
    mutationFn: changeUserEmail,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('settings.account.form.emailSuccess'));
          refetchMe();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
  });

  const emailFormik = useFormik({
    initialValues: initialEmailValues,
    validationSchema: emailLoginInfoSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => emailMutation.mutate(values),
  });

  return (
    <form onSubmit={emailFormik.handleSubmit}>
      <h4 className="m-t-30">{t('settings.account.emailTitle')}</h4>
      <TextInput
        type="text"
        id="email"
        name="email"
        className="m-t-25"
        label={t('settings.account.form.email')}
        error={displayError(t, emailFormik, 'email')}
        valid={isFieldValid(emailFormik, 'email')}
        onChange={emailFormik.handleChange}
        onBlur={emailFormik.handleBlur}
        value={emailFormik.values.email}
        autoComplete="off"
      />
      <Button
        type="submit"
        className="m-t-25"
        variant="secondary"
        label={t('global.save')}
        loading={emailMutation.isLoading}
      />
    </form>
  );
}

function PasswordForm() {
  const { t } = useTranslation();

  const passwordMutation = useMutation({
    mutationFn: changeUserPassword,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('settings.account.form.passwordSuccess'));
          passwordFormik.resetForm();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.global'));
          break;
      }
    },
  });

  const passwordFormik = useFormik({
    initialValues: passwordLoginInfoInitialValues,
    validationSchema: passwordLoginInfoSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => passwordMutation.mutate(values),
  });

  return (
    <form className={styles.form} onSubmit={passwordFormik.handleSubmit}>
      <h4 className="m-t-35">{t('settings.account.passwordTitle')}</h4>
      <TextInput
        type="password"
        id="previousPassword"
        name="previousPassword"
        className="m-t-25"
        label={t('settings.account.form.password')}
        error={displayError(t, passwordFormik, 'previousPassword')}
        valid={isFieldValid(passwordFormik, 'previousPassword')}
        onChange={passwordFormik.handleChange}
        onBlur={passwordFormik.handleBlur}
        value={passwordFormik.values.previousPassword}
        autoComplete="new-password"
      />
      <TextInput
        type="password"
        id="proposedPassword"
        name="proposedPassword"
        className="m-t-25"
        label={t('settings.account.form.newPassword')}
        info={t('global.form.info.password')}
        error={displayError(t, passwordFormik, 'proposedPassword')}
        valid={isFieldValid(passwordFormik, 'proposedPassword')}
        onChange={passwordFormik.handleChange}
        onBlur={passwordFormik.handleBlur}
        value={passwordFormik.values.proposedPassword}
        autoComplete="new-password"
      />
      <Button
        type="submit"
        className="m-t-25"
        variant="secondary"
        label={t('global.save')}
        loading={passwordMutation.isLoading}
      />
    </form>
  );
}

function SettingsAccount() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1>{t('settings.account.title')}</h1>
      <EmailForm />
      <PasswordForm />
    </div>
  );
}

export default SettingsAccount;
