import * as yup from 'yup';

export const registerInitialValues = {
  email: '',
  password: '',
  acceptedGTC: false,
  acceptedGDPR: false,
};

const registerSchema = yup.object().shape({
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
  password: yup.string()
    .required('global.form.errors.required')
    .min(8, 'global.form.errors.password')
    .test('isValidPassword', 'global.form.errors.password', (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecialChar = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);

      const conditions = [hasUpperCase, hasNumber, hasSpecialChar];

      return conditions.find((c) => c !== true) === undefined;
    }),
  acceptedGTC: yup.bool().required().oneOf([true], 'register.form.errors.acceptedGTC'),
  acceptedGDPR: yup.bool().required().oneOf([true], 'register.form.errors.acceptedGDPR'),
});

export default registerSchema;
