import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';

// Components
import {
  Button,
  Message,
  utils,
  UnlockerLoader,
} from 'ui-library-unlocker';
import Modal from '../../../components/molecules/Modal/Modal';
import ProfileDocumentsList from '../../../components/organisms/DocumentList/ProfileDocumentsList/ProfileDocumentsList';
import MissingDocuments from '../../../components/molecules/MissingDocuments/MissingDocuments';

// Hooks
import { useAppContext } from '../../../store/context';

// Services
import { requestBankingEnrolment } from '../../../services/person';

// Utils
import { showModal, hideModal } from '../../../utils/modal';
import { checkIfSendProfileValidationRequest } from '../../../utils/user';

// Styles
import styles from './ProfileDocuments.module.scss';

// Constants
import { ENROLMENT_STATUS } from '../../../utils/constants';

const SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID = 'send-validation-request-confirm-modal';

function ProfileDocuments({
  documentQuery = {},
  isReadOnly = false,
  delegatedUser = null,
  userHasDelegation = false,
}) {
  const { t } = useTranslation();
  const { context: { user, me }, refetchMe } = useAppContext();

  const [missingDocuments, setMissingDocuments] = useState(null);

  const {
    data: documentListData,
    isLoading: documentListLoading,
    refetch: refetchDocumentList,
  } = documentQuery || {};

  const onboardingStatus = useMemo(() => (
    userHasDelegation ? delegatedUser?.onboardingStatus : me?.onboardingStatus
  ), [me?.onboardingStatus, userHasDelegation, delegatedUser?.onboardingStatus]);

  const userUid = useMemo(() => (
    userHasDelegation ? delegatedUser?.uid : user?.username
  ), [user?.username, userHasDelegation, delegatedUser?.uid]);

  const rentDistribution = useMemo(() => (
    // if viewing owner details under user delegation, use the delegated user's rent distribution
    // if delegated owner has not defined rent distribution, use null so useDelegation returns false
    // else, use undefined so the hook uses the connected user's rent distribution
    userHasDelegation
      ? (delegatedUser?.rentDistribution || null)
      : undefined
  ), [userHasDelegation, delegatedUser?.rentDistribution]);

  const enrolmentMutation = useMutation({
    mutationFn: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'sent_validation_request',
          entityType: userHasDelegation ? 'owner' : 'profile',
        },
      });
      return requestBankingEnrolment(userUid);
    },
    onSuccess: () => {
      hideModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID);
      utils.toast.success(t('profile.paymentAccount.success'));
      refetchMe();
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          TagManager.dataLayer({
            dataLayer: {
              event: 'incomplete_validation_request',
              entityType: userHasDelegation ? 'owner' : 'profile',
            },
          });
          if (err?.response?.data?.message === 'PERSON_ENROLMENT_MISSING_PROFILE_DATA') {
            utils.toast.error(t('profile.paymentAccount.errorMissingProfileData'));
          }
          if (err?.response?.data?.message === 'PERSON_ENROLMENT_DOCUMENT_MISSING') {
            utils.toast.error(t('profile.paymentAccount.errorMissingDocuments'));
            const { errors } = err?.response?.data || {};
            if (errors) {
              const missing = Object.keys(errors).map((key) => errors[key]);
              setMissingDocuments(missing || null);
            }
          }
          break;
        }
        case 409: {
          utils.toast.error(t('profile.paymentAccount.errorInProgress'));
          break;
        }
        default:
          utils.toast.error(t('global.form.errors.generic'));
          break;
      }
      hideModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID);
    },
  });

  const canSendValidationRequest = useMemo(() => {
    if (isReadOnly) return false;
    if (documentListData?.data?.collection?.length < 1) return false;
    if (!onboardingStatus) return false;
    return [
      ENROLMENT_STATUS.PROFILE_DONE,
      ENROLMENT_STATUS.ENROLMENT_DENIED,
      ENROLMENT_STATUS.ENROLMENT_CHALLENGED,
    ].includes(onboardingStatus);
  }, [
    documentListData?.data?.collection?.length,
    isReadOnly,
    onboardingStatus,
  ]);

  const mustSendValidationRequest = checkIfSendProfileValidationRequest(rentDistribution);

  const sendValidationBtnLabel = useMemo(() => {
    switch (onboardingStatus) {
      case ENROLMENT_STATUS.PROFILE_DONE:
      case ENROLMENT_STATUS.ENROLMENT_DENIED:
      case ENROLMENT_STATUS.ENROLMENT_CHALLENGED:
        return t('profile.submit');
      case ENROLMENT_STATUS.ENROLMENT_PENDING:
      case ENROLMENT_STATUS.ENROLMENT_REQUESTED:
        return t('profile.submitted');
      case ENROLMENT_STATUS.ENROLMENT_BYPASSED:
      case ENROLMENT_STATUS.COMPLETED:
        return t('profile.submitValidated');
      default:
        return t('profile.submit');
    }
  }, [onboardingStatus]);

  if (documentListLoading) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className="m-t-50">
      <h2>
        {userHasDelegation
          ? t('global.documents.title')
          : t('profile.documents.title')}
      </h2>
      {mustSendValidationRequest && (
        <Message
          content={t(`profile.documents.requiredDocumentsPhysical${userHasDelegation ? 'Delegated' : ''}`)}
          variant="info"
          className={utils.cn(['m-t-20', styles.requiredDocumentsPhysical])}
        />
      )}
      <ProfileDocumentsList
        documentsData={documentListData?.data?.collection || []}
        refetchDocuments={refetchDocumentList}
        userUID={userUid}
        rentDistribution={rentDistribution}
        isReadOnly={isReadOnly}
        userHasDelegation={userHasDelegation}
      />
      <MissingDocuments
        missingDocuments={missingDocuments}
        type="PPH"
      />
      {mustSendValidationRequest && (
        <Button
          className="m-t-40 center-block"
          icon="etat-lieux"
          size="large"
          disabled={!canSendValidationRequest}
          onClick={() => showModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID)}
        >
          {sendValidationBtnLabel}
        </Button>
      )}
      {/* Send validation request confirmation modal */}
      <Modal
        id={SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID}
        title={t('profile.documents.confirmSendValidation')}
        size="large"
        onClose={() => hideModal(SEND_VALIDATION_REQUEST_CONFIRM_MODAL_ID)}
      >
        <Button
          className="m-t-40 center-block"
          size="large"
          loading={enrolmentMutation.isLoading}
          onClick={enrolmentMutation.mutate}
        >
          {t('global.validate')}
        </Button>
      </Modal>
    </div>
  );
}

ProfileDocuments.propTypes = {
  documentQuery: PropTypes.shape({}),
  userHasDelegation: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  delegatedUser: PropTypes.shape({
    uid: PropTypes.string,
    onboardingStatus: PropTypes.string,
    rentDistribution: PropTypes.string,
  }),
};

export default ProfileDocuments;
