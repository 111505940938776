import { useCallback, useMemo, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

// Components
import {
  Picto,
  Button,
  Tag,
  utils,
} from 'ui-library-unlocker';
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import TableOptions from '../../components/atoms/TableOptions/TableOptions';
import DataTable from '../../components/organisms/DataTable/DataTable';
import ConfirmationModal from '../../components/organisms/ConfirmationModal/ConfirmationModal';

// Services
import { getInventories, deleteInventory } from '../../services/inventory';

// Hooks
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useAppContext } from '../../store/context';
import useFilters from '../../hooks/useFilters';

// Utils
import { formatAddress } from '../../utils/properties';
import { handleDocumentDownload } from '../../utils/documents';
import { hideModal, showModal } from '../../utils/modal';
import { getInventoryStatusVariant } from '../../utils/variants';

// Styles
import styles from './InventoryList.module.scss';

// Constants
import { SEARCH_SCOPE_LIST, INVENTORY_STATUS_LIST } from '../../utils/constants';

const SEARCH_SCOPE = {
  [SEARCH_SCOPE_LIST.PROPERTIES]: 'propertyUid',
  [SEARCH_SCOPE_LIST.RESIDENTIAL_COMPLEXES]: 'residentialComplexUid',
};

const DELETE_CONFIRM_MODAL_ID = 'inventory-delete-confirm';

function Inventory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(t('inventory.browserTitle'));
  const { context: { user }, dispatch } = useAppContext();
  const { filters } = useFilters();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [docSelected, setDocSelected] = useState(null);

  const inventoryDeleteMutation = useMutation({
    mutationFn: () => deleteInventory(docSelected),
    onSuccess: () => {
      dispatch({ type: 'SET_NEED_DATA_TABLE_REFETCH', payload: 'inventory-list' });
      hideModal(DELETE_CONFIRM_MODAL_ID);
      utils.toast.success(t('inventory.crud.deleteSuccess'));
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default: {
            utils.toast.error(t('global.form.errors.global'));
            break;
          }
        }
      }
    },
  });

  const showDeleteConfirmModal = useCallback((uid) => {
    setDocSelected(uid);
    showModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const handleDelete = useCallback(() => {
    inventoryDeleteMutation.mutate();
  }, []);

  const cancelDelete = useCallback(() => {
    setDocSelected(null);
    hideModal(DELETE_CONFIRM_MODAL_ID);
  }, []);

  const columns = useMemo(() => ([
    {
      header: t('inventory.table.columns.creationDate'),
      accessorKey: 'creationDate',
      size: 125,
      cell: ({ row: { original: { creationDate } } }) => (
        <div>
          <span>{creationDate ? format(new Date(creationDate), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('inventory.table.columns.property'),
      accessorKey: 'property',
      size: 125,
      cell: ({ row: { original: { property } } }) => (
        <div>
          <Link to={`/property/${property?.uid}`} target="_blank" className={styles.name}>
            <p className="p-2-700">
              {property?.name}
              <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
            </p>
          </Link>
          <p className="p-2-700">
            {formatAddress(property?.address)}
          </p>
        </div>
      ),
    },
    {
      header: t('inventory.table.columns.date'),
      accessorKey: 'date',
      size: 125,
      cell: ({ row: { original: { date } } }) => (
        <div>
          <span>{date ? format(new Date(date), 'dd MMM yyyy', { locale: fr }) : '-'}</span>
        </div>
      ),
    },
    {
      header: t('inventory.table.columns.lease'),
      accessorKey: 'lease',
      size: 125,
      cell: ({ row: { original: { lease, draft, status } } }) => {
        const draftData = JSON.parse(draft);
        const leaseData = status !== INVENTORY_STATUS_LIST.DRAFT
          ? lease
          : { uid: draftData?.leaseUid, name: draftData?.leaseName };
        return (
          <div>
            {leaseData?.uid && (
              <Link to={`/lease/${leaseData?.uid}`} target="_blank" className={styles.name}>
                <p className="p-2-700">
                  {leaseData?.name}
                  <Picto icon="export-link" width={9} color="var(--color-secondary)" className={styles.externalLink} />
                </p>
              </Link>
            )}
          </div>
        );
      },
    },
    {
      header: t('inventory.table.columns.tenants'),
      accessorKey: 'tenant',
      size: 100,
      enableSorting: false,
      cell: ({ row: { original: { signers, draft, status } } }) => {
        const draftData = JSON.parse(draft);
        const signersData = status !== INVENTORY_STATUS_LIST.DRAFT ? signers : draftData?.signers;
        return (
          <div>
            {signersData
              ?.filter((signer) => signer.role === 'tenant')
              ?.map((tenant) => {
                const {
                  firstName,
                  lastName,
                  uid: tenantUid,
                } = tenant;
                return (
                  <Link to={`/tenant/${tenantUid}`} target="_blank">
                    <p className="p-2-700">
                      {`${firstName} ${lastName}`}
                    </p>
                  </Link>
                );
              })}
          </div>
        );
      },
    },
    {
      header: t('inventory.table.columns.status'),
      accessorKey: 'status',
      size: 100,
      cell: ({ row: { original: { status } } }) => (
        <div>
          <Tag
            variant={getInventoryStatusVariant(status)}
            size="medium"
            label={t(`inventory.status.${status}`)}
          />
        </div>
      ),
    },
    {
      header: '',
      accessorKey: 'additionalOptions',
      size: 1,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            uid, status, signers, documentUid,
          },
        },
      }) => {
        const signerUser = signers?.find((signer) => signer?.uid === user?.username);
        const shouldSign = signerUser?.status === 'pending' && signerUser?.link;
        return (
          <div className={styles.seeMore}>
            <Picto
              id={`more-option-inventory-${uid}`}
              icon="more"
              width={24}
              onClick={(e) => {
                e.stopPropagation();
                if (tooltipOpen === uid) setTooltipOpen(null);
                else setTooltipOpen(uid);
              }}
              color="var(--color-secondary)"
            />
            <Tooltip
              isOpen={tooltipOpen === uid}
              anchorId={`more-option-inventory-${uid}`}
              place="bottom"
              type="dark"
              effect="solid"
            >
              <TableOptions
                options={[
                  {
                    id: status === INVENTORY_STATUS_LIST.DRAFT ? 'edit' : 'see',
                    label: t(`global.listOptions.${status === INVENTORY_STATUS_LIST.DRAFT ? 'resume' : 'see'}`),
                    icon: (
                      <Picto
                        icon={status === INVENTORY_STATUS_LIST.DRAFT ? 'edit' : 'eye'}
                        width={24}
                        color="var(--color-primary)"
                      />
                    ),
                    onHoverIcon: (
                      <Picto
                        icon={status === INVENTORY_STATUS_LIST.DRAFT ? 'edit' : 'eye'}
                        width={24}
                        color="var(--color-white)"
                      />
                    ),
                    onClick: (e) => {
                      e.stopPropagation();
                      setTooltipOpen(null);
                      navigate(`/inventory/${uid}`);
                    },
                  },
                  documentUid ? {
                    id: 'download',
                    label: t('global.listOptions.download'),
                    icon: <Picto icon="import" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="import" width={24} color="var(--color-white)" />,
                    onClick: () => {
                      handleDocumentDownload({ uid: documentUid }, t);
                    },
                  } : null,
                  shouldSign ? {
                    id: 'sign',
                    label: t('global.listOptions.sign'),
                    icon: <Picto icon="sign" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="sign" width={24} color="var(--color-white)" />,
                    onClick: () => {
                      window.open(signerUser.link, '_blank');
                    },
                  } : null,
                  status === INVENTORY_STATUS_LIST.DRAFT ? {
                    id: 'delete',
                    label: t('global.listOptions.delete'),
                    icon: <Picto icon="trash" width={24} color="var(--color-primary)" />,
                    onHoverIcon: <Picto icon="trash" width={24} color="var(--color-white)" />,
                    onClick: (e) => {
                      e.stopPropagation();
                      setTooltipOpen(null);
                      showDeleteConfirmModal(uid);
                    },
                  } : null,
                ].filter(Boolean)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]), [t, tooltipOpen, navigate, setTooltipOpen, user, handleDocumentDownload, showDeleteConfirmModal]);

  const allFiltersOptions = useMemo(() => [
    {
      name: 'date',
      multiName: ['dateDate', 'dateStartDate', 'dateEndDate'],
      type: 'date',
      title: t('inventory.table.columns.date'),
      options: [
        {
          value: filters?.dateDate?.[0],
          label: `${t('global.filterDate.exactSelectedFilterLabel')} ${filters?.dateDate?.[0]
            ? format(new Date(filters?.dateDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.dateStartDate?.[0],
          label: `${t('global.filterDate.after')} ${filters?.dateStartDate?.[0]
            ? format(new Date(filters?.dateStartDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
        {
          value: filters?.dateEndDate?.[0],
          label: `${t('global.filterDate.before')} ${filters?.dateEndDate?.[0]
            ? format(new Date(filters?.dateEndDate?.[0]), 'dd/MM/yyyy', { locale: fr })
            : ''
          }`,
        },
      ],
    },
    {
      name: 'status',
      type: 'checkbox',
      title: t('inventory.table.columns.status'),
      options: Object.values(INVENTORY_STATUS_LIST).map((status) => ({
        value: status,
        label: t(`inventory.status.${status}`),
      })),
    },
  ], [t, filters]);

  const headerBtn = useMemo(() => (
    <Link to="/inventory/add" className={styles.headerBtn}>
      <Button
        variant="primary"
        size="large"
        icon="plus"
      >
        {t('inventory.headerBtn')}
      </Button>
    </Link>
  ), [t]);

  return (
    <div className={styles.inventory}>
      <h1>{t('inventory.title')}</h1>
      <DataTable
        columns={columns}
        forcedFilters={{ orderBy: { property: 'createdAt', direction: 'DESC' } }}
        searchLabel={t('inventory.search')}
        omniSearch
        omniSearchScope={SEARCH_SCOPE}
        getData={getInventories}
        queryKey="inventory-list"
        allFiltersOptions={allFiltersOptions}
        headerBtn={headerBtn}
      />
      <ConfirmationModal
        id={DELETE_CONFIRM_MODAL_ID}
        onSubmit={handleDelete}
        onCancel={cancelDelete}
        loading={inventoryDeleteMutation.isLoading}
      />
    </div>
  );
}

export default Inventory;
