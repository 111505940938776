/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

// Components
import {
  utils,
  TextInput,
  Button,
  CheckboxGroup,
  ToggleGroup,
  Message,
  DateInput,
  UnlockerLoader,
} from 'ui-library-unlocker';
import FormInfoRequired from '../../../atoms/FormInfoRequired/FormInfoRequired';
import DocumentList from '../../DocumentList/DocumentList';
import Modal from '../../../molecules/Modal/Modal';
import FileInput from '../../../molecules/FileInput/FileInput';
import ConsumptionScore from '../../../atoms/ConsumptionScore/ConsumptionScore';

// Services
import {
  updateResidenceCharacteristics,
  getResidenceDocuments,
  createResidenceDocument,
  deleteResidenceDocument,
} from '../../../../services/residence';

// Context
import { useAppContext } from '../../../../store/context';

// Hooks
import useResponsive from '../../../../hooks/useResponsive';
import useScroll from '../../../../hooks/useScroll';
import useFileUpload from '../../../../hooks/useFileUpload';
import useManagementRights from '../../../../hooks/useManagementRights';

// Utils
import {
  residenceCharacteristicsInitialValues,
  residenceCharacteristicsSchema,
} from '../../../../utils/forms/residenceSchema';
import { displayError, isFieldValid } from '../../../../utils/forms/form';
import { sortObjectKeysByValueAsc } from '../../../../utils/sort';
import { showModal, hideModal } from '../../../../utils/modal';
import { handleDocumentDownload, handleFileSelect } from '../../../../utils/documents';
import { getDocumentSchema, documentInitialValues } from '../../../../views/Property/PropertyDocuments/documentSchema';

// Styles
import styles from './ResidenceForm.module.scss';

// Constants
const ADD_DOC_MODAL_ID = 'add-ges-dpe-modal';
const DOCUMENT_TYPE = 'residenceDocument';

function ResidenceCharacteristicsForm({ residenceQuery }) {
  const { t } = useTranslation();
  const { context: { user, uiBuilders } } = useAppContext();
  const { isMobile, isLaptopXs } = useResponsive();
  const { scrollContentToTop } = useScroll();

  const [statusMessage, setStatusMessage] = useState({ displayed: false, value: '' });
  const [isHugeDocument, setHugeDocument] = useState(false);

  const residenceBuilders = useMemo(() => (uiBuilders ? uiBuilders['/property/ui'] : null), [uiBuilders]);

  const match = useMatch('/residence/:id');

  const {
    data: residenceData,
    error: residenceError,
    isFetched: residenceIsFetched,
    refetch: residenceRefetch,
  } = residenceQuery || {};

  const userHasManagementRights = useManagementRights(residenceData?.data?.managerUid);

  const {
    data: documentListData,
    isFetching: documentListFetching,
    refetch: refetchDocumentList,
  } = useQuery({
    queryKey: ['residence-documents', user],
    queryFn: () => getResidenceDocuments(match?.params?.id, {
      filters: {
        type: ['ges_disgnostic', 'dpe_disgnostic'],
      },
    }),
    enabled: !!user,
    keepPreviousData: true,
  });

  const resetDocumentModal = () => {
    hideModal(ADD_DOC_MODAL_ID);
    setFileType(null);
    setFile(null);
    setHugeDocument(false);
    diagnosisDocumentFormik.resetForm();
  };

  const createDocumentCb = async (documentUid, fileName) => {
    const createdDocument = await createResidenceDocument(match?.params?.id, {
      type: fileType,
      name: fileName,
      documentUid,
      date: new Date(),
    });
    refetchDocumentList();
    resetDocumentModal();
    return createdDocument;
  };

  const [{
    uploadFile,
    setFileType,
    setFile,
  }, {
    fileType,
    file,
    isUploading,
  }] = useFileUpload(createDocumentCb);

  const documentDeleteMutation = useMutation({
    mutationFn: deleteResidenceDocument,
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          utils.toast.success(t('global.documents.delete.success'));
          refetchDocumentList();
          break;
        default:
          break;
      }
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
    },
  });

  const diagnosisDocumentInitialValues = useMemo(() => ({
    ...documentInitialValues,
    userUid: user?.username,
  }), [user]);

  const diagnosisDocumentFormik = useFormik({
    initialValues: diagnosisDocumentInitialValues,
    validationSchema: getDocumentSchema({ documentType: ['ges_disgnostic', 'dpe_disgnostic'] }),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (typeof file === 'object') {
        uploadFile({
          ...values,
          type: DOCUMENT_TYPE,
        });
      } else {
        utils.toast.info(t('global.documents.addDocumentForm.errors.noFileSelected'));
      }
    },
  });

  useEffect(() => {
    if (fileType) diagnosisDocumentFormik.setFieldValue('type', fileType);
  }, [fileType]);

  const residenceMutation = useMutation({
    mutationFn: (data) => updateResidenceCharacteristics(match?.params?.id, {
      ...data,
      properties: residenceData?.data?.properties?.map((property) => property.uid),
    }),
    onSuccess: ({ response, status }) => {
      const s = status || response?.status;
      switch (s) {
        case 204:
          residenceRefetch().then(() => {
            setStatusMessage({ displayed: true, value: t('residence.tabs.generalInfo.formSuccess') });
            scrollContentToTop();
          });
          break;
        default:
          break;
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      if (err?.response) {
        switch (err?.response?.status) {
          case 400: {
            utils.toast.error(t('global.form.errors.generic'));
            break;
          }
          default:
            break;
        }
      }
      formik.setSubmitting(false);
    },
  });

  const initialValues = useMemo(() => {
    if (residenceError) return residenceData;
    if (residenceData?.data) return residenceData.data;
    return residenceCharacteristicsInitialValues;
  }, [user, residenceData]);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      try {
        residenceCharacteristicsSchema.validateSync(values, {
          abortEarly: false,
        });
      } catch (err) {
        const errorList = err.inner.reduce((errors, error) => {
          errors[error.path] = error.message;
          return errors;
        }, {});

        return errorList;
      }
      return {};
    },
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      residenceMutation.mutate(values);
    },
  });

  const equipmentsOptions = useMemo(() => {
    if (!residenceBuilders) return [];
    const equipments = residenceBuilders?.residentialComplexEquipments;
    if (equipments) {
      return sortObjectKeysByValueAsc(equipments).map((equipmentKey) => ({
        id: equipmentKey,
        label: equipments[equipmentKey],
        value: equipmentKey,
      }));
    }
    return [];
  }, [residenceBuilders]);

  const heatingsOptions = useMemo(() => {
    if (!residenceBuilders) return [];
    const heatings = residenceBuilders?.residentialComplexHeatings;
    if (heatings) {
      return sortObjectKeysByValueAsc(heatings).map((heatingKey) => ({
        id: heatingKey,
        label: heatings[heatingKey],
        value: heatingKey,
      }));
    }
    return [];
  }, [residenceBuilders]);

  const equipmentItemsPerRow = useMemo(() => {
    if (isMobile) return 1;
    if (isLaptopXs) return 3;
    return 4;
  }, [isMobile, isLaptopXs]);

  const columns = useMemo(() => (documentType) => ([
    {
      header: t('global.documents.columns.headers.category'),
      accessorKey: 'type',
      size: 1,
      enableSorting: false,
      cell: () => {
        if (!uiBuilders) return null;
        return (
          <div className={styles.documentTypeValue}>{documentType}</div>
        );
      },
    },
    {
      header: t('global.documents.columns.headers.name'),
      accessorKey: 'name',
      size: 400,
      enableSorting: false,
      cell: ({ row: { original } }) => {
        const fileName = original?.name;
        return (
          <div>
            <p
              tabIndex={0}
              role="button"
              onKeyDown={null}
              onClick={() => handleDocumentDownload(original, t)}
              className={styles.documentNameValue}
            >
              {fileName}
            </p>
          </div>
        );
      },
    },
  ]), [t, uiBuilders]);

  const handleReferenceYearChange = useCallback((date) => {
    formik.setFieldValue('referenceYear', date ? date.getFullYear() : date);
  }, [formik]);

  const referenceYear = useMemo(() => {
    if (formik.values.referenceYear) {
      return new Date(formik.values.referenceYear, 0, 1);
    }
    return null;
  }, [formik.values.referenceYear]);

  const handleFileChange = useCallback((e) => {
    handleFileSelect(e, setHugeDocument, diagnosisDocumentFormik, setFile, t, 50000000);
  }, [setHugeDocument, diagnosisDocumentFormik, setFile, t]);

  const handleCancel = useCallback(() => {
    formik.resetForm();
    utils.toast.info(t('global.form.cancelMessage'));
  }, [formik]);

  if (!residenceIsFetched) return <UnlockerLoader size={200} align="left" />;

  return (
    <div className={styles.wrapper}>
      <form className={styles.caracteristicsForm} onSubmit={formik.handleSubmit}>
        {/* Additional Info */}
        <h2 className="m-b-20">{t('residence.subtitles.characteristics.additionalInfo')}</h2>
        <FormInfoRequired className="m-b-10" content={t('residence.crud.form.equipments')} />
        <CheckboxGroup
          name="equipments"
          className="m-t-25"
          options={equipmentsOptions}
          value={formik?.values?.equipments}
          onChange={(value) => formik.setFieldValue('equipments', value)}
          display="horizontal"
          itemsPerRow={equipmentItemsPerRow}
          disabled={!userHasManagementRights}
        />

        {/* Heating Type */}
        <h2 className="m-t-50 m-b-20">{t('residence.subtitles.characteristics.heatingType')}</h2>
        <FormInfoRequired className="m-b-10" content={t('residence.crud.form.heatings')} />
        <ToggleGroup
          name="heatings"
          className="m-t-25"
          options={heatingsOptions}
          value={formik?.values?.heatings}
          onChange={(value) => formik.setFieldValue('heatings', value)}
          display="horizontal"
          itemsPerRow={equipmentItemsPerRow}
          disabled={!userHasManagementRights}
        />

        {/* GES */}
        <h2 className="m-t-50 m-b-20">{t('residence.subtitles.characteristics.ges')}</h2>
        <FormInfoRequired className="m-b-40" content={t('global.documents.info')} />
        <TextInput
          min="0"
          type="number"
          id="gesScore"
          name="gesScore"
          className={utils.cn([styles.formInput, 'm-t-25'])}
          label={t('residence.crud.form.gesScore')}
          error={displayError(t, formik, 'gesScore')}
          valid={isFieldValid(formik, 'gesScore', null, initialValues?.gesScore)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.gesScore}
          disabled={!userHasManagementRights}
        />
        <ConsumptionScore
          className="m-t-25"
          score={formik.values.gesScore}
          type="ges"
        />
        <DocumentList
          className="m-t-25"
          fileList={['ges_disgnostic']}
          columns={columns(t('residence.subtitles.characteristics.documents.ges'))}
          documentListData={documentListData}
          addButton={{
            enabled: userHasManagementRights,
            label: t('residence.subtitles.characteristics.documents.addGes'),
            icon: 'plus',
          }}
          onAddBtnClick={() => {
            const docType = 'ges_disgnostic';
            setFileType(docType);
            formik.setFieldValue('type', docType);
            showModal(ADD_DOC_MODAL_ID);
          }}
          onFileListElementAddClick={(element) => {
            const { type: documentType } = element;
            setFileType(documentType);
            formik.setFieldValue('type', documentType);
            showModal(ADD_DOC_MODAL_ID);
          }}
          onFileListElementDelete={(element) => {
            documentDeleteMutation.mutate(element?.uid);
          }}
          pagination={null}
          isDocumentListFetching={documentListFetching}
        />

        {/* DPE */}
        <h2 className="m-t-50 m-b-20">{t('residence.subtitles.characteristics.dpe')}</h2>
        <FormInfoRequired className="m-b-40" content={t('global.documents.info')} />
        <TextInput
          min="0"
          type="number"
          id="dpeScore"
          name="dpeScore"
          className={utils.cn([styles.formInput, 'm-t-25'])}
          label={t('residence.crud.form.dpeScore')}
          error={displayError(t, formik, 'dpeScore')}
          valid={isFieldValid(formik, 'dpeScore', null, initialValues?.dpeScore)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dpeScore}
          disabled={!userHasManagementRights}
        />
        <ConsumptionScore
          className="m-t-25"
          score={formik.values.dpeScore}
          type="dpe"
        />
        <DocumentList
          className="m-t-25"
          fileList={['dpe_disgnostic']}
          columns={columns(t('residence.subtitles.characteristics.documents.dpe'))}
          documentListData={documentListData}
          addButton={{
            enabled: userHasManagementRights,
            label: t('residence.subtitles.characteristics.documents.addDpe'),
            icon: 'plus',
          }}
          onAddBtnClick={() => {
            const docType = 'dpe_disgnostic';
            setFileType(docType);
            formik.setFieldValue('type', docType);
            showModal(ADD_DOC_MODAL_ID);
          }}
          onFileListElementAddClick={(element) => {
            const { type: documentType } = element;
            setFileType(documentType);
            formik.setFieldValue('type', documentType);
            showModal(ADD_DOC_MODAL_ID);
          }}
          onFileListElementDelete={(element) => {
            documentDeleteMutation.mutate(element?.uid);
          }}
          pagination={null}
          isDocumentListFetching={documentListFetching}
        />
        <DateInput
          id="referenceYear"
          name="referenceYear"
          className={utils.cn([styles.formInput, 'm-t-25'])}
          label={t('residence.crud.form.referenceYear')}
          error={displayError(t, formik, 'referenceYear')}
          valid={isFieldValid(formik, 'referenceYear', null, initialValues?.referenceYear)}
          onChange={handleReferenceYearChange}
          onBlur={formik.handleBlur}
          value={referenceYear}
          yearOnly
          disabled={!userHasManagementRights}
        />
        {userHasManagementRights && (
          <div className={styles.submit}>
            <Button
              type="submit"
              size="large"
              loading={formik.isSubmitting}
              label={t('global.validate')}
            />
            <button
              type="button"
              className={styles.cancel}
              onClick={handleCancel}
            >
              {t('global.cancel')}
            </button>
          </div>
        )}
      </form>
      <div className={styles.message}>
        {statusMessage?.displayed ? <Message content={statusMessage.value} /> : null}
      </div>
      <Modal
        className={styles.modal}
        id={ADD_DOC_MODAL_ID}
        title={isHugeDocument
          ? t('global.documents.addDocumentForm.errors.size.title')
          : t(
            'property.tabs.characteristics.documents.info',
            {
              fileType: fileType === 'ges_disgnostic'
                ? t('property.tabs.characteristics.documents.ges')
                : t('property.tabs.characteristics.documents.dpe'),
            },
          )}
        size="large"
        onClose={resetDocumentModal}
      >
        {!isHugeDocument ? (
          <form onSubmit={diagnosisDocumentFormik.handleSubmit} className={styles.addForm}>
            <TextInput
              type="text"
              id="customName"
              name="customName"
              className="m-t-25"
              label={t('global.documents.addDocumentForm.fields.name')}
              error={displayError(t, diagnosisDocumentFormik, 'customName')}
              valid={isFieldValid(diagnosisDocumentFormik, 'customName', null, initialValues?.customName)}
              onChange={diagnosisDocumentFormik.handleChange}
              onBlur={diagnosisDocumentFormik.handleBlur}
              value={diagnosisDocumentFormik.values.customName}
            />
            <FileInput
              className="m-t-25"
              id="profile-file"
              name="profile-file"
              label={t('global.documents.addDocumentForm.fields.file')}
              help={t('global.documents.addDocumentForm.fileHelp50Mo')}
              accept="image/jpg,image/png,image/jpeg,application/pdf"
              value={file}
              onChange={handleFileChange}
            />
            <div className={styles.submitBtn}>
              <Button
                type="submit"
                loading={isUploading}
                className="m-t-25"
                label={t('global.documents.addDocumentForm.submit')}
              />
            </div>
          </form>
        ) : (
          <div className={styles.hugeDocument}>
            <p className={styles.errorModalSubtitle}>
              {t('global.documents.addDocumentForm.errors.size.message50Mo')}
            </p>
            <div className={styles.submitBtn}>
              <Button
                className="m-t-25"
                label={t('global.documents.addDocumentForm.errors.size.action')}
                onClick={() => {
                  diagnosisDocumentFormik.resetForm();
                  setFileType(null);
                  setFile(null);
                  setHugeDocument(false);
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

ResidenceCharacteristicsForm.propTypes = {
  residenceQuery: PropTypes.shape().isRequired,
};

export default ResidenceCharacteristicsForm;
