import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate, useMatch } from 'react-router-dom';
import { isEmpty, debounce } from 'lodash';
import TagManager from 'react-gtm-module';

// Components
import {
  utils,
  TextInput,
  SelectInput,
  Button,
  Message,
  UnlockerLoader,
  ToggleInput,
  DateInput,
} from 'ui-library-unlocker';
import AddressInput from '../../../molecules/AddressInput/AddressInput';
import FormInfoRequired from '../../../atoms/FormInfoRequired/FormInfoRequired';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';

// Services
import { createResidence, updateResidence } from '../../../../services/residence';
import { getOwners } from '../../../../services/owner';
import { getManagers } from '../../../../services/person';

// Context
import { useAppContext } from '../../../../store/context';

// Hooks
import useScroll from '../../../../hooks/useScroll';
import useRoles from '../../../../hooks/useRoles';
import useProfileCompletion from '../../../../hooks/useProfileCompletion';
import useProfile from '../../../../hooks/useProfile';
import useManagementRights from '../../../../hooks/useManagementRights';

// Utils
import residenceSchema, { residenceInitialValues } from '../../../../utils/forms/residenceSchema';
import { displayError, errorFocusSubmit, isFieldValid } from '../../../../utils/forms/form';
import { showModal, hideModal, checkIsModalOpen } from '../../../../utils/modal';

// Styles
import styles from './ResidenceForm.module.scss';

// Constants
import { ENROLMENT_STATUS } from '../../../../utils/constants';

const IMPACT_PROPERTIES_MODAL_ID = 'impact-properties-modal';

function ResidenceForm({ isEditing, residenceQuery }) {
  const { t } = useTranslation();
  const { context: { user, uiBuilders, me } } = useAppContext();
  const { profile } = useProfile();
  const { onboardingStatus } = useProfileCompletion();
  const { scrollContentToTop } = useScroll();
  const navigate = useNavigate();
  const { isUserRealEstateManager, isUserAdmin } = useRoles();

  const [statusMessage, setStatusMessage] = useState({ displayed: false, value: '' });
  const [ownerQuery, setOwnerQuery] = useState('');
  const [managerQuery, setManagerQuery] = useState('');

  const residenceBuilders = useMemo(() => (uiBuilders ? uiBuilders['/property/ui'] : null), [uiBuilders]);

  const userType = useMemo(() => {
    if (isUserAdmin) return 'admin';
    if (isUserRealEstateManager) return 'manager';
    return 'owner';
  }, [isUserAdmin, isUserRealEstateManager]);

  const match = useMatch('/residence/:id');

  const {
    data: residenceData,
    isFetched: isResidenceFetched,
    error: residencError,
    refetch: residenceRefetch,
  } = residenceQuery || {};

  const {
    data: ownerListData,
    isFetching: isOwnerListFetching,
    isLoading: isOwnerListLoading,
  } = useQuery({
    queryKey: ['owner-list-residence-form', ownerQuery],
    queryFn: () => getOwners({
      page: 1,
      itemsPerPage: 100,
      filters: {
        search: [ownerQuery],
      },
    }),
    keepPreviousData: true,
    enabled: isUserRealEstateManager || isUserAdmin,
  });

  const {
    data: managerListData,
    isFetching: isManagerListFetching,
    isLoading: isManagerListLoading,
  } = useQuery({
    queryKey: ['managers-list', managerQuery],
    queryFn: () => getManagers({
      page: 1,
      itemsPerPage: 100,
      filters: {
        onboardingStatus: [
          ENROLMENT_STATUS.PROFILE_DONE,
          ENROLMENT_STATUS.COMPLETED,
          ENROLMENT_STATUS.ENROLMENT_BYPASSED,
        ],
        search: [managerQuery],
      },
    }),
    keepPreviousData: true,
  });

  const handleSearchOwner = useCallback(debounce((value) => {
    setOwnerQuery(value);
  }, 500), [setOwnerQuery]);

  const handleSearchManager = useCallback(debounce((value) => {
    setManagerQuery(value);
  }, 500), [setManagerQuery]);

  const userHasManagementRights = useManagementRights(residenceData?.data?.managerUid);

  const managerListOptions = useMemo(() => {
    if (managerListData?.data?.collection) {
      return managerListData?.data?.collection?.map((manager) => ({
        value: manager.uid,
        label: manager.brandName,
      }));
    }
    return [];
  }, [managerListData]);

  const residenceMutation = useMutation({
    mutationFn: (data) => {
      if (isEditing) {
        return updateResidence(match?.params?.id, {
          ...data,
          properties: residenceData?.data?.properties?.map((property) => property.uid),
        });
      }
      TagManager.dataLayer({
        dataLayer: {
          event: 'residence_created',
          userType,
        },
      });
      return createResidence(data);
    },
    onSuccess: ({ response, status, data }) => {
      const s = status || response?.status;
      switch (s) {
        case 201:
          utils.toast.success(t('residence.tabs.generalInfo.formSuccess'));
          setStatusMessage({ displayed: true, value: t('residence.tabs.generalInfo.formSuccess') });
          window.scrollTo({ top: 0, behavior: 'smooth' });
          navigate(`/residence/${data?.uid}`);
          break;
        case 204:
          if (checkIsModalOpen(IMPACT_PROPERTIES_MODAL_ID)) {
            hideModal(IMPACT_PROPERTIES_MODAL_ID);
          }
          residenceRefetch().then(() => {
            setStatusMessage({ displayed: true, value: t('residence.tabs.generalInfo.formSuccess') });
            scrollContentToTop();
          });
          break;
        default:
          break;
      }
      formik.setSubmitting(false);
    },
    onError: (err) => {
      switch (err?.response?.status) {
        case 400: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
        case 403: {
          utils.toast.error(t('global.form.errors.forbidden'));
          break;
        }
        default: {
          utils.toast.error(t('global.form.errors.generic'));
          break;
        }
      }
      formik.setSubmitting(false);
    },
  });

  const initialValues = useMemo(() => {
    if (residencError) return residenceData;
    if (residenceData) {
      return {
        ...residenceData?.data,
      };
    }
    return residenceInitialValues;
  }, [residenceData, residencError, isUserRealEstateManager]);

  const formik = useFormik({
    initialValues,
    validationSchema: residenceSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isEditing && values.managerUid !== initialValues.managerUid) {
        showModal(IMPACT_PROPERTIES_MODAL_ID);
        formik.setSubmitting(false);
      } else {
        residenceMutation.mutate(values);
      }
    },
  });

  const ownerListOptions = useMemo(() => {
    if (profile && user) {
      const options = [];
      if (!isUserRealEstateManager && !isUserAdmin) {
        options.push({
          value: user.username,
          label: `${profile.firstName} ${profile.lastName}`,
        });
      } else {
        ownerListData?.data?.collection
          ?.filter((owner) => (
            [
              ENROLMENT_STATUS.PROFILE_DONE,
              ENROLMENT_STATUS.COMPLETED,
              ENROLMENT_STATUS.ENROLMENT_BYPASSED,
              ENROLMENT_STATUS.ENROLMENT_REQUESTED,
            ].includes(owner.onboardingStatus)
            || owner.delegatedTo === user.username
          ))
          ?.forEach((owner) => {
            options.push({
              value: owner.uid,
              label: `${owner.firstName} ${owner.lastName}`,
            });
            // INFO TO BE CHECKED : you cannot select a company as a residence owner
            // if (Array.isArray(owner.companies)) {
            //   owner.companies
            //     .filter((company) => !company.isRealEstateAgency)
            //     .forEach((company) => {
            //       options.push({
            //         value: company.uid,
            //         label: company.brandName,
            //         parent: {
            //           value: owner.uid,
            //           label: `${owner.firstName} ${owner.lastName}`,
            //         },
            //       });
            //     });
            // }
          });
      }
      // profileCompaniesNotRealEstate.forEach((company) => {
      //   options.push({
      //     value: company.uid,
      //     label: company.brandName,
      //     parent: !isUserRealEstateManager
      //       ? {
      //         value: user.username,
      //         label: `${profile.firstName} ${profile.lastName}`,
      //       }
      //       : undefined,
      //   });
      // });
      return options;
    }
    return [];
  }, [/* profileCompaniesNotRealEstate, */user, isUserRealEstateManager, profile, ownerListData]);

  const typeOptions = useMemo(() => {
    if (!residenceBuilders?.residentialComplexTypes) return [];
    return Object.entries(residenceBuilders.residentialComplexTypes).map(([type, label]) => ({
      value: type,
      label,
    }));
  }, [residenceBuilders]);

  const isFormError = useMemo(() => !isEmpty(formik.errors), [formik.errors]);

  const isOwnManager = useMemo(() => {
    if (formik.values.ownerUid || formik.values.managerUid) {
      return formik.values.ownerUid === formik.values.managerUid;
    }
    return false;
  }, [formik.values.ownerUid, formik.values.managerUid]);

  const userRealEstateAgency = useMemo(() => {
    if (Array.isArray(me?.aclMatrix?.companies)) {
      return me.aclMatrix.companies.find((company) => company.owner && company.isRealEstateAgency);
    }
    return null;
  }, [me]);

  const isReadOnly = useMemo(() => {
    if (isUserAdmin) return false;
    if (!isEditing) return false;
    return !userHasManagementRights;
  }, [isEditing, isUserAdmin, userHasManagementRights]);

  const isFormReady = useMemo(() => {
    if (!isEditing) return true;
    if (!isResidenceFetched || isManagerListLoading) return false;
    if (isUserRealEstateManager && isOwnerListLoading) return false;
    return true;
  }, [isResidenceFetched, isManagerListLoading, isOwnerListLoading, isEditing, isUserRealEstateManager]);

  const handleManagerChange = useCallback((manager) => {
    formik.setFieldValue('managerUid', manager.value);
    formik.setFieldValue('managerName', manager.label);
  }, [formik]);

  const selectedOwner = useMemo(() => (formik.values.ownerUid ? ({
    value: formik.values.ownerUid,
    label: formik.values.ownerName,
  }) : null), [formik.values.ownerUid, formik.values.ownerName]);

  const selectedManager = useMemo(() => (formik.values.managerUid ? ({
    value: formik.values.managerUid,
    label: formik.values.managerName,
  }) : null), [formik.values.managerUid, formik.values.managerName]);

  const handleOwnerChange = useCallback((owner) => {
    formik.setFieldValue('ownerUid', owner.value);
    formik.setFieldValue('ownerName', owner.label);

    if (isUserRealEstateManager) {
      formik.setFieldValue('managerUid', userRealEstateAgency?.uid);
      formik.setFieldValue('managerName', userRealEstateAgency?.brandName);
    } else if (isOwnManager) {
      formik.setFieldValue('managerUid', owner.value);
      formik.setFieldValue('managerName', owner.label);
    }
  }, [formik, isUserRealEstateManager, isOwnManager, userRealEstateAgency]);

  const handleOwnManagerChange = useCallback((check) => {
    if (!check) {
      formik.setFieldValue('managerUid', '');
    } else {
      formik.setFieldValue('managerUid', formik.values.ownerUid);
    }
  }, [formik]);

  const handleConstructionYearChange = useCallback((date) => {
    formik.setFieldValue('constructionYear', date ? date.getFullYear() : date);
  }, [formik]);

  const constructionYear = useMemo(() => {
    if (formik.values.constructionYear) {
      return new Date(formik.values.constructionYear, 0, 1);
    }
    return null;
  }, [formik.values.constructionYear]);

  const closeImpactModal = useCallback(() => {
    hideModal(IMPACT_PROPERTIES_MODAL_ID);
  }, []);

  const handleImpactConfirm = useCallback(() => {
    residenceMutation.mutate(formik.values);
  }, [residenceMutation, formik.values]);

  if (!isFormReady) return <UnlockerLoader size={200} align="left" />;

  const isAllowedToSubmit = (onboardingStatus !== ENROLMENT_STATUS.PENDING) || isUserAdmin;

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={!isAllowedToSubmit ? null : errorFocusSubmit(formik.handleSubmit)}>
        {!isAllowedToSubmit && !isEditing && (
          <Message
            className="m-b-30"
            variant="info"
            content={t('completeProfile.views.properties.level1Info')}
          />
        )}
        {/* General Info */}
        <h2 className={utils.cn(['m-b-30', styles.formTitleWidth])}>
          {t('residence.subtitles.generalInfo.personalInfo')}
        </h2>
        {isEditing && (
          <TextInput
            type="text"
            id="unlockerId"
            name="unlockerId"
            className="m-t-25"
            label={t('residence.crud.form.unlockerId')}
            value={formik.values.unlockerId}
            disabled
          />
        )}
        <TextInput
          type="text"
          id="name"
          name="name"
          className="m-t-25"
          label={t('residence.crud.form.name')}
          error={displayError(t, formik, 'name')}
          valid={isFieldValid(formik, 'name')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          disabled={isReadOnly}
        />
        <SelectInput
          className="m-t-25"
          id="type"
          name="type"
          label={t('residence.crud.form.type')}
          options={typeOptions}
          error={displayError(t, formik, 'type')}
          valid={isFieldValid(formik, 'type')}
          onChange={(value) => {
            formik.setFieldValue('type', value.value);
          }}
          onBlur={formik.handleBlur}
          value={typeOptions.find((type) => type.value === formik.values.type)}
          disabled={isReadOnly}
        />
        <AddressInput
          id="address"
          name="address"
          className="m-t-25"
          label={`${t('residence.crud.form.address')} ${t('global.form.optional')}`}
          error={displayError(t, formik, 'address')}
          valid={isFieldValid(formik, 'address')}
          onAddressSelect={(value) => formik.setFieldValue('address', value)}
          value={formik.values.address || null}
          disabled={isReadOnly}
          countryRestrictions={['fr', 'gp', 're', 'mq', 'gf', 'nc', 'yt', 'pf']}
        />

        {/* Description */}
        <h2 className={utils.cn(['m-t-50 m-b-30', styles.formTitleWidth])}>
          {t('residence.subtitles.generalInfo.description')}
        </h2>
        <FormInfoRequired content={t('global.form.info.mandatoryExceptOptional')} />
        <DateInput
          id="constructionYear"
          name="constructionYear"
          className="m-t-25"
          label={`${t('residence.crud.form.constructionYear')} ${t('global.form.optional')}`}
          error={displayError(t, formik, 'constructionYear')}
          valid={isFieldValid(formik, 'constructionYear')}
          onChange={handleConstructionYearChange}
          onBlur={formik.handleBlur}
          value={constructionYear}
          yearOnly
          disabled={isReadOnly}
        />
        <ToggleInput
          id="hasCollectiveElectricity"
          name="hasCollectiveElectricity"
          className="m-t-25 m-l-10"
          label={t('residence.crud.form.hasCollectiveElectricity')}
          checked={!!formik.values.hasCollectiveElectricity}
          onChange={(check) => formik.setFieldValue('hasCollectiveElectricity', check)}
          disabled={isReadOnly}
        />
        <ToggleInput
          id="hasCollectiveHeating"
          name="hasCollectiveHeating"
          className="m-t-25 m-l-10"
          label={t('residence.crud.form.hasCollectiveHeating')}
          checked={!!formik.values.hasCollectiveHeating}
          onChange={(check) => formik.setFieldValue('hasCollectiveHeating', check)}
          disabled={isReadOnly}
        />
        <TextInput
          min="0"
          type="number"
          id="propertiesCount"
          name="propertiesCount"
          className="m-t-25"
          label={t('residence.crud.form.propertiesCount')}
          error={displayError(t, formik, 'propertiesCount')}
          valid={isFieldValid(formik, 'propertiesCount')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.propertiesCount}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="deliveryPoint"
          name="deliveryPoint"
          className={utils.cn(['m-t-30', styles.deliveryPointInput])}
          label={`${t('residence.crud.form.deliveryPoint')} ${t('global.form.optional')}`}
          error={displayError(t, formik, 'deliveryPoint')}
          valid={isFieldValid(formik, 'deliveryPoint')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.deliveryPoint}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="taxNumber"
          name="taxNumber"
          className={utils.cn(['m-t-30'])}
          label={`${t('residence.crud.form.taxNumber')} ${t('global.form.optional')}`}
          error={displayError(t, formik, 'taxNumber')}
          valid={isFieldValid(formik, 'taxNumber')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.taxNumber}
          disabled={isReadOnly}
        />
        <TextInput
          type="text"
          id="cadastralRef"
          name="cadastralRef"
          className={utils.cn(['m-t-30'])}
          label={`${t('residence.crud.form.cadastralRef')} ${t('global.form.optional')}`}
          error={displayError(t, formik, 'cadastralRef')}
          valid={isFieldValid(formik, 'cadastralRef')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.cadastralRef}
          disabled={isReadOnly}
        />

        {/* Owner and Manager */}
        <h2 className={utils.cn(['m-t-50 m-b-30', styles.formTitleWidth])}>
          {t('residence.subtitles.generalInfo.ownerInfo')}
        </h2>
        <SelectInput
          id="ownerUid"
          name="ownerUid"
          label={t('residence.crud.form.ownerUid')}
          options={ownerListOptions}
          error={displayError(t, formik, 'ownerUid')}
          valid={isFieldValid(formik, 'ownerUid')}
          onChange={handleOwnerChange}
          onInputChange={handleSearchOwner}
          onBlur={formik.handleBlur}
          value={selectedOwner}
          disabled={isReadOnly}
          isLoading={isOwnerListFetching}
        />

        {/* Manager */}
        <h2 className={utils.cn(['m-t-50 m-b-30', styles.formTitleWidth])}>
          {t('residence.subtitles.generalInfo.managerInfo')}
        </h2>
        {!isUserRealEstateManager && (
          <ToggleInput
            id="isOwnManager"
            name="isOwnManager"
            className={utils.cn(['m-b-25', styles.isOwnManager])}
            label={t('residence.crud.form.isOwnManagerInfo')}
            checked={isOwnManager}
            onChange={handleOwnManagerChange}
          />
        )}
        {!isOwnManager && (
          <SelectInput
            id="managerUid"
            name="managerUid"
            label={t('residence.crud.form.managerUid')}
            options={managerListOptions}
            error={displayError(t, formik, 'managerUid')}
            valid={isFieldValid(formik, 'managerUid')}
            onChange={handleManagerChange}
            onInputChange={handleSearchManager}
            onBlur={formik.handleBlur}
            value={selectedManager}
            disabled={isUserRealEstateManager}
            isLoading={isManagerListFetching}
          />
        )}

        {isFormError
          ? <Message variant="error" className="m-t-30" content={t('global.form.errors.localGeneric')} />
          : null}
        {isAllowedToSubmit && (
          <div className={styles.submit}>
            <Button
              type="submit"
              size="large"
              loading={formik.isSubmitting}
              label={t('global.validate')}
            />
            <button
              type="button"
              className={styles.cancel}
              onClick={() => {
                formik.resetForm();
                utils.toast.info(t('global.form.cancelMessage'));
              }}
            >
              {t('global.cancel')}
            </button>
          </div>
        )}
      </form>
      <div>
        {statusMessage?.displayed ? <Message content={statusMessage.value} /> : null}
      </div>
      <ConfirmationModal
        id={IMPACT_PROPERTIES_MODAL_ID}
        onSubmit={handleImpactConfirm}
        onCancel={closeImpactModal}
        title={t('residence.crud.impactProperties.title')}
        description={t('residence.crud.impactProperties.description')}
        size="small"
        loading={residenceMutation.isLoading}
      />
    </div>
  );
}

ResidenceForm.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  residenceQuery: PropTypes.shape().isRequired,
};

export default ResidenceForm;
