import {
  cloneElement,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { useTranslation, Trans } from 'react-i18next';
import { useFormik } from 'formik';

// Components
import {
  RadioGroup,
  Message,
  utils,
} from 'ui-library-unlocker';

// Services
import { updateOnboardingProgress } from '../../../services/onboarding';

// Hooks
import { useOnboardingContext } from '../../../store/onboardingContext';

// Utils
import { errorFocusSubmit } from '../../../utils/forms/form';
import {
  onboardingProfile2InitialValues,
  onboardingProfile2Schema,
} from '../../../utils/forms/onboardingSchema';

// Constants
import { RENT_DISTRIBUTION_STATUS } from '../../../utils/constants';

import styles from './OnboardingTunnelProfile2.module.scss';

function OnboardingTunnelProfile2({
  children: footer = null,
  handleStep,
}) {
  const { t } = useTranslation();
  const {
    contextOnboarding: {
      inputtedData,
      error,
    },
    dispatchOnboarding,
  } = useOnboardingContext();

  const initialValues = useMemo(() => {
    if (error) {
      return onboardingProfile2InitialValues;
    }
    if (inputtedData.profile) {
      return inputtedData.profile;
    }
    return onboardingProfile2InitialValues;
  }, [inputtedData.profile, error]);

  const formik = useFormik({
    initialValues,
    validationSchema: onboardingProfile2Schema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (JSON.stringify(values) === JSON.stringify(initialValues)) return handleStep(1);
      dispatchOnboarding({
        type: 'SET_INPUTTED_DATA',
        payload: {
          ...inputtedData,
          profile: values,
        },
      });
      return progressMutation.mutate(values);
    },
  });

  const progressMutation = useMutation({
    mutationFn: (data) => updateOnboardingProgress('step2', data),
    onSuccess: () => handleStep(1),
    onError: (err) => {
      if (err?.response?.status === 400) {
        return utils.toast.error(t('global.form.errors.generic'));
      }
      return utils.toast.error(t('global.form.errors.global'));
    },
  });

  const handleSubmit = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const rentDistributionOptions = useMemo(() => Object.values(RENT_DISTRIBUTION_STATUS).map((status) => ({
    id: status,
    value: status,
    label: t(`profile.personalInfo.rentDistribution.rentDistributionOptions.${status}`),
  })), [t]);

  const handleRentDistribution = useCallback((value) => {
    formik.setFieldValue('rentDistribution', value);
  }, [formik]);

  return (
    <>
      <div className={styles.profile2}>
        <h2 className="m-b-10">
          {t('onboarding.steps.profile2.title')}
        </h2>
        <Message
          content={<Trans i18nKey="profile.personalInfo.rentDistribution.rentDistributionMeaning" />}
          variant="info"
          className={utils.cn([styles.requiredDocumentsPhysical])}
        />
        <form className={styles.form} onSubmit={errorFocusSubmit(handleSubmit)}>
          <RadioGroup
            name="rentDistribution"
            className="m-t-25"
            options={rentDistributionOptions}
            value={formik.values.rentDistribution}
            onChange={handleRentDistribution}
          />
          <p className="p-2-500 m-t-25 m-b-10">
            <Trans i18nKey="profile.personalInfo.rentDistribution.rentDistributionInfo">
              <a href="mailto:support@unlocker.io">support@unlocker.io</a>
            </Trans>
          </p>
        </form>
      </div>
      {footer && cloneElement(footer, {
        onSubmit: handleSubmit,
        isDisabled: !formik.values.rentDistribution,
        isLoading: progressMutation.isLoading,
      })}
    </>
  );
}

OnboardingTunnelProfile2.propTypes = {
  children: PropTypes.node,
  handleStep: PropTypes.func.isRequired,
};

export default OnboardingTunnelProfile2;
