import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import {
  RadioGroup,
  TextInput,
  utils,
  SelectInput,
} from 'ui-library-unlocker';

// Utils
import { displayError, isFieldValid } from '../../../utils/forms/form';

// Hooks
import useRoles from '../../../hooks/useRoles';

// Styles
import styles from './SettingsPayments.module.scss';

// Constants
import { SETTINGS_PAYOUT_FREQUENCIES, SETTINGS_DAYS_BEFORE_PAYMENT_REMINDER } from '../../../utils/constants';

function FrequencyFormGroup({
  formik,
  name,
  numberName,
  parentKey,
  label,
  options,
  initialValues,
  isGlobalSettings,
}) {
  const { t } = useTranslation();

  return (
    <div className="m-t-35">
      <p>{label}</p>
      {isGlobalSettings && (
        <small className={styles.info}>
          {t('settings.payments.canBeEditedInLease')}
        </small>
      )}
      <RadioGroup
        name={name}
        className="m-t-15"
        options={options}
        value={formik.values[parentKey]?.[name]}
        onChange={(value) => formik.setFieldValue(`${parentKey}.${name}`, value)}
      />
      {formik.values[parentKey]?.[name] === SETTINGS_PAYOUT_FREQUENCIES.MONTHLY && (
        <TextInput
          type="number"
          id={numberName}
          min={1}
          max={28}
          name={`${parentKey}.${numberName}`}
          className={utils.cn([styles.smallInput, 'm-t-20'])}
          label={t('settings.payments.form.dayOfTheMonth')}
          error={displayError(t, formik, `${parentKey}.${numberName}`, null, {
            nestedValue: true,
            errorConcatValues: {
              maxValue: 28,
            },
          })}
          valid={isFieldValid(formik, `${parentKey}.${numberName}`, null, initialValues[parentKey]?.[numberName])}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[parentKey]?.[numberName]}
        />
      )}
    </div>
  );
}

FrequencyFormGroup.propTypes = {
  formik: PropTypes.shape().isRequired,
  name: PropTypes.string.isRequired,
  numberName: PropTypes.string.isRequired,
  parentKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  initialValues: PropTypes.shape().isRequired,
  isGlobalSettings: PropTypes.bool.isRequired,
};

function SettingsLeaseDetails({
  formik,
  initialValues,
  parentKey = 'payment',
  isGlobalSettings = false,
}) {
  const { t } = useTranslation();
  const { isUserAdmin, isUserRealEstateManager } = useRoles();

  const isManagerOrAdmin = useMemo(() => (
    isUserAdmin || isUserRealEstateManager
  ), [isUserAdmin, isUserRealEstateManager]);

  const payoutFrequencyOptions = useMemo(() => Object.values(SETTINGS_PAYOUT_FREQUENCIES).map((frequency) => ({
    id: frequency,
    value: frequency,
    label: t(`settings.payments.form.${frequency}`),
  })), [t]);

  const daysBeforePaymentReminderOptions = useMemo(() => SETTINGS_DAYS_BEFORE_PAYMENT_REMINDER.map((days) => ({
    id: days,
    value: days,
    label: days,
  })), [t]);

  return (
    <>
      <div className="m-t-25">
        <p>
          {t(`${isGlobalSettings ? 'settings.payments' : 'lease.add.form.settings'}.reminderDays`)}
        </p>
        {isGlobalSettings && (
          <small className={styles.info}>
            {t('settings.payments.canBeEditedInLease')}
          </small>
        )}
        <div className={utils.cn([styles.selectWrapper, 'm-t-15'])}>
          <div className={styles.tinySelect}>
            <SelectInput
              id="daysBeforePaymentReminder"
              name={`${parentKey}.daysBeforePaymentReminder`}
              options={daysBeforePaymentReminderOptions}
              onChange={(option) => formik.setFieldValue(`${parentKey}.daysBeforePaymentReminder`, option.value)}
              value={daysBeforePaymentReminderOptions.find((item) => (
                item.value === formik.values[parentKey]?.daysBeforePaymentReminder
              ))}
            />
          </div>
          <p>{t('settings.payments.daysBeforeDueDate')}</p>
        </div>
      </div>
      {isManagerOrAdmin && (
        <>
          <FrequencyFormGroup
            formik={formik}
            name="lessorPayoutFrequency"
            numberName="lessorPayoutDay"
            parentKey={parentKey}
            label={t('settings.payments.lessorPayoutMethod')}
            options={payoutFrequencyOptions}
            initialValues={initialValues}
            isGlobalSettings={isGlobalSettings}
          />
          <FrequencyFormGroup
            formik={formik}
            name="feesPayoutFrequency"
            numberName="feesPayoutDay"
            parentKey={parentKey}
            label={t('settings.payments.feesPayoutMethod')}
            options={payoutFrequencyOptions}
            initialValues={initialValues}
            isGlobalSettings={isGlobalSettings}
          />
        </>
      )}
    </>
  );
}

SettingsLeaseDetails.propTypes = {
  formik: PropTypes.shape().isRequired,
  initialValues: PropTypes.shape().isRequired,
  parentKey: PropTypes.string,
  isGlobalSettings: PropTypes.bool,
};

export default SettingsLeaseDetails;
