import * as yup from 'yup';

export const emailLoginInfoInitialValues = {
  email: '',
};

export const passwordLoginInfoInitialValues = {
  previousPassword: '',
  proposedPassword: '',
};

export const emailLoginInfoSchema = yup.object().shape({
  email: yup.string().email('global.form.errors.email').required('global.form.errors.required'),
});

export const passwordLoginInfoSchema = yup.object().shape({
  previousPassword: yup.string().required('global.form.errors.required'),
  proposedPassword: yup.string()
    .required('global.form.errors.required')
    .min(8, 'global.form.errors.password')
    .test('isValidPassword', 'global.form.errors.password', (value) => {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSpecialChar = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);

      const conditions = [hasUpperCase, hasNumber, hasSpecialChar];

      return conditions.find((c) => c !== true) === undefined;
    }),
});
